// NotFound.js
import React from 'react';
import notfound from "../Assets/Icons/no-results.png"
import { Link } from 'react-router-dom';
const NotFound = () => {
  return (
    <div style={{textAlign:"center" , padding:"50px"}} >
        <img style={{width:"150px"}} src={notfound} alt="" />
      <h2>404 - Page Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
      <Link className='pagenotfound-h-button' to="/">back to home page</Link>
    </div>
  );
};

export default NotFound;
