import React from 'react';
import { Link } from 'react-router-dom';

const ButItYourWay_Lc = () => {
    return (
        <div>
            <Link to="/productsearch">
                <div style={{ textAlign: "-webkit-center" }}>
                    <h4 className="Title-Name_Lc">BUT IT YOUR WAY</h4>
                </div>
                <div className="">
                    <div className="Find-Under-width-Lc">
                        <div className="">
                            <img className='banner-1-style Contact-Img-Lc' src="https://static1.lenskart.com/media/desktop/img/republic/celb-new/call.png" alt="" />
                        </div>
                        <div className="">
                            <img className='banner-1-style Contact-Img-Lc' src="https://static1.lenskart.com/media/desktop/img/republic/celb-new/wts-up.png" alt="" />
                        </div>
                    </div>
                    <div className="Find-Under-width-Lc">
                        <div className="">
                            <img className='banner-1-style Contact-Img-Lc' src="https://static1.lenskart.com/media/desktop/img/Aug21/Desktop/hto.jpg" alt="" />
                        </div>
                        <div className="">
                            <img className='banner-1-style Contact-Img-Lc' src="https://static1.lenskart.com/media/desktop/img/Aug21/Desktop/stores.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default ButItYourWay_Lc;