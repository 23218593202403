import React from "react";

const TableZeropower = (props) => {
  return (
    //   <table>
    //   <tr>
    //     <th>
    //       <button className="prehistory-btn">Pre. History</button>
    //     </th>
    //     <th>Right</th>
    //     <th>Left</th>
    //   </tr>
    //   <tr>
    //     <td>Spherical </td>
    //     <td>
    //       {" "}
    //       <select name="" id="">
    //         <option value="">1</option>
    //         <option value="">2</option>
    //         <option value="">3</option>
    //       </select>{" "}
    //     </td>
    //     <td>
    //       {" "}
    //       <select name="" id="">
    //         <option value="">1</option>
    //         <option value="">2</option>
    //         <option value="">3</option>
    //       </select>
    //     </td>
    //   </tr>
    //   <tr>
    //     <td>Cylindrical </td>
    //     <td>
    //       {" "}
    //       <select name="" id="">
    //         <option value="">1</option>
    //         <option value="">2</option>
    //         <option value="">3</option>
    //       </select>{" "}
    //     </td>
    //     <td>
    //       {" "}
    //       <select name="" id="">
    //         <option value="">1</option>
    //         <option value="">2</option>
    //         <option value="">3</option>
    //       </select>
    //     </td>
    //   </tr>
    //   <tr>
    //     <td>Axis </td>
    //     <td>
    //       {" "}
    //       <select name="" id="">
    //         <option value="">1</option>
    //         <option value="">2</option>
    //         <option value="">3</option>
    //       </select>
    //     </td>
    //     <td>
    //       {" "}
    //       <select name="" id="">
    //         <option value="">1</option>
    //         <option value="">2</option>
    //         <option value="">3</option>
    //       </select>
    //     </td>
    //   </tr>
    // </table>
    <>
      <p className="zeropower-txt">
        Anti-Glare lenses for computer use, night driving and style (with No
        power)
      </p>

      <button
        onClick={props.onClick}
        className="continue-button-bwl"
      >
        Continue
      </button>
    </>
  );
};

export default TableZeropower;
