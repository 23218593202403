import React from 'react'
import { Link } from 'react-router-dom'
import ProductSeacrhImageScroller from '../ProductSlider/ProductSeacrhImageScroller'

const ProductCart = (props) => {
  const {
    item
  } = props;
  return (
    <Link to={`/productdetail5/${item.d2Id}`} state={{ item }} className="Card-product-type-1">
      <div className='card-product-image-1'>
        <ProductSeacrhImageScroller />
      </div>

      <div className='card-product-details-1'>
        <div className='product-rating-box'>
          <div className='card-product-name'>4.7 <i className="fa-solid fa-star cart-star"></i> 1507</div>
          <div></div>
        </div>

        <div> <p className='card-product-name'>
        {item.name}
        </p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}> <p className='card-product-price'>
          <i className="fa-solid fa-indian-rupee-sign"></i>{item.salePrice}

        </p>
          <p className='card-product-price-2'>
            <i className="fa-solid fa-indian-rupee-sign"></i>{item.salePrice * 2}
          </p></div>
        <div> <p className='card-product-desc'>{item.shortDescription ? item.shortDescription : "No short description found!"}</p>
        </div>
      </div>
    </Link>
  )
}

export default ProductCart
