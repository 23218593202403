import React from "react";
import img from "../../Assets/Images/pic2.png";
import img1 from "../../Assets/Images/pic3.png";
import img2 from "../../Assets/Images/pic4.png";

const SquareBoxImage = (props) => {
    return (
        <div className="Flex-Guide">
            <div class="SquareBox-Look">
                <div class="img-look">
                    <img className="Picture-width-GD"
                        src={img}
                        alt=""
                    />
                </div>
                <span>LOOK</span>
                <p class="info-look">Tie your hair with a scarf or a handkerchief before this experiment and stand in front of a mirror. Make sure you do not move your face.</p>
            </div>
            <div class="SquareBox-Look">
                <div class="img-look">
                    <img className="Picture-width-GD"
                        src={img1}
                        alt=""
                    />
                </div>
                <span>TRACE</span>
                <p class="info-look">Slowly trace the outline of your reflection with the dry-erase marker. You can take re-trace the same later, on a transparent paper.</p>
            </div>
            <div class="SquareBox-Look">
                <div class="img-look">
                    <img className="Picture-width-GD"
                        src={img2}
                        alt=""
                    />
                </div>
                <span>COMPARE</span>
                <p class="info-look">Now observe closely and compare your tracings to the below mentioned face-shapes. You can also take help of a friend here.</p>
            </div>
        </div>
    );
};

export default SquareBoxImage;
