import React, { useEffect, useState } from "react";
import Banner1 from "../../Component/Sections/Banner1";
import ReviewAndRating from "../../Component/Rating/ReviewAndRating";
import ProductSwap3 from "../../Component/ProductSlider/ProductSwap3";
import ProductSwap4 from "../../Component/ProductSlider/Productswap4";
import ProductSwap2 from "../../Component/ProductSlider/ProductSwap2";
import ProductSwap from "../../Component/ProductSlider/Productswap";
import { Link } from "react-router-dom";
import ContectLensZoom from "../../Component/ProductSlider/ContectLensZoom";
import ContectLensZoom2 from "../../Component/ProductSlider/ContactLensZoom2";

const ContactLensDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeComponent, setActiveComponent] = useState(<ContectLensZoom />);
  const [description, setDescription] = useState(true);
  const [features, setFeatures] = useState(true);
  const [specification, setSpecification] = useState(true);
  const [review, setReview] = useState(true);

  const opendesc1 = () => {
    setDescription(!description);
  };
  const opendesc2 = () => {
    setFeatures(!features);
  };
  const opendesc3 = () => {
    setSpecification(!specification);
  };

  const opendesc4 = () => {
    setReview(!review);
  };

  const showComponent = (component) => {
    setActiveComponent(component);
  };

  return (
    <div>
      <div className="ProductDetailPage" style={{ border: "none" }}>
        <div className="ProductDetailPage-left">
          <div className="ProductDetailPage-imagebox1">
            <div
              onClick={() => showComponent(<ContectLensZoom />)}
              className="ProductDetailPage-smallimg"
            >
              <img
                className="ProductDetailPage-image"
                src="https://happychashma.com/shop/images/product/Soflens_One_Day_Astigmatism_Toric_30_Lens_par_Box_Bausch_Lomb_1810_2.jpg"
                alt=""
              />
            </div>
            <div
              onClick={() => showComponent(<ContectLensZoom2 />)}
              className="ProductDetailPage-smallimg"
            >
              <img
                className="ProductDetailPage-image"
                src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg"
                alt=""
              />
            </div>
            {/* <div
                  onClick={() => showComponent(<ProductSwap3 />)}
                  className="ProductDetailPage-smallimg"
                >
                  <img
                    className="ProductDetailPage-image"
                    src="https://happychashma.com/shop/images/product/Green_Year_16104_Acetate_Full_Rim_21_Wayfarer_Matte_Black_2207_4.jpg"
                    alt=""
                  />
                </div>
    
                <div
                  onClick={() => showComponent(<ProductSwap4 />)}
                  className="ProductDetailPage-smallimg"
                >
                  <img
                    className="ProductDetailPage-image"
                    src="https://happychashma.com/shop/images/product/Green_Year_16104_Acetate_Full_Rim_21_Wayfarer_Matte_Black_2207_2.jpg"
                    alt=""
                  />
                </div> */}
          </div>
          <div className="ProductDetailPage-imagebox2">{activeComponent}</div>
        </div>
        <div className="ProductDetailPage-right">
          <div className="ProductDetailPage3-section">
            <h1 className="product-name-3">
              Soflens One Day Astigmatism / Toric (30 Lens par Box) Bausch Lomb
            </h1>
            <h2>
              <div>
                <i className="fa-solid fa-indian-rupee-sign"></i> 2500
                <span>
                  {" "}
                  <i className="fa-solid fa-indian-rupee-sign"></i>3500
                </span>
              </div>
              <div className="instock-box-pdp">3 Stock left</div>
            </h2>
          </div>
          {/* <div className="color-section-p1">
                <div className="color-option-pd">
                  <div className="outline-coloroption">
                    <div className="color-option-box1"></div>
                  </div>
                  <div className="outline-coloroption">
                    <div className="color-option-box2"></div>
                  </div>
                  <div className="outline-coloroption">
                    <div className="color-option-box3"></div>
                  </div>
                  <div className="outline-coloroption">
                    <div className="color-option-box4"></div>
                  </div>
                  <div className="outline-coloroption">
                    <div className="color-option-box5"></div>
                  </div>
                </div>
              </div> */}
     

          <table className="contact-lens-size-selection input-group" border={0}>
            <tr>
              <th>Eye</th>
              <th>SPH</th>
              <th>CYL</th>
              <th>AXIS </th>
              <th>BC</th>
              <th>Dia</th>
              <th>Qty</th>
              <th>Price </th>


            </tr>
            <tr>
              <td>Right</td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
            </tr>

            <tr>
              <td>Left</td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
              <td>
                {" "}
                <select name="" id="">
                  <option value="">-1</option>
                  <option value="">0</option> <option value="">1</option>{" "}
                  <option value="">2</option>
                </select>
              </td>
            </tr>
          </table>

          <div className="input-group">
            <input
              required=""
              type="text"
              name="text"
              autocomplete="off"
              className="input3"
            />
            <span className="user-label">Enter Pincode For Delivery Estimate</span>
            <div>check</div>
          </div>

          <div className="productdetail-bottom-box">
            <div className="product-detail-button-box">
              <Link to="/cart" className="product-detail-button">
                Buy Now
              </Link>
              <Link to="/buywithlens" className="product-detail-button">
                Add to cart
              </Link>
            </div>
            <div className="productdetail-name">
              <p
                style={{ marginLeft: "20px " }}
                className="product-detail-return"
              >
                Return Policy : 7 day easy return policy
              </p>
              <p
                style={{ marginLeft: "20px", color: "#fd5959" }}
                className="product-detail-enquiry"
              >
                Product Enquiry
              </p>
            </div>
          </div>
          <p className="productdetail-name rating-box-pdp">
            <div className="product-detail-name">
              4.7 <i className="fa-solid fa-star product-detail-star"></i> 1507
            </div>
          </p>
        </div>
      </div>

      <Banner1 />

      <div className="ProductDetailPage-bottom">
        <div className="ProductDetailPage-bottom-1">
          <div onClick={opendesc1} className="accordion-box">
            Description
            {description ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {description && (
            <div className="Description-accordian-box">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
                laborum, nemo pariatur minima minus totam sit accusantium
                impedit sunt mollitia repellat adipisci dolorum iure
                exercitationem nihil, libero neque recusandae consequatur
                numquam repellendus. Quibusdam alias placeat animi labore dolor
                corporis delectus nemo nam quas provident sint sequi, eos,
                deleniti, perferendis sed.
              </p>
            </div>
          )}
          <div onClick={opendesc2} className="accordion-box">
            Features
            {features ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {features && (
            <div className="Features-accordian-box">
              <table>
                <tr className="dark-tablerow">
                  <td> Frame Material</td>
                  <td>Metal</td>
                </tr>
                <tr className="light-tablerow">
                  <td> Lens Material</td>
                  <td>Polycarbonate</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Frame Color</td>
                  <td>Black</td>
                </tr>
                <tr className="light-tablerow">
                  <td>Shape</td>
                  <td>Square</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Brand</td>
                  <td>KILLER</td>
                </tr>
                <tr className="light-tablerow">
                  <td>Gender</td>
                  <td>Men, Women</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Dimensions</td>
                  <td>48-20-140</td>
                </tr>
                <tr className="light-tablerow">
                  <td>SKUID</td>
                  <td>E12B10286</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Product type</td>
                  <td>eyeglasses</td>
                </tr>
                <tr className="light-tablerow">
                  <td>Country of Origin</td>
                  <td>India</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Manufactured by</td>
                  <td>Scavin International Exim Pvt. Ltd.</td>
                </tr>
                <tr className="light-tablerow">
                  <td>Sold by</td>
                  <td>EyeMyEye Pvt Ltd</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Raw Material Sourced from</td>
                  <td> India </td>
                </tr>
              </table>
            </div>
          )}
          <div onClick={opendesc3} className="accordion-box">
            Specification
            {specification ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {specification && (
            <div className="Specification-accordian-box">
              <p>✔ Full Frame</p>
              <p>✔ Light Weight and Comfortable</p>
              <p>✔ Strong and Durable</p>
              <p>✔ Free Eyeglasses Case with Cleaning Cloth</p>
            </div>
          )}

          <div onClick={opendesc4} className="accordion-box">
            Review & Rating
            {review ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {review && <ReviewAndRating />}
        </div>
      </div>
      <Banner1 />
    </div>
  );
};

export default ContactLensDetail;
