import React, { useState } from "react";
import styletype from "../../Assets/Icons/glasses (1).png";
import styletype1 from "../../Assets/Icons/glasses.png";
import styletype2 from "../../Assets/Icons/hexa.png";
import styletype3 from "../../Assets/Icons/oval-shape-reading-eyeglasses.png";
import styletype4 from "../../Assets/Icons/Square.webp";
import styletype5 from "../../Assets/Icons/Wayfarer.webp";
import styletype6 from "../../Assets/Icons/Rectangle.webp";
import styletype7 from "../../Assets/Icons/sun-glasses.png";
import styletype8 from "../../Assets/Icons/Geometric.webp";
import styletype9 from "../../Assets/Icons/Clubmaster.webp";
import styletype10 from "../../Assets/Icons/Sports.webp";

const MobileFilterPage = (props) => {
  const [selectedOption, setSelectedOption] = useState("prize-range");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const renderSelectedOption = () => {
    switch (selectedOption) {
      case "prize-range":
        return (
          <div className="filter-options">
            {" "}
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" />
              <p style={{ margin: "0" }}>
                0 to 500 <i className="fa-solid fa-indian-rupee-sign"></i>
              </p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>
                500 to 1000 <i className="fa-solid fa-indian-rupee-sign"></i>
              </p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>
                1000 to 1500 <i className="fa-solid fa-indian-rupee-sign"></i>
              </p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>
                1500 to 3000 <i className="fa-solid fa-indian-rupee-sign"></i>
              </p>
            </div>
          </div>
        );
      case "Shop-For":
        return (
          <div className="filter-options">
            {" "}
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" />
              <p style={{ margin: "0" }}>filter-name</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>filter-name</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>filter-name</p>
            </div>
          </div>
        );
      case "Colour":
        return (
          <div className="filter-options">
            {" "}
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" />
              <p style={{ margin: "0" }}>Black</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>Red</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>Blue</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>White</p>
            </div>
          </div>
        );
      case "Style":
        return (
          <div className="style-filter-box">
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype} alt="" />{" "}
              <p>ROUND</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype1} alt="" />{" "}
              <p>CATEYE</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype2} alt="" />{" "}
              <p>HEXAGONAL</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype3} alt="" />{" "}
              <p>OVAL</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype4}
                alt=""
              />{" "}
              <p>SQUARE</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype5}
                alt=""
              />{" "}
              <p>WAYFARER</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype6}
                alt=""
              />{" "}
              <p>RECTANGLE</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype7} alt="" />{" "}
              <p>AVIATOR</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype8}
                alt=""
              />{" "}
              <p>GEOMATRIC</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype9}
                alt=""
              />{" "}
              <p>CLUBMASTER</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype10}
                alt=""
              />{" "}
              <p>SPORTS</p>
            </div>
          </div>
        );
      case "Shape":
        return (
          <div className="style-filter-box">
            {" "}
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype} alt="" />{" "}
              <p>ROUND</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype1} alt="" />{" "}
              <p>CATEYE</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype2} alt="" />{" "}
              <p>HEXAGONAL</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype3} alt="" />{" "}
              <p>OVAL</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype4}
                alt=""
              />{" "}
              <p>SQUARE</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype5}
                alt=""
              />{" "}
              <p>WAYFARER</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype6}
                alt=""
              />{" "}
              <p>RECTANGLE</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype7} alt="" />{" "}
              <p>AVIATOR</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype8}
                alt=""
              />{" "}
              <p>GEOMATRIC</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype9}
                alt=""
              />{" "}
              <p>CLUBMASTER</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype10}
                alt=""
              />{" "}
              <p>SPORTS</p>
            </div>
          </div>
        );
      case "Size":
        return (
          <div className="filter-options">
            {" "}
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" />
              <p style={{ margin: "0" }}>EXTRA NARROW</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>EXTRA WIDE</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>MEDIUM</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>NARROW</p>
            </div>  <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>WIDE</p>
            </div>
          </div>
        );
      case "Material":
        return (
          <div className="filter-options">
            {" "}
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" />
              <p style={{ margin: "0" }}>filter-name</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>filter-name</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>filter-name</p>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="mobile-filter-page">
      <i
        onClick={props.close}
        className="filter-back-button fa-solid fa-arrow-left-long"
      ></i>
      <div className="mobile-filter-page-top">
        Filter <i style={{ marginLeft: "15px" }} className="fa-solid fa-filter"></i>
      </div>
      <div className="mobile-filter-page-main">
        <div className="mobile-filter-page-left">
          <div
            className={`MobileFilter-options ${
              selectedOption === "prize-range" ? "mobile-filter-active" : ""
            }`}
            onClick={() => handleOptionClick("prize-range")}
          >
            {" "}
            Price-Range
          </div>
          <div
            className={`MobileFilter-options ${
              selectedOption === "Shop-For" ? "mobile-filter-active" : ""
            }`}
            onClick={() => handleOptionClick("Shop-For")}
          >
            Shop For
          </div>
          <div
            className={`MobileFilter-options ${
              selectedOption === "Colour" ? "mobile-filter-active" : ""
            }`}
            onClick={() => handleOptionClick("Colour")}
          >
            Colour
          </div>
          <div
            className={`MobileFilter-options ${
              selectedOption === "Style" ? "mobile-filter-active" : ""
            }`}
            onClick={() => handleOptionClick("Style")}
          >
            Style
          </div>
          <div
            className={`MobileFilter-options ${
              selectedOption === "Shape" ? "mobile-filter-active" : ""
            }`}
            onClick={() => handleOptionClick("Shape")}
          >
            Shape
          </div>
          <div
            className={`MobileFilter-options ${
              selectedOption === "Size" ? "mobile-filter-active" : ""
            }`}
            onClick={() => handleOptionClick("Size")}
          >
            Size
          </div>
          <div
            className={`MobileFilter-options ${
              selectedOption === "Material" ? "mobile-filter-active" : ""
            }`}
            onClick={() => handleOptionClick("Material")}
          >
            Material
          </div>
        </div>
        <div className="mobile-filter-page-right">{renderSelectedOption()}</div>
      </div>
      <div className="Button-box-filter">
        <button onClick={props.close} className="Button-1-filter">
          Cancel
        </button>
        <button className="Button-2-filter">Add Filter</button>
      </div>
    </div>
  );
};

export default MobileFilterPage;
