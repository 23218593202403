import React from 'react';
import { Link } from 'react-router-dom';

const OurPurposeBanner_Lc = () => {
  return (
    <Link to="/productsearch">
     <img className='banner-1-style' src="https://static1.lenskart.com/media/desktop/img/Apr22/Bannerforexport.jpg" alt="" />
    </Link>
  )
}

export default OurPurposeBanner_Lc;
