import React from "react";
import sizemanualimage from "../../Assets/Images/sizemanualimage.svg"

const SizeManual = () => {
  return (
    <div className="SizeManual">
      <h1 className="SizeManual-heading">Our frame sizes</h1>
      <div className="SizeManual-section">
        <p>
          All our frames are designed keeping in mind an ideal Indian face. Most
          of our collections fall in Medium Size Category. We have over 1
          million happy customers. Yet, in case you're uncomfortable ordering
          due to size/fitment issues, don't worry. All our products come with a
          14-Day No Questions Asked Returns Policy. You can return the frame &
          we'll refund your complete money including the money you have paid for
          Lenses. Yes, you read it right! We refund even the Lenses Price, if
          you are not happy with the fit. You may also take help from the quick
          & easy ways to find your frame size below -
        </p>
      </div>
      <div className="SizeManual-section">

        <h1>1. Picking Size from Current Frames</h1>
        <p>
          Reading your frame size is easy. Take your current pair of eyewear
          which fits well on your face and look on the insides of the temples.
          Find the set of numbers reflecting the order of measurement e.g.
          53-15-140. These are the lens width, bridge width and temple length
          respectively.
        </p>
        <img src={sizemanualimage} alt="" />
        <div className="SizeManual-section">

          <div>
            <p>Lens Width/Diameter –</p>
            <p>
              This is the horizontal width of each lens in millimeters. It
              ranges between 40-62mm
            </p>
          </div>
          <div>
            <p>Bridge Width –</p>
            <p>
              This is the distance between the two lenses in millimeters. It
              ranges between 14-24mm.
            </p>
          </div>
          <div>
            <p>Temple Length – </p>
            <p>
              This is the length from screw to temple tips. It ranges between
              110-150mm.
            </p>
          </div>
        </div>
      </div>

      <div className="SizeManual-section">

        <h1>2. Measuring Frames with Ruler</h1>
        <p>
          There may be a possibility that the measurement on your current frames
          is faded or is not printed. In this case, you will need a millimeter
          ruler or a cloth measuring tape to measure the frame yourself. Find
          size as shown above and purchase your next set of eyeglasses or
          sunglasses without any hassle.
        </p>

        <img src="" alt="" />
      </div>

      <div className="SizeManual-section">

        <h1>3. Finding Frame Size by Clothing Size</h1>
        <p>
          Broadly, you can also follow the below chart to find your frame size.
        </p>

        <ul>
          <li>
            If you wear small-sized attires, frames with less than or equal to
            50mm of lens-width would fit you easily in case of eyeglasses. For
            sunglasses the width of 55mm or less should be your choice.
          </li>
          <li>
            Similarly, for people who go for large-sized clothes, must opt for
            spectacles with lens-width or diameter of 55mm or more. In case of
            shades, this will be 65mm or more.
          </li>
          <li>
            {" "}
            People who wear M size, should get frames between 51mm to 54mm of
            lens-width for eyeglasses and 56mm to 64mm for sunglasses.
          </li>
        </ul>
      </div>

      <div className="SizeManual-section">

        <h1>Eyeglasses Size Chart</h1>
        <div>
          <div>
            <p>SMALL</p>
            <p>Medium</p>
            <p>Large</p>
          </div>
          <div>
            <p>50mm or less</p>
            <p>51mm-54mm</p>
            <p>55mm or more</p>
          </div>
        </div>
      </div>

      <div className="SizeManual-section">

        <h1>Sunglasses Size Chart</h1>
        <div>
          <div>
            <p>SMALL</p>
            <p>Medium</p>
            <p>Large</p>
          </div>
          <div>
            <p>55mm or less</p>
            <p>56mm-64mm</p>
            <p>65mm or more</p>
          </div>
        </div>
      </div>

      <div className="SizeManual-section">

        <h1>4. Using Credit Card to Measure Frame Size</h1>
        <img src="" alt="" />
        <p>
          Know your frame size while you’re on-the-go with your credit or debit
          card. Surprisingly, the standard width of the card can be compared to
          a medium (M) sized frame.
        </p>
        <p>
          To measure, place one edge of the card at the center of your nose and
          see where it ends. If the card ends at the tip of your eyes, a medium
          sized frame would fit you perfectly. If the card width extends the
          eyes, a small size would be perfect for you. Just in case the card
          edge finishes before your eyes end, you need a (L) large-sized frame.
        </p>
      </div>

      <div className="SizeManual-section">
        <h1>5. Still Unsure About the Exact Size?</h1>
        <p>
          Don’t worry. Give us a call on 9823190701 or mail @
          optivioopticiansiolim@gmail.com and we’ll together find your perfect
          frame-size.
        </p>
      </div>
    </div>
  );
};

export default SizeManual;
