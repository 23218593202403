import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import MyContext from "../../../MyApp/MyContext";
import ProductSearchComponent from "../ProductSlider/ProductSearchComponent";
import Order from "../../../API/API_SERVISES/Order";
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";
import AlertSuccess from "../AlertBox/AlertSuccess";

const ProductCart11 = (props) => {
    const {
        item
    } = props;

    const { imageBaseUrl, fetchDataWish, Itemvariation, fetchVariations, setMyAccount, signupshow, myAccount, fetchDataCart } = useContext(MyContext);

    // useEffect(() => {
    //     fetchVariations(item.d1Id)
    // }, [])

    // let isActiveValue = null;
    // if (Itemvariation.length > 0) {

    //     const filteredItems = Itemvariation[0].variation.filter(item => item.attr_prnt_nm == "Color");
    //     if (filteredItems.length > 0) {
    //         // Assuming isActive is a property of the filtered item 
    //         isActiveValue = filteredItems[0].isActive;
    //     } else {
    //         // Handle case where no items match the filter condition
    //         console.log("No items with attr_prnt_nm === 'color' found");
    //     }

    // }
    const [isHovered, setHovered] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false);
    // console.log(isActiveValue);
    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const [colorChangefunc, setColorChangeFunc] = useState("color-1");

    const colorchange1 = (event) => {
        setColorChangeFunc(event.target.id);
    };

    const [, setInputValues] = useState({
        d1ItemId: item.d1ItemId,
        d2ItemId: item.d2ItemId,
        d1AccountId: 0,
        d2AccountId: 0,
        userId: item.userId,

    });

    const fetchWishList = (itemData) => {
        const userLogin = localStorage.getItem("accountLogin");
        const userArray = userLogin ? JSON.parse(userLogin) : null;

        if (userArray) {
            // UserArray is present, navigate to "/MyAccountPage"
            // setMyAccount(!myAccount);
            toggleWishlist();
        } else {
            // UserArray is not present, continue with your existing logic
            setMyAccount(!myAccount);
            document.body.style.overflow = myAccount ? "auto" : "hidden";
            signupshow();
        }
    };

    const { wishList, setWishList } = useContext(MyContext);

    const toggleWishlist = async (itemData) => {
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);

        console.log(itemData);
        console.log(item);
        console.log(wishList);
        const existingIndex = wishList.find(product => product.d1ItemId === item.d1Id);
        console.log(existingIndex);
        if (existingIndex !== undefined) {
            // alert("Item is already in wishlist");
            try {
                const val = await Order.deleteDeleteFavouriteItemList(existingIndex.id);
                if (Boolean(val) === true) {
                    // alert("Delete data successfully", "Ok");
                    fetchDataWish()
                } else {
                    alert("Error Deleting Data");
                }
                // fetchData();
            } catch (error) {
                console.error("Error deleting account:", error);
            }
            return;
        } else {

            const data = {
                d1ItemId: item.d1Id,
                d2ItemId: item.d2Id,
                d1AccountId: loginData.d1Id,
                d2AccountId: loginData.d2Id,
                userId: 1,
            };
            try {
                const isSuccess = await Order.insertCreateFavouriteItemList(data);
                if (isSuccess) {
                    // alert("Added to wishlist successfully");
                    fetchDataWish()
                } else {
                    throw new Error("Error while adding to wishlist");
                }
            } catch (error) {
                console.error("Error while adding to wishlist:", error);
                alert("Error while adding to wishlist");
            }
            setInputValues({
                d1ItemId: "",
                d2ItemId: "",
                d1AccountId: "",
                d2AccountId: "",
                userId: "",
            });
        };
    }



    const AddToCart = async () => {
        debugger
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);
        if (loginData === null) {
            loginData = {
                d1Id: 0,
                d2Id: 0,
            }
        }

        console.log()
        console.log(item)
        const data = {
            d1ItemId: item.d1Id || item.d1ItemId,
            d2ItemId: item.d2Id || item.d2ItemId,
            d1AccountId: loginData ? (loginData.d1Id || 0) : 0,
            d2AccountId: loginData ? (loginData.d2Id || 0) : 0,
            userId: 1,
            quantity: 1,
            unit: "Pcs",
            clientId: 0,
            itemPrice: item.salePrice,
            lensPrice: item.lensPrice,
            lensName: item.lensName,
            coatingPrice: item.coatingPrice,
            coatingName: item.coatingName,

            // box_R: inputValues.boxesRight,
            // box_L: inputValues.boxesLeft,
            // sph_R: inputValues.sphRight,
            // sph_L: inputValues.sphLeft,
            // cyl_R: inputValues.cylRight,
            // cyl_L: inputValues.cylLeft,
            // axis_R: inputValues.axisRight,
            // axis_L: inputValues.axisLeft,
            // add_R: inputValues.addRight,
            // add_L: inputValues.addLeft,
            // dia_R: inputValues.diaRight,
            // dia_L: inputValues.diaLeft,
            // bC_R: inputValues.bcRight,
            // bC_L: inputValues.bcLeft,
        }
        const isSuccess = await Order.insertCreateEcomCartDetail(data);
        if (isSuccess) {
            // setTotalQuantity(isSuccess.length)
            fetchDataCart()
            setAlertSuccess(true)
            setInputValues({
                d1ItemId: " ",
                d2ItemId: " ",
                d1AccountId: " ",
                d2AccountId: " ",
                userId: " ",
                quantity: " ",
                unit: " ",
                itemPrice: " ",
                lensPrice: "",
                lensName: "",
                coatingPrice: "",
                coatingName: "",
                boxesLeft: "",
                boxesRight: "",
                sph_R: "",
                sph_L: "",
                cyl_R: "",
                cyl_L: "",
                axis_R: "",
                axis_L: "",
                add_R: "",
                add_L: "",
                dia_R: "",
                dia_L: "",
                bcRight: "",
                bcLeft: "",
            });

        }
    }
    useEffect(() => {
        if (alertSuccess) {
            const timeoutId = setTimeout(() => {
                setAlertSuccess(false);
            }, 500); // 3 seconds
            return () => clearTimeout(timeoutId);
        }
    }, [alertSuccess]);

    return (

        <div className="card-product-main-container11">
            {alertSuccess && (<AlertSuccess />)}
            <div style={{ cursor: "pointer", position: "absolute", right: "0px", zIndex: "1" }} onClick={() => fetchWishList(item)}>
                {(wishList && wishList.find(product => product.d1ItemId === item.d1Id)) ? (
                    <i className="fa-solid fa-heart HeartSaveBtn2"></i>
                ) : (
                    <i className="fa-regular fa-heart HeartSaveBtn2"></i>
                )}
            </div>
            <div className="Card-product-type-11">
                <Link to={`/ProductDetailTheme/${item.d2Id}`} state={{ item }} >

                    {colorChangefunc === "color-1" && (
                        <div key={item.id}
                            className="Card-product-type-1-top"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            {/* <img
                            className="Card-product-type-1-img"
                            src={
                                isHovered
                                    ? `${imageBaseUrl}${item.images[1]}`
                                    : `${imageBaseUrl}${item.images[0]}`
                            }
                            alt=""
                        /> */}
                            <div
                                className="Card-product-type-1-img"
                                style={{
                                    // backgroundImage: `url(${isHovered ? `${imageBaseUrl}${item.images[1]}` : `${imageBaseUrl}${item.images[0]}`})`,
                                    backgroundImage: `url(${imageBaseUrl}${item.images.length > 1 && isHovered ? item.images[1] : item.images[0]})`
                                }}
                            />
                        </div>
                    )}

                    {colorChangefunc === "color-2" && (
                        <div
                            className="Card-product-type-1-top"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <img
                                className="Card-product-type-1-img"
                                src={
                                    isHovered
                                        ? `${imageBaseUrl}${item.images[3]}`
                                        : `${imageBaseUrl}${item.images[2]}`
                                }
                                alt=""
                            />
                        </div>
                    )}

                    {colorChangefunc === "color-3" && (
                        <div
                            className="Card-product-type-1-top"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <img
                                className="Card-product-type-1-img"
                                src={
                                    isHovered
                                        ? `${imageBaseUrl}${item.images[4]}`
                                        : `${imageBaseUrl}${item.images[3]}`
                                }
                                alt=""
                            />
                        </div>
                    )}

                    {colorChangefunc === "color-4" && (
                        <div
                            className="Card-product-type-1-top"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <img
                                className="Card-product-type-1-img"
                                src={
                                    isHovered
                                        ? `${imageBaseUrl}${item.images[5]}`
                                        : `${imageBaseUrl}${item.images[4]}`
                                }
                                alt=""
                            />
                        </div>
                    )}

                    {colorChangefunc === "color-5" && (
                        <div
                            className="Card-product-type-1-top"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <img
                                className="Card-product-type-1-img"
                                src={
                                    isHovered
                                        ? `${imageBaseUrl}${item.images[6]}`
                                        : `${imageBaseUrl}${item.images[5]}`
                                }
                                alt=""
                            />
                        </div>
                    )}


                    <div className="Card-product-type-1-bottom">
                        <div className="Card-product-type-1-desc-box">
                            <div>
                                <p className="card-product-name">
                                    {item.sku}
                                </p>
                            </div>
                            <div>
                                {/* <p className="card-product-desc">{item.shortDescription ? item.shortDescription : "No short description found!"}</p> */}
                            </div>
                            {/* <div style={{ display: "flex", alignItems: "center" }}>
                            <p className="card-product-size11">
                                <span>Size </span>
                                Medium.Ocean Blue
                            </p>

                        </div> */}
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p className="card-product-price11">
                                    <i className="fa-solid fa-indian-rupee-sign"></i> {item.salePrice}
                                </p>
                                <p className="card-product-price-2">
                                    MRP&nbsp;<i className="fa-solid fa-indian-rupee-sign"></i>
                                    <span className="mrp-price">{item.mrpPrice}</span>
                                </p>
                                <p className="discount-percentage">
                                    {parseInt((((item.mrpPrice - item.salePrice) / item.mrpPrice) * 100).toFixed(2))}% off
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="Card-product-type-1-middle">
                        <div className="Card-product-type-1-rating-color-box">
                            <div className="Card-product-type-1-rating-box11">
                                <div className="card-product-name" style={{ margin: "0" }}>
                                    4.7 <i className="fa-solid fa-star cart-star-c2"></i>
                                   
                                </div>
                            </div>
                        </div>
                        {/* {isActiveValue && (
                        <div className="Card-product-type-1-color-box">
                            <div
                                onClick={() => colorchange1({ target: { id: "color-1" } })}

                                className="outline-coloroption-c2"
                            >
                                <div className="color-option-box-main color-option-box1-c2"></div>
                            </div>
                            <div
                                onClick={() => colorchange1({ target: { id: "color-2" } })}
                                className="outline-coloroption-c2"
                            >
                                <div className="color-option-box-main color-option-box2-c2"></div>
                            </div>
                            <div
                                onClick={() => colorchange1({ target: { id: "color-3" } })}
                                className="outline-coloroption-c2"
                            >
                                <div className="color-option-box-main color-option-box3-c2"></div>
                            </div>
                            <div
                                onClick={() => colorchange1({ target: { id: "color-4" } })}
                                className="outline-coloroption-c2"
                            >
                                <div className="color-option-box-main color-option-box4-c2"></div>
                            </div>
                            <div
                                onClick={() => colorchange1({ target: { id: "color-5" } })}
                                className="outline-coloroption-c2"
                            >
                                <div className="color-option-box-main color-option-box5-c2"></div>
                            </div>
                        </div>
                    )} */}
                    </div>
                </Link>
                <div className="Card-product-type-1-end1">
                    {/* Get 40% OFF in Coupon : LENSFREE */}
                    <div
                        onClick={() => AddToCart(item.id)}
                        className="product-detail-button-theme3-cart-button">
                        Add To Cart{" "}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCart11;
