import React, { useState, useContext, useEffect } from "react";
import imagemen from "../../../Assets/Images/men_pic.webp"
import imageWomen from "../../../Assets/Images/women_pic.webp"
import imageKids from "../../../Assets/Images/kid_pic.webp"
import MenRightpanelTheam3 from "./MenRightpanelTheam3";
import WomenRightpanelTheam3 from "./WomenRightpanelTheam3";
import KidsRightpanelTheam3 from "./KidsRightpanelTheam3";
import MyContext from "../../../MyContext";
import { Link } from 'react-router-dom';
import Appointment from "../../../Pages/AppointmentPage/Appointment";
import CheckOutAndGeneralSetting from "../../../../API/API_SERVISES/CheckoutAndGeneralSetting";


const Theme3NavbarOnhover = ({ closeNavbarLc, openNavbarLc, parentId, CategoryData }) => {
    const { imageBaseUrl } = useContext(MyContext);
    console.log("Category Data", CategoryData);

    const filterId = CategoryData.filter(item => item.d1_Id === parentId)
    // console.log(filterId[0].d1_Id)
    // const { openNavbarLc, closeNavbarLc } = props
    const [menPanelOpen, setMenPanelOpen] = useState(true);
    const [womenPanelOpen, setWomenPanelOpen] = useState(false);
    const [kidsPanelOpen, setKidsPanelOpen] = useState(false);

    const openRightPanel = (category) => {
        if (category === "Men") {
            setMenPanelOpen(true);
            setWomenPanelOpen(false);
            setKidsPanelOpen(false);
        } else if (category === "Women") {
            setWomenPanelOpen(true);
            setMenPanelOpen(false);
            setKidsPanelOpen(false);
        } else if (category === "Kids") {
            setKidsPanelOpen(true);
            setMenPanelOpen(false);
            setWomenPanelOpen(false);
        }
    };


    const { homeEyeCheckUpData } = useContext(MyContext)


    const closeRightPanels = () => {
        setMenPanelOpen(false);
        setWomenPanelOpen(false);
        setKidsPanelOpen(false);
    };

    return (
        <>
            <div className="theme3-navbar-onhover" onMouseEnter={() => openNavbarLc(parentId)} onMouseLeave={closeNavbarLc}>
                <div className="navbar-onhover-left-Panel-for-sub">
                    {parentId === 5008 ? (homeEyeCheckUpData.eyeCheckUpInHome === true ?
                        (
                            <div style={{ display: "flex" }}>
                                <Appointment closeNavbarLc={closeNavbarLc} />
                            </div>
                        ) : (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <h2>Sorry Home Eye Test Is Currently Unavailable</h2>
                            </div>
                        )
                    ) : (CategoryData.map((item, index) => {
                        if (item.primaryGroup === false && item.showInMenu === true && item.parentGrp === parentId) {
                            return (
                                <div className="Select-Category-width-Lc">
                                    {/* <div key={index} className="Flex-SubCategory-Lc">
                    {item.menuName}
                </div> */}
                                    <h3 key={index} className="Flex-Gender-Lc">
                                        <Link to={`/productsearch?productId=${item.d2_Id}`} className="Submenu-color-home">{item.menuName}</Link></h3>
                                    {CategoryData
                                        .filter(subItem => subItem.parentGrp === item.d1_Id && subItem.showInMenu === true)
                                        .map((subItem, subIndex) => (
                                            <div className="Flex-Select-Category-Lc">
                                                <Link to={`/productsearch?productId=${subItem.d2_Id}`} className="Subcategory-Men-Lc">
                                                    <div className="navbar-onhover-left-Panel-child">
                                                        {subItem.images && subItem.images[0] ? (
                                                            <div className="Font-image-nav-Lc">
                                                                <img className=""
                                                                    src={`${imageBaseUrl}${subItem.images[0]}`}
                                                                    alt="" />
                                                            </div>
                                                        ) : (
                                                            <div style={{ display: "none" }}>
                                                                <img className="" src="" alt="" />
                                                            </div>
                                                        )}
                                                        <div className="{`theme-3-onhover-heading-2}`} Subitem-Color-Lc">{subItem.menuName}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                </div>
                            )
                        }
                    }
                    )
                    )
                    }

                    {/* <div className="Flex-SubCategory-Lc">
                        <h3 className="Flex-Gender-Lc">Select Gender </h3>
                        <h3 className="Flex-Gender-Lc">Select Category </h3>
                        <h3 className="Flex-Gender-Lc">Our top picks </h3>
                        <h3 className="Flex-Gender-Lc">Frame Type </h3>
                        <h3 className="Flex-Gender-Lc">Collection </h3>
                        <h3 className="Flex-Gender-Lc">Brands </h3>
                    </div>
                    <div className="Flex-Select-Category-Lc">
                    <div className="Subcategory-Men-Lc">
                        <div
                            className="navbar-onhover-left-Panel-child"
                            onMouseEnter={() => openRightPanel("Men")}
                            style={{ backgroundColor: menPanelOpen ? "rgb(255, 243, 232)" : "white" }}
                        >

                            <img src={imagemen} alt="" />
                            <p style={{ minWidth: "100px" }}>
                                Men
                            </p>
                            <i className="fa-solid fa-angle-right"></i>
                        </div>
                
                        <div
                            className="navbar-onhover-left-Panel-child"
                            onMouseEnter={() => openRightPanel("Women")}
                            style={{ backgroundColor: womenPanelOpen ? "rgb(255, 243, 232)" : "white" }}
                        >
                            <img src={imageWomen} alt="" />
                            <p style={{ minWidth: "100px" }}>
                                Women
                            </p>
                            <i className="fa-solid fa-angle-right"></i>
                        </div>
                        <div
                            className="navbar-onhover-left-Panel-child"
                            onMouseEnter={() => openRightPanel("Kids")}
                            style={{ backgroundColor: kidsPanelOpen ? "rgb(255, 243, 232)" : "white" }}
                        >
                            <img src={imageKids} alt="" />
                            <p style={{ minWidth: "100px" }}>
                                Kids
                            </p>
                            <i className="fa-solid fa-angle-right"></i>
                        </div>
                    </div>
                    <div className="">
                        <div
                            className="navbar-onhover-left-Panel-child"
                            onMouseEnter={() => openRightPanel("Men")}
                        >
                            <p style={{ minWidth: "100px" }}>
                                CLASSIC EYEGLASSES Starting From 2000
                            </p>
                            <i className="fa-solid fa-angle-right"></i>
                        </div>
                        <div
                            className="navbar-onhover-left-Panel-child"
                            onMouseEnter={() => openRightPanel("Women")}
                        >
                            <p style={{ minWidth: "100px" }}>
                                PREMIUM EYEGLASSES Starting From 2000
                            </p>
                            <i className="fa-solid fa-angle-right"></i>
                        </div>
                        <div
                            className="navbar-onhover-left-Panel-child"
                            onMouseEnter={() => openRightPanel("Kids")}
                        >
                            <p style={{ minWidth: "100px" }}>
                                COMPUTER EYEGLASSES Starting From 2000
                            </p>
                            <i className="fa-solid fa-angle-right"></i>
                        </div>
                    </div>
                    <div className='theme-3-onhover-heading-2'>
                        <div>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                           
                        </div>
                    </div>
                    <div className='theme-3-onhover-heading-2'>
                        <div>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                           
                        </div>
                    </div>
                    <div className='theme-3-onhover-heading-2'>
                        <div>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                           
                        </div>
                    </div>
                    <div className='theme-3-onhover-heading-2'>
                        <div>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                            <p>New Lens Eyeglass   <i className="fa-solid fa-angle-right"></i></p>
                           
                        </div>
                    </div>
                    </div> */}

                </div>


                {/* {menPanelOpen && (
                    <MenRightpanelTheam3 />
                )}
                {womenPanelOpen && (
                    <WomenRightpanelTheam3 />
                )}
                {kidsPanelOpen && (
                    <KidsRightpanelTheam3 />
                )} */}

            </div>
        </>
    );
};

export default Theme3NavbarOnhover;
