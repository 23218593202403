import React from "react";

const ProductEnquiryModal = (props) => {
  return (
    <div className="modal-back">
      <div className="form-container-pe">
        <i
          style={{ cursor: "pointer" }}
          onClick={props.handleClick}
          className="fa-solid fa-xmark modal-cross"
        ></i>
        <p style={{ marginBottom: "20px", border: "none" }} className="title">
          Product Enquriy
        </p>

        <div className="form-modal">
          <select className="input-modal-pe" name="" id="">
            <option value="">Product Enquiry</option>
            <option value="">Product Booking</option>
            <option value="">Order Delivery</option>
            <option value="">Problem With Item Received/Return</option>
            <option value="">Feedback</option>
            <option value="">Franchise Enquiry</option>
            <option value="">Other Issue</option>
          </select>
          <input
            type="text"
            className="input-modal-pe"
            placeholder="First Name"
          />
          <input
            type="text"
            className="input-modal-pe"
            placeholder="Last Name"
          />
          <input
            type="text"
            className="input-modal-pe"
            placeholder="Mobile Number"
          />
          <input
            type="text"
            className="input-modal-pe"
            placeholder="Email Address"
          />
          <input type="text" className="input-modal-pe" placeholder="Address" />
          <textarea
            name=""
            className="input-modal-pe"
            id=""
            style={{
              width: "100%",
              maxWidth: "100%",
              minWidth: "100%",
              height: "100px",
              maxHeight: "130px",
              minHeight: "130px",
            }}
          ></textarea>

          <div
            to="/admin"
            style={{ textDecoration: "none", textAlign: "center" }}
            onClick={props.handleClick}
            className="form-btn"
          >
            Submit Query
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductEnquiryModal;
