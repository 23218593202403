import React, { useContext, useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactImageMagnify from 'react-image-magnify';
import MyContext from "../../../MyApp/MyContext";
// import './ProductSwap2.css'; // Import your custom styles if needed

const ProductSwap = (props) => {

  const { items } = props
  
  const { imageBaseUrl } = useContext(MyContext);

  const [enlargedImagePosition, setEnlargedImagePosition] = useState('beside');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setEnlargedImagePosition('over');
      } else {
        setEnlargedImagePosition('beside');
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // var settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   // autoplay: true,
  //   // autoplaySpeed: 3000,
  // };

  const imageurl = `${imageBaseUrl}${items.images[0]}`;
  // const imageurl2 = 'https://www.specsplus.in/shop/images/product/SNOOPY_160229_699_2.jpg';

  return (
    <>
      {/* <Slider className="product-swap-2" {...settings}> */}
        <div className="productswap-deteil-2" style={{zIndex:"3"}}>
          <ReactImageMagnify
            {...{
              smallImage: {
                isFluidWidth: true,
                src: imageurl,
              },
              largeImage: {
                src: imageurl,
                width: 1200,
                height: 600,
              },
              style: {
                backgroundColor: 'white',
              },
              enlargedImageContainerDimensions:{
                width:  "93%",
                height : '120%',
                backgroundColor:"black"
              }
              ,
              enlargedImagePosition: enlargedImagePosition, 
              
            }}
          />
        </div>
  
        {/* <div className="productswap-deteil-3">
        <ReactImageMagnify
            {...{
              smallImage: {
                isFluidWidth: true,
                src: imageurl2,
              },
              largeImage: {
                src: imageurl2,
                width: 1200,
                height: 600,
              },
              style: {
                backgroundColor: 'white',
              },
              enlargedImageContainerDimensions:{
                width:  "125%",
                height : '150%',
                backgroundColor:"black"
              }
              ,
              enlargedImagePosition: 'beside', 
              
            }}
          />
        </div> */}
      {/* </Slider> */}
    </>
  );
};

export default ProductSwap;
