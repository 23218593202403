import React from 'react'
import { Link } from 'react-router-dom'

const AlertBox = (props) => {
  return (
    <div className='modal-back'>
    <div className='alert-box-1'>
      <div>
        <p className='alert-box-1-txt'>{props.alertname}</p>
      </div>
      <div className='alert-box-1-btn-box'>
        <button  className='alert-box-1-btn' onClick={props.alertbox} > Yes</button>
        <button className='alert-box-1-btn red-btn' onClick={props.close} > No</button>
      </div>

    </div>
    </div>
  )
}

export default AlertBox