import React from 'react'
import { Link } from 'react-router-dom'

const AlertSuccess = (props) => {
    return (
        <div style={{display:"flex", justifyContent:"center"}} className='modal-back-alert-theme3'>
            <h1 className='AddCartAlert' style={{}}><i class="fa-solid fa-circle-check CartIcon1"></i> Add to cart Successfully</h1>
        </div>
    )
}

export default AlertSuccess;