import React, { useContext, useEffect, useState } from "react";
import { Link, } from "react-router-dom";
import ProductSwap from "../../Component/ProductSlider/Productswap";
import Banner1 from "../../Component/Sections/Banner1";
import ReviewAndRating from "../../Component/Rating/ReviewAndRating";
import MyContext from "../../../MyApp/MyContext";
import ProductEnquiryModal from "../../Component/Modal/ProductEnquiryModal";
import Order from "../../../API/API_SERVISES/Order.js";
import AlertSuccess from "../../Component/AlertBox/AlertSuccess.jsx";
import img11 from "./../../Assets/Image_lc/LensImg/single_vision.webp"
import img22 from "./../../Assets/Image_lc/LensImg/bifocal.webp"
import img33 from "./../../Assets/Image_lc/LensImg/zero_power.webp"
import img44 from "./../../Assets/Image_lc/LensImg/tinted_sv.webp"

import ProductSwap_LC from "../../Component/ProductSlider/ProductSwap_LC.jsx";
import { useLocation, useParams } from 'react-router-dom';
import ArrowLeft from "../../Assets/Icons/icon-arrow-left.png"
import ArrowRight from "../../Assets/Icons/icon-arrow-right.png"
import ThemeModal from "./ThemeModal.jsx";
import ItemMasterApi from "./../../../API/API_SERVISES/ItemMaster.js";
import ModalTechnicalInforamation from "../../Component/Modal/ModalTechnicalInforamation.jsx";
import img5 from "../../Assets/Image_lc/visit_store.webp"
import ItemMaster from "./../../../API/API_SERVISES/ItemMaster.js";
import Loader1 from "../../Component/Loaders/Loader1.jsx";
import Slider from "react-slick";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';


const ProductDetailTheme = () => {

    const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
    const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

    var WebApiUrl = express_server_url;
    WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

    const { productEnquiry, signupshow, myAccount, setMyAccount, openproductenquiry, fetchDataWish, itemDataLens, setItemDataLens, currentUser, wishList, setWishList, fetchVariations, Itemvariation, shopBanner } = useContext(MyContext);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (Itemvariation.length === 0) {
            fetchVariations()
        }
    }, [])

    const [attributeFetch, setAttributeFetch] = useState([]);
    const [loading, setloading] = useState(false)
    const location = useLocation();
    const [item, setItem] = useState(location?.state?.item || {});
    const [item2, setItem2] = useState(null);
    const [colorChangefunc, setColorChangeFunc] = useState("color-1");
    const colorchange1 = (event) => {
        setColorChangeFunc(event.target.id);
    };


    const [selectedParents, setSelectedParents] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const { imageBaseUrl, fetchDataCart } = useContext(MyContext);

    const [activeComponent, setActiveComponent] = useState(<ProductSwap items={item} />);
    const [description, setDescription] = useState(true);
    const [features, setFeatures] = useState(true);
    const [specification, setSpecification] = useState(true);
    const [review, setReview] = useState(true);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [isModalStationMaster, setIsModalStationMaster] = useState(false);
    const [modalBox, setModalBox] = useState(false);
    const [isSliderOpen, setSliderOpen] = useState(false);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(4);
    const itemsPerPage2 = 1; // Number of items to show per page
    const { id } = useParams();

    // *******************{GetById Master}************************

    // Helper function to check if the object is empty
    const isEmptyObject = (obj) => {
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    useEffect(() => {
        if (isEmptyObject(item)) {
            fetchProductDetails(id);
        }

        if (item.d2_Id != id) {
            fetchProductDetails(id);
        }
    }, [id, item]);



    const fetchProductDetails = async (productId) => {
        setloading(true)
        try {
            const response = await ItemMaster.GetItemMasterById(productId);
            if (response) {
                setItem(response);
                setloading(false)
            }
        } catch (error) {
            setloading(false)
            console.error('Failed to fetch product details:', error);
        }
    };

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 750px)');

        const handleResize = (e) => {
            if (e.matches) {
                setItemsPerPage(4);
            } else {
                setItemsPerPage(5);
            }
        };

        // Set the initial value
        handleResize(mediaQuery);

        // Add event listener
        mediaQuery.addEventListener('change', handleResize);

        // Clean up event listener on component unmount
        return () => {
            mediaQuery.removeEventListener('change', handleResize);
        };
    }, []);
    const [AttributeData, setAttributeData] = useState([])

    useEffect(() => {
        const fetchAttribute = async () => {
            var data1 = await ItemMaster.GetItemAttributeSettings();
            console.log("update data", data1);
            if (data1) {
                setAttributeData(data1)
            } else {
                console.log("error");
            }
        }
        fetchAttribute()
    }, [])
    console.log(selectedParents);
    console.log(selectedAttributes);
    console.log(Itemvariation);
    console.log(AttributeData);
    console.log(item);

    const filteredAttributes = AttributeData.filter(attribute => {
        return attribute.attributeChilds.some(child => {
            const found = Itemvariation.some(item => {
                return item.variation.some(variation => {
                    return variation.attr_prnt === attribute.attributeId && variation.isActive;
                });
            });
            return found;
        });
    });
    const filteredAttributeData = filteredAttributes.map(attribute => {
        const filteredChilds = attribute.attributeChilds.filter(child => {
            // Check if child.attrId exists in any variation's attr of ItemVariation
            return Itemvariation.some(item => item.variation.some(variant => variant.attr === child.attrId));
        });

        // Return a new object with filtered attributeChilds
        return {
            ...attribute,
            attributeChilds: filteredChilds
        };
    });
    console.log("Attribute Data", filteredAttributeData);

    useEffect(() => {
        // Initialize selectedParents and selectedAttributes from filteredAttributeData
        if (item.itmAttribute && item.itmAttribute.length > 0) {
            const initialParents = item.itmAttribute.map(category => category.attributeParentId);
            const initialAttributes = item.itmAttribute.map(category => category.attributeId);

            setSelectedParents(initialParents);
            setSelectedAttributes(initialAttributes);
        }
    }, [item]);

    useEffect(() => {
        // Call findItemMaster whenever attributeFetch changes, but not on initial render
        if (attributeFetch.length > 0) {
            findItemMaster(attributeFetch);
        }
    }, [attributeFetch]);
    const SettingAttribute = (ChildId, ParentId) => {
        // Find index of ParentId in selectedParents array
        // alert(`childId: ${ChildId}\nparentId: ${ParentId}`);
        const index = selectedParents.indexOf(ParentId);

        if (index !== -1) {
            // ParentId already exists, update corresponding attrId
            const updatedParents = [...selectedParents];
            const updatedAttributes = [...selectedAttributes];

            updatedAttributes[index] = ChildId;

            setSelectedParents(updatedParents);
            setSelectedAttributes(updatedAttributes);
        } else {
            // ParentId does not exist, add it
            setSelectedParents([...selectedParents, ParentId]);
            setSelectedAttributes([...selectedAttributes, ChildId]);
        }

        // Update attributeFetch state
        setAttributeFetch(prevAttributes => {
            const attributeExists = prevAttributes.some(item => item.parentId === ParentId);

            if (attributeExists) {
                // Update the existing object with the same parentId
                return prevAttributes.map(item =>
                    item.parentId === ParentId ? { ...item, attrId: ChildId } : item
                );
            } else {
                // Add a new object if parentId does not exist
                return [...prevAttributes, { parentId: ParentId, attrId: ChildId }];
            }
        });

        // Set loading state
        setloading(true);
    };


    const findItemMaster = async (updatedAttributeFetch) => {
        // debugger
        // Construct filteredData using updatedAttributeFetch
        const filteredData = [{
            fieldName: "AttributeParentId",
            operatorName: "equal",
            compareValue: item.attributeParentId,
        }];

        // Call API or function with updatedAttributeFetch
        var data = await ItemMaster.GetItemMasterFilterVariation(filteredData, updatedAttributeFetch);
        if (data.length > 0) {
            setItem(data[0]);
            setloading(false);
        }
        setloading(false);
    };



    const toggleSlider = (itemData) => {
        console.log(itemData);
        setItemDataLens(itemData)
        setSliderOpen(true);
    };
    const toggleSliderClose = () => {
        // console.log(editData);
        setSliderOpen(false);
    };

    const opendesc1 = () => {
        setDescription(!description);
    };
    const opendesc2 = () => {
        setFeatures(!features);
    };
    const opendesc3 = () => {
        setSpecification(!specification);
    };

    const opendesc4 = () => {
        setReview(!review);
    };

    const showComponent = (component) => {
        setActiveComponent(component);
        setModalBox(true)
    };

    const [inputValues, setInputValues] = useState({
        d1ItemId: item.d1ItemId,
        d2ItemId: item.d2ItemId,
        d1AccountId: item.d1AccountId,
        d2AccountId: item.d1AccountId,
        userId: 1,
        quantity: 1,
        unit: "Pcs",
        itemPrice: item.itemPrice,
        lensPrice: item.lensPrice,
        lensName: item.lensName,
        coatingPrice: item.coatingPrice,
        coatingName: item.coatingName,
        box_R: "",
        box_L: "",
        sph_R: "",
        sph_L: "",
        cyl_R: "",
        cyl_L: "",
        axis_R: "",
        axis_L: "",
        add_R: "",
        add_L: "",
        dia_R: "",
        dia_L: "",
        bC_R: "",
        bC_L: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);
        // setInputValues((prevFormData) => ({ ...prevFormData, [name]: value }));
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));

    };

    const AddToCart = async () => {
        debugger
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);
        if (loginData === null) {
            loginData = {
                d1Id: 0,
                d2Id: 0,
            }
        }

        console.log()
        console.log(item)
        const data = {
            d1ItemId: item.d1_Id || item.d1ItemId,
            d2ItemId: item.d2_Id || item.d2ItemId,
            d1AccountId: loginData ? (loginData.d1Id || 0) : 0,
            d2AccountId: loginData ? (loginData.d2Id || 0) : 0,
            userId: 1,
            quantity: 1,
            unit: "Pcs",
            clientId: 0,
            itemPrice: item.salePrice || 0,
            lensPrice: item.lensPrice || 0,
            lensName: item.lensName || "",
            coatingPrice: item.coatingPrice || 0,
            coatingName: item.coatingName || "",

            // box_R: inputValues.boxesRight,
            // box_L: inputValues.boxesLeft,
            // sph_R: inputValues.sphRight,
            // sph_L: inputValues.sphLeft,
            // cyl_R: inputValues.cylRight,
            // cyl_L: inputValues.cylLeft,
            // axis_R: inputValues.axisRight,
            // axis_L: inputValues.axisLeft,
            // add_R: inputValues.addRight,
            // add_L: inputValues.addLeft,
            // dia_R: inputValues.diaRight,
            // dia_L: inputValues.diaLeft,
            // bC_R: inputValues.bcRight,
            // bC_L: inputValues.bcLeft,
        }
        const isSuccess = await Order.insertCreateEcomCartDetail(data);
        if (isSuccess) {
            // setTotalQuantity(isSuccess.length)
            fetchDataCart()
            setAlertSuccess(true)
            setInputValues({
                d1ItemId: " ",
                d2ItemId: " ",
                d1AccountId: " ",
                d2AccountId: " ",
                userId: " ",
                quantity: " ",
                unit: " ",
                itemPrice: " ",
                lensPrice: "",
                lensName: "",
                coatingPrice: "",
                coatingName: "",
                boxesLeft: "",
                boxesRight: "",
                sph_R: "",
                sph_L: "",
                cyl_R: "",
                cyl_L: "",
                axis_R: "",
                axis_L: "",
                add_R: "",
                add_L: "",
                dia_R: "",
                dia_L: "",
                bcRight: "",
                bcLeft: "",
            });

        }
    }

    useEffect(() => {
        fetchVariations(item.d1Id)

    }, [])

    const AddLensInCart = async (id, lensPrice, lensName, coatingPrice, coatingName) => {
        // debugger
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);
        if (loginData === null) {
            loginData = {
                d1Id: 0,
                d2Id: 0,
            }
        }
        console.log()
        console.log(item)
        const data = {
            d1ItemId: item.d1Id || item.d1ItemId,
            d2ItemId: item.d2Id || item.d2ItemId,
            d1AccountId: loginData ? (loginData.d1Id || 0) : 0,
            d2AccountId: loginData ? (loginData.d2Id || 0) : 0,
            userId: 1,
            quantity: 1,
            unit: "Pcs",
            itemPrice: item.salePrice,
            lensPrice: lensPrice,
            lensName: lensName,
            coatingPrice: coatingPrice,
            coatingName: coatingName,

        }
        const isSuccess = await Order.insertCreateEcomCartDetail(data);

        setInputValues(isSuccess)
        // setTotalQuantity(isSuccess.length)
        fetchDataCart()
        setAlertSuccess(true)
        setInputValues({
            d1ItemId: " ",
            d2ItemId: " ",
            d1AccountId: " ",
            d2AccountId: " ",
            userId: " ",
            quantity: " ",
            unit: " ",
            itemPrice: " ",
            lensPrice: "",
            lensName: "",
            coatingPrice: "",
            coatingName: "",
        });

    }

    const getProductIdFromUrl = async () => {
        // debugger
        const url = window.location.href;
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1]; // Assuming the ID is at the end of the URL
    };
    const fetchItem = async () => {
        debugger
        if (location.state === null && location.state === undefined) {
            var itemId = getProductIdFromUrl();
            const data = await ItemMaster.GetItemMasterById(itemId);
            if (data) {
                console.log(data);
                setItem(data);
            }
        }
    }
    useEffect(() => {
        fetchItem()
    }, [])

    useEffect(() => {
        // debugger
        getProductIdFromUrl()
        const fetchData = async () => {
            try {
                const id = getProductIdFromUrl();
                const data = await ItemMasterApi.GetItemMasterById(id);
                if (data) {
                    console.log(data);
                    setItem2(data);
                }
            } catch (error) {
                console.error('Error fetching item data:', error);
            }
        };

        fetchData();

    }, []);

    const [modelOpen, setModelOpen] = useState(false)

    useEffect(() => {
        if (alertSuccess) {
            const timeoutId = setTimeout(() => {
                setAlertSuccess(false);
            }, 500); // 3 seconds
            return () => clearTimeout(timeoutId);
        }
    }, [alertSuccess]);

    // const images = [
    //     `${imageBaseUrl}${item.images[0]}`,
    //     `${imageBaseUrl}${item.images[1]}`,
    //     `${imageBaseUrl}${item.images[2]}`,
    //     `${imageBaseUrl}${item.images[3]}`, 
    // ];
    // const images = item && item.images ? [
    //     `${imageBaseUrl}${item.images[0]}`,
    //     `${imageBaseUrl}${item.images[1]}`,
    //     `${imageBaseUrl}${item.images[2]}`,
    //     `${imageBaseUrl}${item.images[3]}`,
    // ] : [];


    const images = [];

    if (item && item.images) {
        for (let i = 0; i < Math.min(item.images.length); i++) {
            images.push(`${imageBaseUrl}${item.images[i]}`);
        }
    }

    console.log(images)
    const [components, setComponents] = useState([]); // State to hold the components

    // Function to show the component when clicked
    const showComponent1 = (component) => {
        setComponents([component]); // Set the component to display
        setModalBox(true)
    };

    const handleModalClick = (e) => {
        // Prevent the event from propagating to parent elements
        e.stopPropagation();
    };
    const hideModal3 = () => {
        setModalBox(false);
    };

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true
    };


    const ChangeImage = (index) => {
        setImageIndex(index)
    }

    const [imageIndex, setImageIndex] = useState(0)


    useEffect(() => {
        const fetchData = async () => {
            const contactlens = await ItemMasterApi.GetContactLens(item.d2Id);
            setOptionsSph(contactlens)
            console.log("contactlens", contactlens);
            console.log(item);

            if (item.productCategory == 2) {

                //*********** Sph ****************** */
                const DataSphRight = generateRanges((contactlens.r_SPH_From), (contactlens.r_SPH_To), contactlens.r_SPH_Interval);
                setIntervalValuesSphRight(DataSphRight)

                const DataSphLeft = generateRanges((contactlens.l_SPH_From), (contactlens.l_SPH_To), contactlens.l_SPH_Interval);
                setIntervalValuesSphLeft(DataSphLeft)

                //************** Cyl ****************** */

                const DataCylRight = generateRanges((contactlens.r_CYL_From), (contactlens.r_CYL_To), contactlens.r_CYL_Interval);
                setIntervalValuesCylRight(DataCylRight)

                const DataCylLeft = generateRanges((contactlens.l_CYL_From), (contactlens.l_CYL_To), contactlens.l_CYL_Interval);
                setIntervalValuesCylLeft(DataCylLeft)

                //***************** Axis ******************* */
                const DataAxisRight = generateRanges((contactlens.r_AXIS_From), (contactlens.r_AXIS_To), contactlens.r_AXIS_Interval);
                setIntervalValuesAxisRight(DataAxisRight)

                const DataAxisLeft = generateRanges((contactlens.l_AXIS_From), (contactlens.l_AXIS_To), contactlens.l_AXIS_Interval);
                setIntervalValuesAxisLeft(DataAxisLeft)

                //******************* Bc ************************ */

                const DataBcRight = generateIntervalValues((contactlens.r_BC));
                setIntervalValuesBcRight(DataBcRight)

                const DataBcLeft = generateIntervalValues((contactlens.l_BC));
                setIntervalValuesBcLeft(DataBcLeft)

                //********************** Dia ***************************** */

                const DataDiaRight = generateIntervalValues((contactlens.r_Dia));
                setIntervalValuesDiaRight(DataDiaRight)

                const DataDiaLeft = generateIntervalValues((contactlens.l_Dia));
                setIntervalValuesDiaLeft(DataDiaLeft)

                //************************* Add ****************************** */

                const DataAddRight = generateRanges((contactlens.r_Add_From), (contactlens.r_Add_To), "0.25");
                setIntervalValuesAddRight(DataAddRight)

                const DataAddLeft = generateRanges((contactlens.l_Add_From), (contactlens.l_Add_To), "0.25");
                setIntervalValuesAddLeft(DataAddLeft)
            }

        };
        fetchData();
    }, []);


    //**************************************************************** */


    const toggleWishlist = async (itemData) => {

        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);
        console.log(itemData);
        console.log(item);
        console.log(wishList);
        const existingIndex = wishList.find(product => product.d1ItemId === item.d1Id);
        console.log(existingIndex);
        if (existingIndex !== undefined) {
            // alert("Item is already in wishlist");
            try {
                const val = await Order.deleteDeleteFavouriteItemList(existingIndex.id);
                if (Boolean(val) === true) {
                    // alert("Delete data successfully", "Ok");
                    fetchDataWish()
                } else {
                    alert("Error Deleting Data");
                }
                // fetchData();
            } catch (error) {
                console.error("Error deleting account:", error);
            }
            return;
        } else {

            const data = {
                d1ItemId: item.d1Id,
                d2ItemId: item.d2Id,
                d1AccountId: loginData.d1Id,
                d2AccountId: loginData.d2Id,
                userId: 1,
            };
            try {
                const isSuccess = await Order.insertCreateFavouriteItemList(data);
                if (isSuccess) {
                    // alert("Added to wishlist successfully");
                    fetchDataWish()
                } else {
                    throw new Error("Error while adding to wishlist");
                }
            } catch (error) {
                console.error("Error while adding to wishlist:", error);
                alert("Error while adding to wishlist");
            }
            setInputValues({
                d1ItemId: "",
                d2ItemId: "",
                d1AccountId: "",
                d2AccountId: "",
                userId: "",
            });
        };
    }
    const [buyWithlens, setbuyWithlens] = useState([])

    const fetchWishList = (itemData) => {
        const userLogin = localStorage.getItem("accountLogin");
        const userArray = userLogin ? JSON.parse(userLogin) : null;

        if (userArray) {
            // UserArray is present, navigate to "/MyAccountPage"
            // setMyAccount(!myAccount);
            toggleWishlist();
        } else {
            // UserArray is not present, continue with your existing logic
            setMyAccount(!myAccount);
            document.body.style.overflow = myAccount ? "auto" : "hidden";
            signupshow();
        }
    };


    // ***************************Static Code*************************************
    const [prescriptionDetailBox, setPrescriptionDetailBox] = useState(true);
    const [prescriptionOptionBox, setPrescriptionOptionBox] = useState(false);

    const [imageToShow, setimageToShow] = useState(false)
    const [imageToShow1, setimageToShow1] = useState(false)
    const [imageToShow2, setimageToShow2] = useState(false)
    const [imageToShow3, setimageToShow3] = useState(false)
    const [featureData, setFeatureData] = useState(false)
    const [eyePower, setEyePower] = useState(false)
    const [lensType, setLenstype] = useState(true);
    const [apiData, setapiData] = useState([])
    const [apiDataFeature, setapiDataFeature] = useState([])

    const [selectedRadio, setSelectedRadio] = useState("table1");
    const handleRadioChange = (event) => {
        setSelectedRadio(event.target.value);

    };


    //********************************************************** */
    const contactLensData = [
        { label: "Spherical Power (SPH)", rightEye: "-0.75", leftEye: "-1.00" },
        { label: "Cylindrical Power (CYL)", rightEye: "-0.75", leftEye: "-1.00" },
        { label: "Axis (0 - 180)", rightEye: "-0.75", leftEye: "-1.00" }
    ];

    const featuredata = async (id) => {
        const data = apiData.result
            .filter(item => item.itemId === id)
            .map(item => item.addFeatures)
            .flat();

        console.log("Featuredata: ", data);
        setapiDataFeature(data);
        return data.length > 0

    }


    //*********************************************************** */
    const [intervalValuesSphRight, setIntervalValuesSphRight] = useState([]);
    const [intervalValuesSphLeft, setIntervalValuesSphLeft] = useState([]);
    const [intervalValuesCylRight, setIntervalValuesCylRight] = useState([]);
    const [intervalValuesCylLeft, setIntervalValuesCylLeft] = useState([]);
    const [intervalValuesAxisRight, setIntervalValuesAxisRight] = useState([]);
    const [intervalValuesAxisLeft, setIntervalValuesAxisLeft] = useState([]);
    const [intervalValuesBcRight, setIntervalValuesBcRight] = useState([]);
    const [intervalValuesBcLeft, setIntervalValuesBcLeft] = useState([]);
    const [intervalValuesDiaRight, setIntervalValuesDiaRight] = useState([]);
    const [intervalValuesDiaLeft, setIntervalValuesDiaLeft] = useState([]);
    const [intervalValuesAddRight, setIntervalValuesAddRight] = useState([]);
    const [intervalValuesAddLeft, setIntervalValuesAddLeft] = useState([]);

    const [isHidden, setIsHidden] = useState(false);
    const [optionsSph, setOptionsSph] = useState([]);
    console.log(optionsSph);
    // const {rightEyeInputs, setRightEyeInputs} = useState();



    const [formData, setFormData] = useState({
        boxesLeft: '',
        boxesRight: '',
        sphLeft: '',
        sphRight: '',
        cylLeft: '',
        cylRight: '',
        axisLeft: '',
        axisRight: '',
        bcLeft: '',
        bcRight: '',
        diaLeft: '',
        diaRight: '',
        addLeft: '',
        addRight: '',


    });

    function generateRanges(sp1, sp2, intervals) {
        // const sp1 = "-1,-3,4";
        // const sp2 = "1,-2,5";
        // const intervals = "0.50,0.25,0.50"; 

        const sp1Array = sp1.split(",").map(num => {
            const parsed = parseFloat(num);
            if (isNaN(parsed)) {
                throw new Error(`Invalid number in sp1: ${num}`);
            }
            return parsed;
        });
        const sp2Array = sp2.split(",").map(Number);
        const intervalArray = intervals.split(",").map(parseFloat);

        // Initialize an array to store all generated ranges
        const allRanges = [];

        // Iterate over each interval value
        for (let i = 0; i < intervalArray.length; i++) {
            const currentInterval = intervalArray[i];
            const range = generateRange(sp1Array[i], sp2Array[i], currentInterval);
            allRanges.push(...range);
        }
        allRanges.sort((a, b) => a - b);

        console.log("allRanges", allRanges);
        return allRanges;
    }

    function generateRange(start, end, interval) {
        // Convert interval to number
        const step = parseFloat(interval);

        // Initialize the result array
        const result = [];

        // Start iterating from the start value to the end value
        for (let value = start; value <= end; value += step) {
            // Round the value to handle floating point precision issues
            const roundedValue = Math.round(value * 100) / 100;
            // Push the rounded value to the result array
            result.push(roundedValue);
        }

        return result;
    }

    function generateIntervalValues(inputString) {
        return inputString.split(",").map(item => item.trim());
    }

    //************************************************** */  

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    };




    const optionsBoxes = [
        { value: '0 ', label: ' 0  ' },
        { value: '1 ', label: ' 1  ' },
        { value: '2 ', label: ' 2  ' },
        { value: '3 ', label: ' 3  ' },
        { value: '4 ', label: ' 4  ' },
        { value: '5 ', label: ' 5  ' },
        { value: '6 ', label: ' 6  ' },
        { value: '7 ', label: ' 7  ' },
        { value: '8 ', label: ' 8  ' },
        { value: '9 ', label: ' 9  ' },
        { value: '10 ', label: '10 ' },
        { value: '11 ', label: '11 ' },
        { value: '12 ', label: '12 ' },
        { value: '13 ', label: '13 ' },
        { value: '14 ', label: '14 ' },
        { value: '15 ', label: '15 ' },
        { value: '16 ', label: '16 ' },
    ];

    const shouldHide = formData.axisLeft === null && formData.axisRight.length === 0;

    //******************************************************* */
    const [isModalOpenSaved, setIsModalOpenSaved] = useState(false);
    const [isModalOpenManually, setIsModalOpenManually] = useState(false);

    const handleRadioChangeSaved = () => {
        setIsModalOpenSaved(true);
        setIsModalOpenManually(false);
    };

    const handleRadioChangeManually = () => {
        setIsModalOpenSaved(false);
        setIsModalOpenManually(true);
    };

    const handleRadioChangePower = () => {
        setIsModalOpenSaved(false);
        setIsModalOpenManually(false);
    };

    //*************************************************** */
    const [showModalTechnical, setShowModalTechnical] = useState(false);

    const toggleModal = () => {
        setShowModalTechnical(!showModalTechnical);
    };

    const [showModalVisit, setShowModalVisit] = useState(false);

    const toggleModalVisit = () => {
        setShowModalVisit(!showModalVisit);
    };

    const [showModalCheck, setShowModalCheck] = useState(false);

    const toggleModalCheck = () => {
        setShowModalCheck(!showModalCheck);
    };

    const [showModalReview, setShowModalReview] = useState(false);

    const toggleModalReview = () => {
        setShowModalReview(!showModalReview);
    };

    //***************************************************************** */

    const [isModalOpenInfo, setIsModalOpenInfo] = useState(false);

    const toggleModalInfo = () => {
        setIsModalOpenInfo(!isModalOpenInfo);
    };

    const closeInfoModal = () => {
        setIsModalOpenInfo(false);
    };

    const [SingleLensModalBox, setSingleLensModalBox] = useState(false)
    const showModal1 = async (lens) => {
        setSingleLensModalBox(true)
        setLenstype(lens)
        setTransition(true);
        setShowFirstComponent(false);
        setTimeout(() => {
            setTransition(false);
        }, 200);
    }
    const showModal1Hide = () => {
        setSingleLensModalBox(false)
        setFeatureData(false)
        setEyePower(false)
        setShowFirstComponent(true);
    }
    const showModal1Hidefeature = () => {
        setSingleLensModalBox(false)
        setFeatureData(false)
        setShowFirstComponent(true);

    }

    const [showFirstComponent, setShowFirstComponent] = useState(true);
    const [transition, setTransition] = useState(false);

    const handleClick = () => {
        setTransition(true);
        setTimeout(() => {
            setShowFirstComponent(false);
            setTransition(false);
        }, 1); // Duration of the slide effect should match the CSS transition duration
    };


    const [selectedFeatureId, setSelectedFeatureId] = useState(null);

    const handleModal = (id) => {
        // debugger
        setSelectedFeatureId(id);
        setSingleLensModalBox(false)
        setFeatureData(true)
    };

    const handlelastModal = () => {
        setFeatureData(false)
        setSingleLensModalBox(false)
        setEyePower(true)
    }

    console.log(AttributeData);
    console.log("Product type", item.productType === "3");


    let isActiveValue1 = null;
    if (Itemvariation.length > 0) {

        const filteredItems = Itemvariation[0].variation.filter(item => item.attr_prnt_nm.toUpperCase() === "COLOR");
        if (filteredItems.length > 0) {
            // Assuming isActive is a property of the filtered item 
            isActiveValue1 = filteredItems[0].isActive;
        } else {
            // Handle case where no items match the filter condition
            console.log("No items with attr_prnt_nm === 'color' found");
        }

    }

    const handleNext = () => {
        if (currentIndex + itemsPerPageRelated < apiData.length) {
            setCurrentIndex(currentIndex + itemsPerPageRelated);
        }
    };

    const handlePrev = () => {
        if (currentIndex - itemsPerPageRelated >= 0) {
            setCurrentIndex(currentIndex - itemsPerPageRelated);
        }
    };

    const fetchData1 = async () => {
        try {
            const val = await ItemMaster.getItemRelatedProdSetting(item.d1Id)
            console.log(val);
            setapiData(val);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        fetchData1();
    }, [item]);



    const [itemsPerPageRelated, setItemsPerPageRelated] = useState(4);

    // Function to determine the number of items to show based on the screen size
    const numItemsToShow = () => {
        if (window.innerWidth < 768) {
            return 2;
        } else if (window.innerWidth < 950) {
            return 4;
        } else {
            return 4;
        }
    };

    // Update the number of items per page based on the screen size
    const updateItemsPerPage = () => {
        setItemsPerPageRelated(numItemsToShow());
    };

    // Add event listener to update the number of items on window resize
    useEffect(() => {
        updateItemsPerPage();
        window.addEventListener('resize', updateItemsPerPage);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', updateItemsPerPage);
    }, []);

    const ArrowButtonPrevious = ({ onClick }) => {
        return (
            <button
                onClick={onClick}
                style={{ backgroundColor: "transparent", border: "none" }}
            >
                <img
                    src={ArrowLeft}
                    alt={"Left Arrow"}
                    style={{
                        width: "50px",
                        height: "50px",

                    }}
                />
            </button>
        );
    };

    const ArrowButtonNext = ({ onClick }) => {
        return (
            <button
                onClick={onClick}
                style={{ backgroundColor: "transparent", border: "none" }}
            >
                <img
                    src={ArrowRight}
                    alt={"Left Arrow"}
                    style={{
                        width: "50px",
                        height: "50px",

                    }}
                />
            </button>
        );
    };

    const settings1 = {
        // dots: true,
        // fade: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        // slidesPerRow: 2,
        // waitForAnimate: false,
        // className: "center",
        // centerMode: true,
        prevArrow: <ArrowButtonPrevious />,
        nextArrow: <ArrowButtonNext />
    };

    return (
        <div>
            <div className="ProductDetailPage">
                <div className="ProductDetailPage-left-theme3">
                    <div className="ProductDetailPage-imagebox2 ProductDetailPage-imagebox2-theme3">

                        <div style={{ width: "100%" }}>
                            <div className="lenses-show-multiple" style={{ display: "grid", gridTemplateColumns: images.length >= 2 ? "repeat(2, 1fr)" : "repeat(1, 1fr)", gap: "10px" }}>
                                {images.map((imageUrl, index) => (
                                    <div key={index}>
                                        <div
                                            onClick={() => showComponent1(<ProductSwap_LC items={item} imageIndex={imageIndex} />)}
                                            className="ProductDetailPage-smallimg-theme3"
                                        >
                                            {/* <img
                                                className="ProductDetailPage-image"
                                                src={imageUrl}
                                                alt=""
                                            /> */}
                                            <div
                                                class="ProductDetailPage-image"
                                                style={{ backgroundImage: `url(${imageUrl})` }}
                                            >
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* Render the currently selected component */}
                                {modalBox && (

                                    <div onClick={hideModal3} className="modal-back">
                                        <div onClick={handleModalClick} style={{ zIndex: "5" }} className="OpticalField-modal increase_modal">
                                            <div style={{ padding: "15px" }}>
                                                <div className="Modal-Display">
                                                    <label className="close_modal-theme3" onClick={hideModal3} ><i class="fa-solid fa-xmark"></i></label>
                                                </div>
                                                <div style={{ display: "flex", justifyItems: "baseline", justifyContent: "center" }}>
                                                    <div className="ing-size-xx-xl-theme3">
                                                        <ProductSwap_LC items={item} imageIndex={imageIndex} />
                                                    </div>
                                                </div>
                                            </div>


                                            <div style={{ display: "flex" }}>
                                                <div className=" ProductDetailPage-imagebox1-theme3">
                                                    <div className="Flex-ImagesTheme_Lc">
                                                        {/* <Slider {...settings1}>
                                                            {images.map((imageUrl, index) => (
                                                                <div key={index} className="Flex-ImagesTheme_Lc">
                                                                    <div
                                                                        onClick={() => ChangeImage(index)}
                                                                        className="ProductDetailPage-smallimg-theme lense-view-container-theme3"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                backgroundImage: `url(${imageUrl})`,
                                                                                backgroundPosition: "center",
                                                                                backgroundRepeat: "no-repeat",
                                                                                backgroundSize: "100%",
                                                                                width: "100%",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </Slider> */}
                                                        {images.length > 4 ? (
                                                            <Slider {...settings1} className="slick-slider-custom">
                                                                {images.map((imageUrl, index) => (
                                                                    <div key={index} className="Flex-ImagesTheme_Lc" >
                                                                        <div
                                                                            onClick={() => ChangeImage(index)}
                                                                            className="ProductDetailPage-smallimg-theme lense-view-container-theme3"
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    backgroundImage: `url(${imageUrl})`,
                                                                                    backgroundPosition: "center",
                                                                                    backgroundRepeat: "no-repeat",
                                                                                    backgroundSize: "100%",
                                                                                    width: "100%",
                                                                                    // height:"200px"
                                                                                    // Adjust height as needed
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </Slider>
                                                        ) : (
                                                            // Optionally, render a different component or message when there are 4 or fewer images
                                                            <div style={{ display: "contents" }}>
                                                                {images.map((imageUrl, index) => (
                                                                    <div key={index} className="Flex-ImagesTheme_Lc">
                                                                        <div
                                                                            onClick={() => ChangeImage(index)}
                                                                            className="ProductDetailPage-smallimg-theme lense-view-container-theme3"
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    backgroundImage: `url(${imageUrl})`,
                                                                                    backgroundPosition: "center",
                                                                                    backgroundRepeat: "no-repeat",
                                                                                    backgroundSize: "100%",
                                                                                    width: "100%",
                                                                                    // Adjust height as needed
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ProductDetailPage-right">
                    <div className="productdetailbox">
                        <div className="Flex-WishList-Lc">
                            <div>
                                {/* <p className="Product-Font-Top_Lc">Bausch & Lomb iConnect</p> */}
                            </div>
                            <div style={{ cursor: "pointer", position: "absolute", right: "0px", zIndex: "1" }} onClick={() => fetchWishList(item.id)}>
                                {(wishList && wishList.find(product => product.d1ItemId === item.d1Id)) ? (
                                    <i className="fa-solid fa-heart HeartSaveBtn3 "></i>
                                ) : (
                                    <i className="fa-regular fa-heart HeartSaveBtn3"></i>
                                )}
                            </div>
                        </div>
                        <p className="productdetail-name font-2">
                            {item.sku}
                        </p>
                        <p className="productdetail-name">
                            {/* <div className="product-detail-name">
                                4.7 <i className="fa-solid fa-star product-detail-star"></i>{" "}
                                1507
                            </div> */}
                        </p>
                        <div
                            className="productdetail-name"
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <p className="product-detail-price">
                                <i className="fa-solid fa-indian-rupee-sign"></i> {item.salePrice}
                            </p>
                            <p className="card-product-price-2">
                                MRP &nbsp;
                                <i className="fa-solid fa-indian-rupee-sign"></i>
                                &nbsp;
                                <span className="mrp-price">{item.mrpPrice}</span>
                            </p>
                            <p className="discount-percentage">
                                {parseInt((((item.mrpPrice - item.salePrice) / item.mrpPrice) * 100).toFixed(2))}% off
                            </p>
                        </div>

                        {isActiveValue1 && (
                            <>
                                {/* <div className="Card-product-type-1-color-box-1">
                                    {filteredColor.map(color => (
                                        <div className="outline-coloroption-c2"
                                        //  onClick={() => getParentId(color.parentId[0], color.childId[0])}
                                        >
                                            <div style={{ backgroundColor: color.attrName }} className="color-option-box-main"></div>
                                        </div>
                                    ))}
                                </div> */}
                                {/* <div className="Card-product-type-1-color-box-1">
                                    <div
                                        onClick={() => colorchange1({ target: { id: "color-1" } })}

                                        className="outline-coloroption-c2"
                                    >
                                        <div className="color-option-box-main color-option-box1-c2"></div>
                                    </div>
                                    <div
                                        onClick={() => colorchange1({ target: { id: "color-2" } })}
                                        className="outline-coloroption-c2"
                                    >
                                        <div className="color-option-box-main color-option-box2-c2"></div>
                                    </div>
                                    <div
                                        onClick={() => colorchange1({ target: { id: "color-3" } })}
                                        className="outline-coloroption-c2"
                                    >
                                        <div className="color-option-box-main color-option-box3-c2"></div>
                                    </div>
                                    <div
                                        onClick={() => colorchange1({ target: { id: "color-4" } })}
                                        className="outline-coloroption-c2"
                                    >
                                        <div className="color-option-box-main color-option-box4-c2"></div>
                                    </div>
                                    <div
                                        onClick={() => colorchange1({ target: { id: "color-5" } })}
                                        className="outline-coloroption-c2"
                                    >
                                        <div className="color-option-box-main color-option-box5-c2"></div>
                                    </div>
                                </div> */}
                            </>
                        )}


                        {/* {AttributeData.map(item  => (
                                <>
                                    {item.attributeChilds.map(data => (
                                        <div style={{ display: "flex" }}>{data.attrName} </div>
                                    ))}
                                </>
                            ))} */}


                        {/* <div>
                            {item.productType === "3" && filteredAttributeData.length > 0 && filteredAttributeData.map(category => (
                                <div key={category.attributeId}>
                                    <h3 style={{ fontSize: "15px" }}>{category.attributeName}</h3>
                                    <div className="sizeVarient">
                                        {category.attributeChilds.map(attribute => {
                                            const index = selectedParents.indexOf(category.attributeId);
                                            const isSelected = index !== -1 && selectedAttributes[index] === attribute.attrId;

                                            return (
                                                <div
                                                    key={attribute.attrId}
                                                    className={isSelected ? 'selectedAttribute' : 'notSelectedAttribute'}
                                                    onClick={() => SettingAttribute(attribute.attrId, category.attributeId)}
                                                >


                                                    {attribute.attrName}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div> */}

                        <div>
                            {item.productType === "3" && filteredAttributeData.length > 0 && filteredAttributeData.map(category => (
                                <div key={category.attributeId}>
                                    <h3 style={{ fontSize: "15px" }}>{category.attributeName}</h3>
                                    <div className="sizeVarient">
                                        {category.attributeChilds.map(attribute => {
                                            debugger
                                            const index = selectedParents.indexOf(category.attributeId);
                                            const isSelected = index !== -1 && selectedAttributes[index] === attribute.attrId;

                                            /* Attribute child Method */
                                            // const itemWithCntrlType = Itemvariation.find(item =>
                                            //     item.variation.some(variant => variant.attr === attribute.attrId)
                                            // );
                                            // const cntrlType = itemWithCntrlType ? itemWithCntrlType.cntrlTypeImgTxt : 2;

                                            // const imageId = attribute.imageId || 0;

                                            // const displayContent = cntrlType === 1 && imageId
                                            //     ? <img src={`${imageBaseUrl}${imageId}`} alt={attribute.attrName} />
                                            //     : attribute.attrName;





                                            /* Getting IMage From Item */

                                            const itemVariation = Itemvariation.find(variation =>
                                                variation.variation.some(variant => variant.attr === attribute.attrId)
                                            );
                                            const imageId = item.images.length > 0 ? item.images[0] : 0;

                                            const cntrlType = itemVariation ? itemVariation.cntrlTypeImgTxt : 2;
                                            const displayContent = cntrlType === 1 && imageId
                                                ? <img src={`${imageBaseUrl}${imageId}`} alt={attribute.attrName} width={40} height={40} />
                                                : attribute.attrName;

                                            return (
                                                <div
                                                    key={attribute.attrId}
                                                    className={isSelected ? 'selectedAttribute' : 'notSelectedAttribute'}
                                                    onClick={() => SettingAttribute(attribute.attrId, category.attributeId)}
                                                >
                                                    {displayContent}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div>



                        <p className="productdetail-name">
                            <div>
                                {/* <p className="product-detail-desc">
                                    Black shade glasses and one of the best glasses of our store
                                    and Black shade glasses and one of the best glasses of our
                                    store
                                </p> */}
                            </div>
                        </p>

                        {item.productCategory == "2" ? (
                            <div>
                                <div className="OrderOnPhone-line_Lc"></div>
                                <div className="Order-phone_Lc">ORDER ON PHONE</div>
                                <div className="Give-Missed-Lc">
                                    GIVE MISSED CALL : +918470007367
                                </div>
                                {/* <div className="OrderOnPhone-line_Lc"></div> */}

                                <div className="Select-Power-Lc">SELECT POWER</div>

                                <div className="Border-Box-Lc">
                                    <div className="Flex-Radio-Button-Lc">
                                        <input
                                            className="Radio-Input-Box-Lc"
                                            type="radio"
                                            name="power"
                                            id=""
                                            onChange={handleRadioChangePower}
                                        ></input>
                                        <p className="product-detail-desc Radio-font-lc">
                                            Call me later for eye power or submit after the checkout
                                        </p>
                                    </div>
                                    <div className="Flex-Radio-Button-Lc">
                                        <input
                                            className="Radio-Input-Box-Lc"
                                            type="radio"
                                            name="power"
                                            onChange={handleRadioChangeSaved}
                                        ></input>
                                        <p className="product-detail-desc Radio-font-lc">
                                            Use Saved Prescription
                                        </p>
                                    </div>
                                    {isModalOpenSaved && (
                                        <div className="Border-Box-Lc">
                                            <div className="Box-padding-themeLc">
                                                <div className="Flex-User-Selected">
                                                    <div className="L-Elipse-Lc">L</div>
                                                    <div>
                                                        <p className="user-lc-font">lenskart user</p>
                                                        <p className="Added-font-Lc">Added on: 24</p>
                                                        <p className="Added-font-Lc">May 2024</p>
                                                    </div>
                                                    <div>
                                                        <button className="Selected-Btn-Lc">
                                                            {" "}
                                                            <i class="fa-solid fa-circle-check"></i>Selected
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="Border-Box-Lc">
                                                    <table className="Table-Sub-Main_Lc">
                                                        <div className="ContactLens-Font-Lc">CONTACT LENS</div>
                                                        <tr>
                                                            <th>
                                                                <button className="prehistory-btn">
                                                                    Pre. History
                                                                </button>
                                                            </th>
                                                            <th>RIGHT EYE</th>
                                                            <th>LEFT EYE</th>
                                                        </tr>
                                                        {contactLensData.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.label}</td>
                                                                <td>{item.rightEye}</td>
                                                                <td>{item.leftEye}</td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="Flex-Radio-Button-Lc">
                                        <input
                                            className="Radio-Input-Box-Lc"
                                            type="radio"
                                            name="power"
                                            onChange={handleRadioChangeManually}
                                        ></input>
                                        <p className="product-detail-desc Radio-font-lc">
                                            Enter Prescription Manually
                                        </p>
                                    </div>
                                    {isModalOpenManually && (
                                        <div className="Border-Box-Lc">
                                            <div className="Box-padding-themeLc">
                                                <div>
                                                    <p className="product-detail-desc Radio-font-lc">
                                                        Contact lens power can be different from Eyeglass power
                                                    </p>
                                                </div>

                                                <div className="Flex-Right-Arrow-Lc">
                                                    <p className="product-detail-desc Radio-font-lc Power-Color-Lc">
                                                        LEARN HOW TO READ YOUR PRESCRIPTION
                                                    </p>
                                                    <i className="fa-solid fa-angle-right right-arrow-iconLC"></i>
                                                </div>
                                                {/* <div className="OrderOnPhone-line_Lc"></div> */}
                                                <div className="Flex-Right-Arrow-Lc">
                                                    <p className="product-detail-desc Radio-font-lc Power-Color-Lc">
                                                        CONVERT EYEGLASSES POWER TO CONTACT LENS
                                                    </p>
                                                    <i className="fa-solid fa-angle-right right-arrow-iconLC"></i>
                                                </div>

                                                <table className="Table-Main_Lc">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <button className="prehistory-btn">
                                                                    Pre. History
                                                                </button>
                                                            </th>
                                                            <th>OS (LEFT EYE)</th>
                                                            <th>OD (RIGHT EYE)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                Boxes
                                                                <i class="fa-regular fa-circle-question question-mark"></i>
                                                                <div className="description">
                                                                    <ul className="ul-padding-left">
                                                                        <li className="li-margin-bot">
                                                                            A box has 6 Lens per box for 1 eye. To learn
                                                                            more, click here
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <select
                                                                    className="Left-Eye-select_lc"
                                                                    name="boxesLeft"
                                                                    value={inputValues.boxesLeft}
                                                                    onChange={handleInputChange}
                                                                >
                                                                    {optionsBoxes.map((option, index) => (
                                                                        <option key={index} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select
                                                                    className="Left-Eye-select_lc"
                                                                    name="boxesRight"
                                                                    value={inputValues.boxesRight}
                                                                    onChange={handleInputChange}
                                                                >
                                                                    {optionsBoxes.map((option, index) => (
                                                                        <option key={index} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        {intervalValuesAxisLeft.length != 0 && (
                                                            <tr>
                                                                <td>
                                                                    Sph<i class="fa-regular fa-circle-question "></i>
                                                                    <div className="description">
                                                                        <ul className="ul-padding-left">
                                                                            <li className="li-margin-bot">
                                                                                This is your main power and is a + or - number
                                                                                or 0.To learn more
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="sphLeft"
                                                                        value={inputValues.sphLeft}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesSphLeft !== null && Array.isArray(intervalValuesSphLeft)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesSphLeft.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="sphRight"
                                                                        value={inputValues.sphRight}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesSphRight !== null && Array.isArray(intervalValuesSphRight)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesSphRight.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {intervalValuesCylLeft.length != 0 && (
                                                            <tr>
                                                                <td>
                                                                    Cyl<i class="fa-regular fa-circle-question "></i>
                                                                    <div className="description">
                                                                        <ul className="ul-padding-left">
                                                                            <li className="li-margin-bot">
                                                                                This is your main power and is a + or - number
                                                                                or 0.To learn more
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="cylLeft"
                                                                        value={inputValues.cylLeft}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesCylLeft !== null && Array.isArray(intervalValuesCylLeft)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesCylLeft.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="cylRight"
                                                                        value={inputValues.cylRight}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesCylRight !== null && Array.isArray(intervalValuesCylRight)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesCylRight.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {intervalValuesAxisLeft.length != 0 && (
                                                            <tr style={{ display: shouldHide ? 'none' : 'table-row' }}>
                                                                <td>
                                                                    Axis<i class="fa-regular fa-circle-question "></i>
                                                                    <div className="description">
                                                                        <ul className="ul-padding-left">
                                                                            <li className="li-margin-bot">
                                                                                This is your main power and is a + or - number
                                                                                or 0.To learn more
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="axisLeft"
                                                                        value={inputValues.axisLeft}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesAxisLeft !== null && Array.isArray(intervalValuesAxisLeft)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesAxisLeft.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="axisRight"
                                                                        value={inputValues.axisRight}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesAxisRight !== null && Array.isArray(intervalValuesAxisRight)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesAxisRight.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {intervalValuesBcLeft.length != 0 && (
                                                            <tr>
                                                                <td>
                                                                    Bc<i class="fa-regular fa-circle-question "></i>
                                                                    <div className="description">
                                                                        <ul className="ul-padding-left">
                                                                            <li className="li-margin-bot">
                                                                                This is your main power and is a + or - number
                                                                                or 0.To learn more
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="bcLeft"
                                                                        value={inputValues.bcLeft}
                                                                        onChange={handleInputChange} >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesBcLeft !== null && Array.isArray(intervalValuesBcLeft)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesBcLeft.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="bcRight"
                                                                        value={inputValues.bcRight}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesBcRight !== null && Array.isArray(intervalValuesBcRight)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesBcRight.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {intervalValuesDiaLeft.length != 0 && (
                                                            <tr>
                                                                <td>
                                                                    Dia<i class="fa-regular fa-circle-question "></i>
                                                                    <div className="description">
                                                                        <ul className="ul-padding-left">
                                                                            <li className="li-margin-bot">
                                                                                This is your main power and is a + or - number
                                                                                or 0.To learn more
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="diaLeft" value={inputValues.diaLeft}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesDiaLeft !== null && Array.isArray(intervalValuesDiaLeft)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesDiaLeft.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="diaRight" value={inputValues.diaRight}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesDiaRight !== null && Array.isArray(intervalValuesDiaRight)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesDiaRight.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {intervalValuesAddLeft.length != 0 && (
                                                            <tr>
                                                                <td>
                                                                    Add<i class="fa-regular fa-circle-question "></i>
                                                                    <div className="description">
                                                                        <ul className="ul-padding-left">
                                                                            <li className="li-margin-bot">
                                                                                This is your main power and is a + or - number
                                                                                or 0.To learn more
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="addLeft"
                                                                        value={inputValues.addLeft}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesAddLeft !== null && Array.isArray(intervalValuesAddLeft)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesAddLeft.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="addRight" value={inputValues.addRight}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesAddRight !== null && Array.isArray(intervalValuesAddRight)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesAddRight.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : null}

                        <div className="productdetail-bottom-box">
                            <div className="product-detail-button-box-theme3 ">
                                <div
                                    onClick={() => AddToCart(item.id)} className="product-detail-button-theme3">
                                    Buy Now{" "}
                                </div>
                                {item.productCategory == "1" ? (
                                    <div onClick={() => toggleSlider(item)}
                                        className="product-detail-button-theme3">
                                        Buy With Lens
                                    </div>
                                ) : null}
                            </div>
                            <div className="productdetail-name">
                                <p className="product-detail-return">
                                    Return Policy : 7 day easy return policy
                                </p>
                                {/* <p
                                     onClick={openproductenquiry}
                                    className="product-detail-enquiry"
                                >
                                    Product Enquiry
                                </p> */}
                            </div>
                            <div className="Flex-Technical-Arrow" onClick={toggleModal}>
                                <div className="Technical-Down-Lc">Technical information</div>
                                <div className="">
                                    {/* <i className="fa-solid fa-angle-down right-arrow-iconLC"></i> */}
                                    <i
                                        className={`fa-solid fa-angle-${showModalTechnical ? "up" : "down"
                                            } right-arrow-iconLC`}
                                    ></i>
                                </div>
                            </div>
                            {showModalTechnical && (
                                <div className="Features-Technical-Box">
                                    <table>

                                        <div className="" style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: item.shortDescription }} />
                                        <tr className="">
                                            <td
                                                className="Font-TechInfo_Lc Color-ShowAllInfo-Lc"
                                                onClick={toggleModalInfo}
                                            >
                                                Show All Information
                                            </td>
                                            <td className="Font-TechInfo_Lc"></td>
                                        </tr>
                                    </table>
                                </div>
                            )}

                            {isModalOpenInfo && (
                                <div onClick={hideModal3} className="modal-back">
                                    <div
                                        onClick={handleModalClick}
                                        style={{ zIndex: "5" }}
                                        className="OpticalField-modal increase_modal"
                                    >
                                        <div style={{ padding: "5px" }}>
                                            <div className="Modal-Title-Lc">Technical information
                                                <label
                                                    className="close_modal-theme3"
                                                    onClick={closeInfoModal}
                                                >
                                                    <i class="fa-solid fa-xmark"></i>
                                                </label>
                                            </div>
                                            <div>
                                                <div className="" style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: item.longDescription }} />
                                                {/* <div className="Modal-Lc-theme3">
                                                    <ModalTechnicalInforamation />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* <div className="OrderOnPhone-line_Lc"></div> */}
                            {/* <div className="Flex-Technical-Arrow" onClick={toggleModalVisit}>
                                <div className="Technical-Down-Lc" >Visit Nearby Store</div>
                                <div className="">
                             
                                    <i className={`fa-solid fa-angle-${showModalVisit ? "up" : "down"
                                        } right-arrow-iconLC`}></i>
                                </div>
                            </div> */}
                            {/* {showModalVisit && (
                                <div className="Flex-Visit-Store">
                                    <div className="Visit-Near-width">
                                        <img src={img5} alt="" />
                                    </div>
                                    <div className="Visit-Near-width">
                                        <p>Please Share Your Location To See Nearby Stores</p>
                                        <p className="Color-ShowAllInfo-Lc">Store Locator</p>

                                    </div>
                                </div>
                            )} */}
                            {/* <div className="OrderOnPhone-line_Lc"></div>
                            <div className="Flex-Technical-Arrow" onClick={toggleModalCheck}>
                                <div className="Technical-Down-Lc">Check Delivery Options</div>
                                <div className="">
                                    <i className={`fa-solid fa-angle-${showModalCheck ? "up" : "down"
                                        } right-arrow-iconLC`}></i>
                                </div>
                            </div>
                            {showModalCheck && (
                                <div className="Flex-input-box-delivery">
                                    <input className="Pincode-width-Lc" type="text" placeholder="Enter Pin Code" />
                                    <button className="Check-Btn-Lc" type="button">CHECK</button>
                                </div>
                            )} */}
                            {/* <div className="OrderOnPhone-line_Lc"></div>
                            <div className="Flex-Technical-Arrow" onClick={toggleModalReview}>
                                <div className="Technical-Down-Lc">Review(380)</div>
                                <div className="">
                                    <i className={`fa-solid fa-angle-${showModalReview ? "up" : "down"
                                        } right-arrow-iconLC`}></i>
                                </div>
                            </div> */}
                            {/* {showModalReview && (
                                <div className="Contact-Review-Padding-Lc">
                                    <div>
                                        <i class="fa-solid fa-star star-color-Lc"></i>
                                        <i class="fa-solid fa-star star-color-Lc"></i>
                                        <i class="fa-solid fa-star star-color-Lc"></i>
                                        <i class="fa-solid fa-star star-color-Lc"></i>
                                        <i class="fa-solid fa-star star-color-Lc"></i>
                                    </div>
                                    <div>
                                        <p className="Font-Contact-Lenses_Lc">Contact Lenses</p>
                                        <p>Easy to use and comfortable</p>
                                        <p>CHARMS U. - Jan 18, 2024</p>
                                    </div>
                                    <div className="Flex-Button-MoreWrite">
                                        <button className="More-Review-btn" type="button">MORE REVIEWS</button>
                                        <button className="write-Review-btn" type="button">WRITE A REVIEW</button>
                                    </div>
                                </div>
                            )} */}
                        </div>
                    </div>
                </div>
            </div>



            {/* <div className="ProductDetailPage-bottom">
                <div className="ProductDetailPage-bottom-1">
                    <div onClick={opendesc1} className="accordion-box">
                        Description
                        {description ? (
                            <div>
                                <i className="fa-solid fa-minus minus-btn"></i>
                            </div>
                        ) : (
                            <div>
                                <i className="fa-solid fa-plus plus-btn"></i>
                            </div>
                        )}
                    </div>
                    {description && (
                        <div className="Description-accordian-box">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
                                laborum, nemo pariatur minima minus totam sit accusantium
                                impedit sunt mollitia repellat adipisci dolorum iure
                                exercitationem nihil, libero neque recusandae consequatur
                                numquam repellendus. Quibusdam alias placeat animi labore dolor
                                corporis delectus nemo nam quas provident sint sequi, eos,
                                deleniti, perferendis sed.
                            </p>
                        </div>
                    )}
                    <div onClick={opendesc2} className="accordion-box">
                        Features
                        {features ? (
                            <div>
                                <i className="fa-solid fa-minus minus-btn"></i>
                            </div>
                        ) : (
                            <div>
                                <i className="fa-solid fa-plus plus-btn"></i>
                            </div>
                        )}
                    </div>
                    {features && (
                        <div className="Features-accordian-box">
                            <table>
                                <tr className="dark-tablerow">
                                    <td> Frame Material</td>
                                    <td>Metal</td>
                                </tr>
                                <tr className="light-tablerow">
                                    <td> Lens Material</td>
                                    <td>Polycarbonate</td>
                                </tr>
                                <tr className="dark-tablerow">
                                    <td>Frame Color</td>
                                    <td>Black</td>
                                </tr>
                                <tr className="light-tablerow">
                                    <td>Shape</td>
                                    <td>Square</td>
                                </tr>
                                <tr className="dark-tablerow">
                                    <td>Brand</td>
                                    <td>KILLER</td>
                                </tr>
                                <tr className="light-tablerow">
                                    <td>Gender</td>
                                    <td>Men, Women</td>
                                </tr>
                                <tr className="dark-tablerow">
                                    <td>Dimensions</td>
                                    <td>48-20-140</td>
                                </tr>
                                <tr className="light-tablerow">
                                    <td>SKUID</td>
                                    <td>E12B10286</td>
                                </tr>
                                <tr className="dark-tablerow">
                                    <td>Product type</td>
                                    <td>eyeglasses</td>
                                </tr>
                                <tr className="light-tablerow">
                                    <td>Country of Origin</td>
                                    <td>India</td>
                                </tr>
                                <tr className="dark-tablerow">
                                    <td>Manufactured by</td>
                                    <td>Scavin International Exim Pvt. Ltd.</td>
                                </tr>
                                <tr className="light-tablerow">
                                    <td>Sold by</td>
                                    <td>EyeMyEye Pvt Ltd</td>
                                </tr>
                                <tr className="dark-tablerow">
                                    <td>Raw Material Sourced from</td>
                                    <td> India </td>
                                </tr>
                            </table>
                        </div>
                    )}
                    <div onClick={opendesc3} className="accordion-box">
                        Specification
                        {specification ? (
                            <div>
                                <i className="fa-solid fa-minus minus-btn"></i>
                            </div>
                        ) : (
                            <div>
                                <i className="fa-solid fa-plus plus-btn"></i>
                            </div>
                        )}
                    </div>
                    {specification && (
                        <div className="Specification-accordian-box">
                            <p>✔ Full Frame</p>
                            <p>✔ Light Weight and Comfortable</p>
                            <p>✔ Strong and Durable</p>
                            <p>✔ Free Eyeglasses Case with Cleaning Cloth</p>
                        </div>
                    )}

                    <div
                        onClick={opendesc4}
                        className="accordion-box"
                        style={{ borderBottom: "0" }}
                    >
                        Review & Rating
                        {review ? (
                            <div>
                                <i className="fa-solid fa-minus minus-btn"></i>
                            </div>
                        ) : (
                            <div>
                                <i className="fa-solid fa-plus plus-btn"></i>
                            </div>
                        )}
                    </div>
                    {review && <ReviewAndRating />}
                </div>
            </div> */}

            {/* ********************** Related Product*********************** */}

            <div className="cartpage-theme4">
                {apiData.length >= 0 && (
                    <div className="wish-side-ihdds2">
                        <div>
                            <h2 className="head-body-arrow">Related Product</h2>
                        </div>
                        <div>
                            <i className="fa-solid fa-arrow-left left-right-arrow" onClick={handlePrev} disabled={currentIndex === 0}></i>
                            <i className="fa-solid fa-arrow-right left-right-arrow" onClick={handleNext} disabled={currentIndex + itemsPerPageRelated >= apiData.length}></i>
                        </div>
                    </div>
                )}
                <div style={{ cursor: "pointer" }} className="cartpage-pricebox-container-theme3-pt">
                    {apiData && apiData.slice(currentIndex, currentIndex + itemsPerPageRelated).map((product, index) => (
                        <Link to={`/ProductDetailTheme/${product.mainItemD2Id}`}
                            state={{ item: product }} key={index} className="pricebox-wishlist-container">
                            {/* <div key={index} className="pricebox-wishlist-container"> */}
                            <div className="wishlist_background" style={{ background: `url(${imageBaseUrl}${product.selectedImageId[0]}) no-repeat center center`, backgroundSize: 'cover' }}>
                            </div>
                            <div className="title-name-wl-3">
                                <p>{product.selectedItemName}</p>
                            </div>
                            <div className="price-show-wl-3">
                                <i className="fa-solid fa-indian-rupee-sign"></i> {product.salePrice}
                            </div>
                            {/* </div> */}
                        </Link>
                    ))}
                </div>
            </div>



            <Banner1 />

            {productEnquiry && (
                <ProductEnquiryModal handleClick={openproductenquiry} />
            )}
            {alertSuccess && (<AlertSuccess />)}
            <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
                <div >
                    <div>
                        <div className="slider-display-allow">
                            <div className="slider-head-gm"><i onClick={showModal1Hide} class="fa-solid fa-arrow-left-long slide1-left"></i> <h2 className="slider-top-head-nm">
                                Select lens Type </h2></div>
                            <div onClick={toggleSliderClose} className="slider-head-gm"><i class="fa-solid fa-xmark slider-cross-btn"></i></div>
                        </div>
                    </div>

                    <div className="">
                        {showFirstComponent && (
                            <div className={`component first ${transition ? 'exit' : ''}`}
                            // onClick={handleClick}
                            >
                                <div className="buywithlens-content">
                                    <div className="buywithlens-left">
                                        <div className={`buy-with-lens-option-slider-theme3`}
                                            onClick={() => showModal1(0)}  >
                                            <div>
                                                <img src={img11} alt="" className="slider-lens-picture" />
                                            </div>
                                            <div className="lens-product-type-x1">
                                                <div className="lens-option-top-theme3">
                                                    <p>Single Vision</p>
                                                </div>
                                                <div className="lens-option-pararaph-theme3"
                                                    onClick={() => showModal1()}  >
                                                    For Distance or Near Vision (Thin, Anti Glare, Blue Cut)
                                                </div>
                                            </div>
                                            <div className="lens-product-type-x2">
                                                <i class="fa-solid fa-chevron-right lens-type-icon-xl"></i>
                                            </div>
                                        </div>
                                        <div
                                            className="buy-with-lens-option-slider-theme3"
                                            // onClick={() => setSelectedRadio("table2")}
                                            onClick={() => showModal1(1)}
                                        >
                                            <div>
                                                <img src={img22} alt="" className="slider-lens-picture" />
                                            </div>
                                            <div className="lens-product-type-x1">
                                                <div className="lens-option-top-theme3"
                                                    onClick={() => showModal1()} >
                                                    <p>Bifocal/Progressive</p>
                                                </div>
                                                <div className="lens-option-pararaph-theme3"
                                                    onClick={() => showModal1()} >
                                                    BiFocal And Progressive (For Two powers in same Lenses)
                                                </div>
                                            </div>
                                            <div className="lens-product-type-x2">
                                                <i class="fa-solid fa-chevron-right lens-type-icon-xl"></i>

                                            </div>
                                        </div>
                                        <div
                                            className="buy-with-lens-option-slider-theme3"
                                            // onClick={() => setSelectedRadio("table3")}
                                            onClick={() => showModal1(2)}                                        >
                                            <div>
                                                <img src={img33} alt="" className="slider-lens-picture" />
                                            </div>
                                            <div className="lens-product-type-x1">
                                                <div className="lens-option-top-theme3"
                                                    onClick={() => showModal1()} >

                                                    <p>Zero Power</p>

                                                </div>
                                                <div className="lens-option-pararaph-theme3"
                                                    onClick={() => showModal1()} >
                                                    Block 98% of harmful rays ( Anti glare and Blue-cut options)
                                                </div>
                                            </div>
                                            <div className="lens-product-type-x2">

                                                <i class="fa-solid fa-chevron-right lens-type-icon-xl"></i>
                                            </div>
                                        </div>

                                        <div
                                            className="buy-with-lens-option-slider-theme3"
                                            // onClick={() => setSelectedRadio("table4")}
                                            onClick={() => showModal1(3)}
                                        >
                                            <div>
                                                <img src={img44} alt="" className="slider-lens-picture" />
                                            </div>
                                            <div className="lens-product-type-x1">
                                                <div className="lens-option-top-theme3"
                                                    onClick={() => showModal1()} >
                                                    <p>Tinted Single Vision</p>
                                                </div>
                                                <div className="lens-option-pararaph-theme3" onClick={() => showModal1()}>
                                                    For Distance or Near Vision (green, Gray , Brown)
                                                </div>
                                            </div>
                                            <div className="lens-product-type-x2">
                                                <i class="fa-solid fa-chevron-right lens-type-icon-xl"></i>
                                            </div>
                                        </div>
                                        <div className="cart-btn-price">

                                            <div className="slide-head-bar-td-1" >
                                                <div className="slide-call-head-bar"><i class="fa-solid fa-phone-volume"></i>&nbsp;&nbsp;Not sure what to select?&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <h4>Call 1800-202-4444</h4>
                                                </div>
                                                <div>
                                                    <div className="slide-sub-amount-bar">SubTotal (Frame)</div>
                                                    <div className="slide-sub-amount-bar"><i class="fa-solid fa-indian-rupee-sign"></i> {itemDataLens.salePrice}</div>
                                                </div>
                                            </div>
                                            {/* <div className="cart-button">
                                                <Link to="/Cart3" onClick={() => AddToCart(item.id)} className="slide-head-bar-td-1">
                                                    <button className="slide-add-cart-btn">Add To Cart</button>
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div >

                    <ThemeModal SingleLensModalBox={SingleLensModalBox} id={item.d2Id}
                        price={itemDataLens.salePrice} setFeatureData={setFeatureData} featureData={featureData}
                        setSelectedFeatureId={setSelectedFeatureId} handleModal={handleModal}
                        lensType={lensType} AddToCart={AddToCart}
                        toggleSliderClose={toggleSliderClose}
                        showModal1Hide={showModal1Hide}
                        AddLensInCart={AddLensInCart}
                        handlelastModal={handlelastModal}
                        eyePower={eyePower}
                    />

                </div>
            </div>
            {loading && (<Loader1 />)}
        </div>
    );
};

export default ProductDetailTheme;
