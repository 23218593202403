import React from 'react'

const MyOffers = () => {
  return (
    <div>
                <label className="title">My Offers</label>

    </div>
  )
}

export default MyOffers