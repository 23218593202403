import React, { useEffect, useState } from 'react'
import ReviewTest from './ReviewTest';

const ReviewAndRating = () => {

    
  const [rating, setRating] = useState(3.5);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating === rating ? 0.5 : selectedRating);
  };

  const [constantProgressValue, setConstantProgressValue] = useState(null);
  const [constantProgressValue1, setConstantProgressValue1] = useState(null);
  const [constantProgressValue2, setConstantProgressValue2] = useState(null);

  const progressBarStyle = {};

  useEffect(() => {
    // Your condition to update the progress value
    // For example, update the progress value after 2 seconds
    const timeoutId = setTimeout(() => {
      setConstantProgressValue(4.1); // Set your updated value here
    }, 2000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount or dependency change
  }, []);

  useEffect(() => {
    // Your condition to update the progress value
    // For example, update the progress value after 2 seconds
    const timeoutId = setTimeout(() => {
      setConstantProgressValue1(4.6); // Set your updated value here
    }, 2000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount or dependency change
  }, []);

  useEffect(() => {
    // Your condition to update the progress value
    // For example, update the progress value after 2 seconds
    const timeoutId = setTimeout(() => {
      setConstantProgressValue2(4.3); // Set your updated value here
    }, 2000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount or dependency change
  }, []);



  return (
    <div className="Specification-accordian-box">
    <div className="page-detail-review-section">
      <div className="page-detail-review-section-start">
        <div className="page-detail-review-section-start-main">
          <h1 style={{textAlign:"center"}}>Give your rating</h1>

          <ReviewTest  />

          {/* <div className="star-rating-1">
            <input
              type="radio"
              id="5-stars"
              name="rating"
              value="5"
            />
            <div for="5-stars" className="star-1">
              &#9733;
            </div>
            <input
              type="radio"
              id="4-stars"
              name="rating"
              value="4"
            />
            <div for="4-stars" className="star-1">
              &#9733;
            </div>
            <input
              type="radio"
              id="3-stars"
              name="rating"
              value="3"
            />
            <div for="3-stars" className="star-1">
              &#9733;
            </div>
            <input
              type="radio"
              id="2-stars"
              name="rating"
              value="2"
            />
            <div for="2-stars" className="star-1">
              &#9733;
            </div>
            <input type="radio" id="1-star" name="rating" value="1" />
            <div for="1-star" className="star-1">
              &#9733;
            </div>
          </div> */}
          <p style={{textAlign:"center"}} className="pdp-click-to-rate-button">Click to rate</p>
          <p>Review title</p>
     

<input className="review-submit-input" type="text"  placeholder="Write your review here" />

          <p>Would you recommend this product to a friend ?</p>

          <div className="pdp-radio-box">
            <div>
              <input type="radio" name="" id="" /> Yes
            </div>
            <div>
              <input type="radio" name="" id="" /> No
            </div>
          </div>

          <p>Your review</p>
          <textarea
            className="rating-input-box"
            placeholder="Write your review title"
          />

          <button className="review-submit-button">
            Send your review
          </button>
        </div>
      </div>
      <div className="page-detail-review-section-left">
        <div className="page-detail-review-section-left-main">
          {/* <div className="review-back-button-section"> </div> */}
          <h1>Review and ratings</h1>
          <div className="star-box-review">
            <div className="star-rating">
              {[...Array(5)].map((_, index) => (
                <span
                  key={index}
                  className={index < rating ? "star filled" : "star"}
                  onClick={() => handleStarClick(index + 1)}
                >
                  &#9733;
                </span>
              ))}
              <p
                style={{
                  color: "gray",
                  fontSize: "13px",
                  lineHeight: "10px",
                  marginBottom: "15px",
                }}
              >
                based on 453 ratings
              </p>
              <p style={{fontWeight:"600"}}>Rating: {rating}</p>
            </div>
            <div className="rating-options-3-pdp">
              <div className="rating-options-3-pdp-3">
                <div>Reliablity</div>
                <div>{constantProgressValue}</div>
              </div>
              <div className="rating-options-3-pdp-2">
                <progress
                  value={constantProgressValue}
                  max="5"
                ></progress>
              </div>
            </div>

            <div className="rating-options-3-pdp">
              <div className="rating-options-3-pdp-3">
                <div>Positive Solutions</div>
                <div>{constantProgressValue1}</div>
              </div>
              <div className="rating-options-3-pdp-2">
                <progress
                  value={constantProgressValue1}
                  max="5"
                ></progress>
              </div>
            </div>

            <div className="rating-options-3-pdp">
              <div className="rating-options-3-pdp-3">
                <div>Payout Rating</div>
                <div>{constantProgressValue2}</div>
              </div>
              <div className="rating-options-3-pdp-2">
                <progress
                  style={progressBarStyle}
                  value={constantProgressValue2}
                  max="5"
                ></progress>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-detail-review-section-right">
        <div className="page-detail-review-section-right-main">
          {/* <p className="page-detail-review-heading1">
            Reviews <span>153</span>
          </p> */}
          <div className="rating-options-3-pdp-1">
            <div>
              <p className="page-detail-paragraph-review1">
                Julia, Berlin
              </p>
              <div className="star-rating">
                {[...Array(5)].map((_, index) => (
                  <span
                    key={index}
                    className={
                      index < rating ? "star filled" : "star"
                    }
                    onClick={() => handleStarClick(index + 1)}
                  >
                    &#9733;
                  </span>
                ))}
              </div>
            </div>
            <p className="page-detail-paragraph-review1">Jan 11</p>
          </div>

          <h3 className="page-detail-review-heading">
            Moved here from another Lorem ipsum dolor sit amet
            consectetur adipisicing elit
          </h3>
          <p className="page-detail-paragraph-review">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Modi commodi alias esse impedit dolores dolorum quae ab
            eius corrupti illo sed eos ea nostrum, dolore ut vitae
            optio obcaecati velit beatae eaque sequi recusandae.
            Accusamus alias nulla obcaecati ea, ipsa sunt consequuntur
            enim, asperiores vero praesentium itaque natus deserunt
            quae!
          </p>
        </div>
        <div className="page-detail-review-section-right-main">
          {/* <p className="page-detail-review-heading1">
            Reviews <span>153</span>
          </p> */}
          <div className="rating-options-3-pdp-1">
            <div>
              <p className="page-detail-paragraph-review1">
                Julia, Berlin
              </p>
              <div className="star-rating">
                {[...Array(5)].map((_, index) => (
                  <span
                    key={index}
                    className={
                      index < rating ? "star filled" : "star"
                    }
                    onClick={() => handleStarClick(index + 1)}
                  >
                    &#9733;
                  </span>
                ))}
              </div>
            </div>
            <p className="page-detail-paragraph-review1">Jan 11</p>
          </div>
          <h3 className="page-detail-review-heading">
            Moved here from another Lorem ipsum dolor sit amet
            consectetur adipisicing elit
          </h3>
          <p className="page-detail-paragraph-review">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Modi commodi alias esse impedit dolores dolorum quae ab
            eius corrupti illo sed eos ea nostrum, dolore ut vitae
            optio obcaecati velit beatae eaque sequi recusandae.
            Accusamus alias nulla obcaecati ea, ipsa sunt consequuntur
            enim, asperiores vero praesentium itaque natus deserunt
            quae!
          </p>
        </div>
        <div className="page-detail-review-section-right-main">
          {/* <p className="page-detail-review-heading1">
            Reviews <span>153</span>
          </p> */}
          <div className="rating-options-3-pdp-1">
            <div>
              <p className="page-detail-paragraph-review1">
                Julia, Berlin
              </p>
              <div className="star-rating">
                {[...Array(5)].map((_, index) => (
                  <span
                    key={index}
                    className={
                      index < rating ? "star filled" : "star"
                    }
                    onClick={() => handleStarClick(index + 1)}
                  >
                    &#9733;
                  </span>
                ))}
              </div>
            </div>
            <p className="page-detail-paragraph-review1">Jan 11</p>
          </div>
          <h3 className="page-detail-review-heading">
            Moved here from another Lorem ipsum dolor sit amet
            consectetur adipisicing elit
          </h3>
          <p className="page-detail-paragraph-review">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Modi commodi alias esse impedit dolores dolorum quae ab
            eius corrupti illo sed eos ea nostrum, dolore ut vitae
            optio obcaecati velit beatae eaque sequi recusandae.
            Accusamus alias nulla obcaecati ea, ipsa sunt consequuntur
            enim, asperiores vero praesentium itaque natus deserunt
            quae!
          </p>
        </div>
        <div className="page-detail-review-section-right-main">
          {/* <p className="page-detail-review-heading1">
            Reviews <span>153</span>
          </p> */}
          <div className="rating-options-3-pdp-1">
            <div>
              <p className="page-detail-paragraph-review1">
                Julia, Berlin
              </p>
              <div className="star-rating">
                {[...Array(5)].map((_, index) => (
                  <span
                    key={index}
                    className={
                      index < rating ? "star filled" : "star"
                    }
                    onClick={() => handleStarClick(index + 1)}
                  >
                    &#9733;
                  </span>
                ))}
              </div>
            </div>
            <p className="page-detail-paragraph-review1">Jan 11</p>
          </div>
          <h3 className="page-detail-review-heading">
            Moved here from another Lorem ipsum dolor sit amet
            consectetur adipisicing elit
          </h3>
          <p className="page-detail-paragraph-review">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Modi commodi alias esse impedit dolores dolorum quae ab
            eius corrupti illo sed eos ea nostrum, dolore ut vitae
            optio obcaecati velit beatae eaque sequi recusandae.
            Accusamus alias nulla obcaecati ea, ipsa sunt consequuntur
            enim, asperiores vero praesentium itaque natus deserunt
            quae!
          </p>
        </div>

        <div className="page-detail-review-section-right-main">
          {/* <p className="page-detail-review-heading1">
            Reviews <span>153</span>
          </p> */}
          <div className="rating-options-3-pdp-1">
            <div>
              <p className="page-detail-paragraph-review1">
                Julia, Berlin
              </p>
              <div className="star-rating">
                {[...Array(5)].map((_, index) => (
                  <span
                    key={index}
                    className={
                      index < rating ? "star filled" : "star"
                    }
                    onClick={() => handleStarClick(index + 1)}
                  >
                    &#9733;
                  </span>
                ))}
              </div>
            </div>
            <p className="page-detail-paragraph-review1">Jan 11</p>
          </div>
          <h3 className="page-detail-review-heading">
            Moved here from another Lorem ipsum dolor sit amet
            consectetur adipisicing elit
          </h3>
          <p className="page-detail-paragraph-review">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Modi commodi alias esse impedit dolores dolorum quae ab
            eius corrupti illo sed eos ea nostrum, dolore ut vitae
            optio obcaecati velit beatae eaque sequi recusandae.
            Accusamus alias nulla obcaecati ea, ipsa sunt consequuntur
            enim, asperiores vero praesentium itaque natus deserunt
            quae!
          </p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ReviewAndRating