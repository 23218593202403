import React, { useContext } from "react";
import { Link } from "react-router-dom";
import menu from "../../Assets/Icons/app.png";
import order from "../../Assets/Icons/order.png";
import Cart from "../../Assets/Icons/shopping-bag.png";
import MyContext from "../../MyContext";


const MobileBottomMentBtn = () => {
  const { totalQuantity } = useContext(MyContext)
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="button-container-bottom">
      <Link to="/" className="button-bottom-nav">
        <i class="fa-solid fa-house" style={{ fontSize: "19px" }}></i>
        <p className="mobile-lowerbar-txt">
          Home
        </p>
      </Link>
      <Link to="/productsearch" className="button-bottom-nav">
        <img src={menu} alt="" style={{ width: "20px" }} />
        <p className="mobile-lowerbar-txt">
          Explore
        </p>
      </Link>

      <Link to="/cart3" className="button-bottom-nav mbl-top-rel">
        {totalQuantity > 0 && (
          <span className="mbl-cart-pg">{totalQuantity} </span>
        )}
        <img src={Cart} alt="" style={{ width: "25px" }} />
        <p style={{ margin: "0" }} className="mobile-lowerbar-txt">
          Cart
        </p>
      </Link>
      <Link to="/productsearch" className="button-bottom-nav">
        <img src={order} alt="" style={{ width: "20px" }} />
        <p className="mobile-lowerbar-txt">
          Orders
        </p>
      </Link>

      <div onClick={handleGoBack} className="button-bottom-nav">
        <i className="mobile-back-btn fa-solid fa-arrow-left-long"></i>
        <p className="mobile-lowerbar-txt">
          Back
        </p>
      </div>
    </div>
  );
};

export default MobileBottomMentBtn;
