import React from 'react'
import OrderBox from './OrderBox'

const MyOrder = () => {
  return (
    <div className='admin-myorder'>
          <label className="title" style={{marginBottom:"0"}}>My Order</label>
          <div className='favourite-box-container'>
            {/* <div className='myorder-box'></div>
            <div className='myorder-box'></div>
            <div className='myorder-box'></div>
            <div className='myorder-box'></div> */}
            
            <OrderBox/>

            <OrderBox/>

            <OrderBox/>

          </div>

    </div>
  )
}

export default MyOrder