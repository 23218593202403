import React, { useState, useEffect, useContext } from "react";
import dlt from "../../Assets/Icons/trash.png";
import Order from "../../../API/API_SERVISES/Order";
import AlertBox from "../AlertBox/AlertBox";
import { Link } from "react-router-dom";
import MyContext from "../../MyContext";



const MyFavourite = (props) => {

  const { imageBaseUrl, wishList, setWishList, fetchDataWish } = useContext(MyContext);

  // const [wishList, setWishList] = useState([])
  const [alert, setAlert] = useState(false);
  const alertbox = (id) => {
    setAlert(!alert)
  }
  const {
    item
  } = props;


  const [getdeleteId, setDeleteId] = useState(null);

  // const DeleteAccount = (id) => {
  //   setDeleteId(id);
  // };

  const DeleteAccountConfirm = async (id) => {

    try {
      const val = await Order.deleteDeleteFavouriteItemList(id);
      if (Boolean(val) === true) {
        setDeleteId(null);
        fetchDataWish();
        alert("Delete data successfully", "Ok");
      } else {
        alert("Error Deleting Data");
      }

    } catch (error) {
      console.error("Error deleting account:", error);
    }
    // fetchData();
  };


  return (
    <div>
      <label className="title">My WishList({wishList?.length || 0})</label>

      <div className="favourite-box-container">
        <div className="HeightOverflow" >
          {wishList.map(product => (
            <Link
              to={`/ProductDetailTheme/${product.d2ItemId}`} 
              className="favourite-box-admin" key={product.id}>
              <div className="review-box-image2">
                <img style={{ width: "100px" }} className="favourite-img"
                  src={`${imageBaseUrl}${product.itemImgId}`}
                  alt=""
                />
              </div>
              <div className="review-box-detail2">
                <div className="review-box-detail-line-admin-1">
                  <div className="review-box-name2">
                    {product.itemName}
                  </div>
                  <div onClick={() => DeleteAccountConfirm(product.id)} className="review-box-section">
                    {/* <i className="fa-solid fa-trash"></i> */}
                    <img className="fa-trash" src={dlt} alt="" />
                  </div>
                </div>
                <div className="review-box-detail-line-2">
                  <div className="lens-txt">
                    Lens :{" "}
                    <div className="review-product-name2">
                      {/* {product.shortDescription} */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "60px",
                    }}
                  >
                    {" "}
                    <p className="review-price">
                      <i className="fa-solid fa-indian-rupee-sign"></i> {product.itemPrice}
                    </p>
                    {/* <p className="review-price-2">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 2500
                  </p> */}
                  </div>
                </div>

              </div>
            </Link>
          ))}
        </div>

      </div>
      {alert && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={alertbox}
          onYesClick={() => DeleteAccountConfirm()}
          alertbox={alertbox}
          alertname="Are You sure you want to delete this"
        //  alertname="Are You Sure U Want to logout" alertbox={alertbox} close={logout}
        />
      )}
    </div>
  );
};

export default MyFavourite;
