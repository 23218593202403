import React, { useContext, useEffect, useState, useMemo } from "react";
import coupon from "../../Assets/Icons/discount.png"
import Modal from "../../Component/Modal/Modal";
import AlertBox from "../../Component/AlertBox/AlertBox";
import AddCouponModal from "../../Component/Modal/AddCouponModal";
import Order from "../../../API/API_SERVISES/Order";
import dlt from "../../Assets/Icons/trash.png"
import MyContext from "../../MyContext";
import axios from 'axios';
import swal from 'sweetalert';
import AlertSuccess from "../../Component/AlertBox/AlertSuccess";

const CartPage2 = () => {
  const [modal, setModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const [addcoupon, setAddCoupon] = useState(false);
  const [sweetAlert, setSweetAlert] = useState(false);

  const alertbox = () => {
    setAlert(!alert)
  }

  const OpenAddCoupon = () => {
    setAddCoupon(!addcoupon)
  }

  const { setTotalQuantity, totalQuantity, currentUser, fetchDataCart } = useContext(MyContext)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const signin = () => {
    setModal(!modal)
  }

  const [InputValues, setInputValues] = useState([])
  const [EcomCartId, setDeleteCart] = useState(null)

  const [product, setProduct] = useState({
    id: InputValues.id,
    quantity: InputValues.quantity,
  });

  // const fetchDataCart = async () => {


  //   try {
  //     const val = await Order.getGetAllEcomCartDetails();
  //     console.log(val);
  //     setInputValues(val);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }

  // };
  // useEffect(() => {
  //   if (currentUser) {
  //     fetchDataCart();
  //   }
  // }, [currentUser]);

  // **************Delete******************

  const DeleteStation = (id) => {
    console.log(id);
    DeleteDeleteEcomCartDetail(id);

  }
  const DeleteDeleteEcomCartDetail = async (id) => {
    try {
      const val = await Order.deleteDeleteEcomCartDetail(id);
      setDeleteCart(val)
      fetchDataCart();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
    fetchDataCart();
  };

  const [quantity1, setQuantity] = useState();
  const [loading, setLoading] = useState(false);




  const handleCartIncremrnt = async (quantity, id) => {
    debugger
    setLoading(true); // debugger; console.log(InputValues)

    try {
      // Assuming handleChange is defined elsewhere in your code
      const updatedValues = [
        {
          operationType: 0,
          path: "Quantity",
          op: "Add",
          from: "string",
          value: quantity + 1 // Assuming you want to increment the quantity 
        }
      ];

      const isSuccess = await Order.UpdateEcomCartPartially(updatedValues, id);
      if (isSuccess) {
        fetchDataCart();

      } else {
        throw new Error("Error while updating cart");
      }
    } catch (error) {
      console.error("Error while updating cart:", error);
      fetchDataCart();

    } finally {
      setLoading(false);
    }
    fetchDataCart();
  };

  const handleCartDecrement = async (quantity, id) => {
    debugger
    setLoading(true);
    try {
      // Assuming handleChange is defined elsewhere in your code
      const updatedValues = [
        {
          operationType: 0,
          path: "Quantity",
          op: "Add",
          from: "string",
          value: quantity - 1 // Assuming you want to increment the quantity
        },
      ];

      const isSuccess = await Order.UpdateEcomCartPartially(updatedValues, id);
      if (isSuccess) {
        // alert("Cart Updated successfully");
        fetchDataCart();
      } else {
        throw new Error("Error while updating cart");
      }
    } catch (error) {
      console.error("Error while updating cart:", error);
      // alert("Error while updating cart");
      fetchDataCart();
    } finally {
      setLoading(false);
    }
    fetchDataCart();
  };

  const [totalPrice, setTotalPrice] = useState(0);
  const [taxtotalPrice, setTaxTotalPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [productPrice, setProductPrice] = useState(0);


  const calculateTotalPrice = () => {
    let totalPrice = 0;
    let taxPrice = 0;
    let finalPrice = 0;
    let productPrice = 0;
    let productQuantity = 0;
    console.log(InputValues)
    InputValues.forEach(product => {
      totalPrice += product.itemPrice * product.quantity
      productPrice += product.itemPrice
      productQuantity += product.quantity
      // taxPrice += product.itemPrice * 18 / 100;
      // finalPrice = finalPrice + (product.itemPrice + (product.itemPrice * 18 / 100))
    });
    console.log(finalPrice);
    setTotalPrice(totalPrice);
    setTaxTotalPrice(taxPrice);
    setFinalPrice(finalPrice);
    setProductPrice(productPrice)
    setTotalQuantity(productQuantity)

  };
  useEffect(() => {

    calculateTotalPrice();
  }, [InputValues]);
  console.log(InputValues)

  return (
    <div className="cartpage">
      <div className="cartpage-left">
        <div className="cartpage-heading">Review Order</div>
        <div className="cartpage-reviewbox">
          <div className="cart-container-scroll" >
            {InputValues.map((product) => (
              <div className="review-box review-box-2" key={product.id}>
                <div className="review-box-image">
                  <img src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg" alt="" />
                </div>
                <div className="review-box-detail">
                  <p onClick={() => DeleteStation(product.id)} className="cartpage-2-product-remove">
                    <i className="fa-regular fa-trash-can"></i>
                  </p>
                  <div className="review-box-options" style={{ paddingTop: "5px" }}>
                    <div className="heading-panel-1">{product.itemName}</div>
                    <div className="prize-55">
                      {/* {product.salePrice} <i className="fa-solid fa-indian-rupee"></i> */}
                    </div>
                  </div>
                  <div className="review-box-options">
                    <div>LENS - {product.lensType}</div>
                    <div className="prize-55">
                      <i className="fa-solid fa-indian-rupee"></i> {product.itemPrice}
                    </div>
                  </div>
                  <div className="review-box-options">
                    <div>Final prize</div>
                    <div className="prize-55">
                      <i className="fa-solid fa-indian-rupee"></i>  {product.itemPrice * product.quantity}
                    </div>
                  </div>
                  <p className="review-box-detail-line-4" style={{ marginBottom: "3px", paddingLeft: "5px" }}>
                    <div className="quantity">
                      <a onClick={() => handleCartDecrement(product.quantity, product.id)} className="quantity__minus">
                        <span>-</span>
                      </a>
                      <input name="quantity" type="text" className="quantity__input" value={product.quantity} />
                      <a onClick={() => handleCartIncremrnt(product.quantity, product.id)} className="quantity__plus">
                        <span>+</span>
                      </a>
                    </div>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="cartpage-right">
        <div style={{ marginLeft: "5px" }} className="cartpage-heading">Price Summary</div>
        <div style={{ cursor: "pointer" }} onClick={OpenAddCoupon} className="cartpage-pricebox">
          <div className="pricebox-1">
            <div style={{ display: "flex" }}>
              <img src={coupon} alt="" />
              <div className="pricebox-coupon">Add Coupon Code</div>
            </div>
            <i className="fa-solid fa-caret-right"></i>
          </div>
        </div>
        <div className="cartpage-pricebox">
          <div className="pricebox-type-1">
            <div className="pricebox-heading">
              MRP
            </div>
            <div className="pricebox-heading-2">
              <i className="fa-solid fa-indian-rupee-sign"></i> {totalPrice}
            </div>
          </div>
          <div style={{ color: "rgb(17, 186, 216)" }} className="pricebox-type-1">
            <div className="pricebox-heading">
              Item Discount
            </div>
            <div className="pricebox-heading-2">
              <i className="fa-solid fa-indian-rupee-sign"></i>  0
            </div>
          </div>
          <div className="pricebox-type-1">
            <div className="pricebox-heading">
              Net Price
            </div>
            <div className="pricebox-heading-2">
              <i className="fa-solid fa-indian-rupee-sign"></i> {totalPrice}
            </div>
          </div>
          <div style={{ border: '0' }} className="pricebox-type-1">
            <div className="pricebox-heading">
              You Pay (incl. taxes)
            </div>
            <div className="pricebox-heading-2">
              <i className="fa-solid fa-indian-rupee-sign"></i> {totalPrice}
            </div>
          </div>
          <button className="pricebox-type-btn" onClick={signin}>CheckOut</button>

        </div>
        {/* <div className="cartpage-pricebox"></div>
          <div className="cartpage-pricebox"></div>
          <div className="cartpage-pricebox"></div> */}
      </div>
      <div className="mobile-cart-price-box">
        <p>total amount to be pay
        </p>
        <p>1600 <i className="fa-solid fa-indian-rupee-sign"></i> </p>
      </div>
      {modal && (
        <Modal handleClick={signin} />
      )}
      {alert && (<AlertBox alertname="Remove Item From Cart" close={() => DeleteDeleteEcomCartDetail()} />)}
      {addcoupon && (<AddCouponModal handleClick={OpenAddCoupon} />)}
      {/* <AlertSuccess /> */}
    </div>
  );
};

export default CartPage2;
