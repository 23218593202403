import React, { useState } from "react";
import dlt from "../../Assets/Icons/trash.png";
// import MyContext from "./MyContext";

const TrackBox_Lc = () => {
    const [isClicked, setIsClicked] = useState(false);

    const toggleClass = () => {
        setIsClicked(!isClicked);
    };

    //   const constextProduct = useContext(MyContext);

    //   console.log(constextProduct);

    //   const filteredProducts = constextProduct.filter(
    //     (item) => item.favourite === true
    //   );

    // 

    return (
        <div>
            <div className="my-order-table Track-Height-Box">
                <div className="order-box-top-top">
                    <div className="order-box-top">
                        <div className="order-detail-list Track-Order-width">
                            <div className="Flex-Track-Box_Lc">
                                <p className="ordertxt-1">Order ID : 1220603837 </p>
                                <p className="ordertxt-1">Order Date : 23 May </p>
                            </div>
                        </div>

                        <div className="order-detail-list Track-Height-Lc">
                            <p className="ordertxt-1">Total Price : 455</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-order-table Order-image-height_Lc">
                <div className="order-box-top-top">
                    <div className="order-box-top">
                        <div className="order-detail-list Delivered-height-ord">
                            <div className="Flex-Track-Box_Lc">
                                <i class="fa-solid fa-truck"></i>
                                <p className="Del-ord-font">Delivered</p>
                            </div>
                        </div>
                    </div>
                    <div className="Order-line_Lc"></div>
                    <div className="order-box-top">
                        <div className="review-box-image">
                            <img className="Fav-img-Lc "
                                src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg"
                                alt=""
                            />
                        </div>
                        <div className="review-box-detail review-height_Lc">
                            <div className="review-box-detail-line-1">
                                <div className="review-box-name">
                                    Peyush Bansal Shark Tank Monza Red Full Rim Hustler
                                    Eyeglasses....
                                </div>
                            </div>
                            <div className="review-box-detail-line-2">
                                <div className="lens-txt">
                                    Lens :{" "}
                                    <div className="review-product-name">
                                        Black Full Frame Eyeglasses
                                    </div>
                                </div>
                                <div className="lens-txt">
                                    <div className="review-product-name">
                                        Return window closed on 8 Jul 2024
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "60px",
                                }}
                            >
                                {" "}
                                <p className="review-price">
                                    <i className="fa-solid fa-indian-rupee-sign"></i> 1500
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Order-Details-Track">
                Order Details
            </div>

            <div className="Delivery-Details-lc-ord">
            <div className="order-box-top-top">
            <div className="order-box-top">
                <div className="order-detail-list Order-width-details">
                    <div>
                    <p className="ordertxt-1">Shubham Bala</p>   
                    </div>
                    <p className="ordertxt-1">Shubham Bala</p>
                    <p className="ordertxt-1">MMI Hospital,
                        Raipur,
                        RAIPUR - 492001,
                        CHHATISGARH, India</p>
                    <p className="ordertxt-2">
                        vklcreation@gmail.com
                    </p>
                    <p className="ordertxt-2">+91-7389427428</p>
                </div>

                <div
                    style={{ justifyContent: "end" }}
                    className="order-detail-list Order-width-details"
                >
                    <div className="Flex-Price-Breakup">
                    <p className="ordertxt-1">Sub Total</p>
                    <p className="ordertxt-1">₹ 800</p>
                    </div>

                    <div className="Flex-Price-Breakup">
                    <p className="ordertxt-1">Voucher Discount</p>
                    <p className="ordertxt-1">- ₹ 700</p>
                    </div>
                    <div className="Order-line_Lc"></div>

                    <div className="Flex-Price-Breakup">
                    <p className="ordertxt-1">Net Amount</p>    
                    <p className="ordertxt-1">₹ 100</p>
                    </div>

                    <div className="Flex-Price-Breakup">
                    <p className="ordertxt-1">Convenience Fees</p>    
                    <p className="ordertxt-1">+ ₹ 50</p>
                    </div>
                    <div className="Order-line_Lc"></div>
                    <div className="Flex-Price-Breakup">
                    <p className="ordertxt-1">Total</p>    
                    <p className="ordertxt-1">₹ 150</p>
                    </div>

                  
                    
                    <p
                        style={{ marginBottom: "5px", color: "#11bad8" }}
                        className="ordertxt-3"
                    >
                        See Details
                    </p>
                </div>
            </div>
            </div>
            </div>



            <div
                // onClick={toggleClass}
                className={` ${isClicked ? "custom-div" : ""} my-order-table `}
            >
                <div className="order-box-top-top">
                    <div className="order-box-top">
                        <div className="order-detail-list Track-Order-width">
                            <div className="Flex-Track-Box_Lc">
                                <p className="ordertxt-1">Order ID : 1220603837 </p>
                                <p className="ordertxt-1">Order Date : 23 May </p>
                            </div>
                        </div>

                        <div className="order-detail-list">
                            <p className="ordertxt-1">Total Price : 455</p>
                            {/* <p
                                style={{ marginBottom: "5px", color: "#11bad8" }}
                                className="ordertxt-3"
                            >
                                See Details
                            </p> */}
                        </div>
                    </div>
                    <div>

                        <div
                            style={{
                                borderRadius: "15px",
                                height: "350px",
                                boxShadow: "none",
                                width: "100%",
                                padding: "0",
                                marginBottom: "0"
                            }}
                            className="card-cart cart "
                        >
                            <label className="title">My Order </label>

                            <div className="favourite-box-container">
                                <div className="favourite-box">
                                    <div className="review-box-image">
                                        <img className="favourite-img "
                                            src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="review-box-detail">
                                        <div className="review-box-detail-line-1">
                                            <div className="review-box-name">
                                                Peyush Bansal Shark Tank Monza Red Full Rim Hustler
                                                Eyeglasses....
                                            </div>
                                            <div className="review-box-section">
                                                {/* <i className="fa-solid fa-trash"></i> */}
                                                <img className="fa-trash" src={dlt} alt="" />
                                            </div>
                                        </div>
                                        <div className="review-box-detail-line-2">
                                            <div className="lens-txt">
                                                Lens :{" "}
                                                <div className="review-product-name">
                                                    Black Full Frame Eyeglasses
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "60px",
                                                }}
                                            >
                                                {" "}
                                                <p className="review-price">
                                                    <i className="fa-solid fa-indian-rupee-sign"></i> 1500
                                                </p>
                                                {/* <p className="review-price-2">
                                                <i className="fa-solid fa-indian-rupee-sign"></i> 2500
                                                </p> */}
                                            </div>
                                        </div>

                                    </div>
                                </div>



                                <div className="favourite-box">
                                    <div className="review-box-image">
                                        <img className="favourite-img"
                                            src="https://happychashma.com/shop/images/product/Green_Year_9017_Metal_Half_Rim_52_Rectangle_Black_502_1.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="review-box-detail">
                                        <div className="review-box-detail-line-1">
                                            <div className="review-box-name">
                                                Peyush Bansal Shark Tank Monza Red Full Rim Hustler
                                                Eyeglasses....
                                            </div>
                                            <div className="review-box-section">
                                                <img className="fa-trash" src={dlt} alt="" />
                                            </div>
                                        </div>
                                        <div className="review-box-detail-line-2">
                                            <div className="lens-txt">
                                                Lens :{" "}
                                                <div className="review-product-name">
                                                    Black Full Frame Eyeglasses
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "60px",
                                                }}
                                            >
                                                {" "}
                                                <p className="review-price">
                                                    <i className="fa-solid fa-indian-rupee-sign"></i> 1500
                                                </p>
                                                {/* <p className="review-price-2">
                                                <i className="fa-solid fa-indian-rupee-sign"></i> 2500
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default TrackBox_Lc;
