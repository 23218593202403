import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from 'react-router-dom';
import ShopBanner from "../../../API/API_SERVISES/Shopbanner";
import { useContext } from 'react';
import MyContext from '../../MyContext';

const BannerSlider1_Lc = () => {

    const { imageBaseUrl, currentUser, shopBanner } = useContext(MyContext);
    const navigate = useNavigate();

    const [images, setImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const fetchData = () => {
        if (currentUser) {
            try {
                // const data = await ShopBanner.GetShopBanners();
                const data = shopBanner;
                const filteredData = data.filter(item => item.imageBannerName.includes("Banner")).slice().sort((a, b) => a.bannerPriority - b.bannerPriority);
                console.log("filteredData", filteredData);
                setImages(filteredData);
            } catch (error) {
                console.error('Error fetching shop banners:', error);
            }
        }
    }
    useEffect(() => {
        fetchData();
    }, [currentUser, shopBanner])

    // const images = [
    //     "https://static5.lenskart.com/media/uploads/Web_Banner_eyesun-1.jpg",
    //     "https://static1.lenskart.com/media/desktop/img/oct9/hd-acetate/Option%201%20static.jpg",
    //     "https://static5.lenskart.com/media/uploads/web02apr.jpg",
    //     "https://static5.lenskart.com/media/uploads/Desktop-Banner0905home_page.gif",
    //     "https://static5.lenskart.com/media/uploads/home_banner_jj_gentleman.gif",
    //     "https://static1.lenskart.com/media/desktop/img/oct9/holiday-edit/Desktop.jpg",
    //     "https://static5.lenskart.com/media/uploads/final-lpl-webbanner-1.gif",
    //     "https://static1.lenskart.com/media/desktop/img/Feb24/bloom/InBloom-WebBanner.jpg",
    //     "https://static5.lenskart.com/media/uploads/Web-bannerTentpole.jpg",
    //     "https://static1.lenskart.com/media/desktop/img/2024/feb/IN/tentpole/web%20banner.jpg",
    //     // Add more image URLs as needed
    // ];

    //  const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const nextSlide = () => {
        console.log("images", images);
        console.log((currentImageIndex + 1) % (images.length))
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % (images.length));
    };

    const prevSlide = () => {
        setCurrentImageIndex((prevIndex) => {
            if (prevIndex === 0) {
                return (images.length) - 1;
            } else {
                return prevIndex - 1;
            }
        });
    };

    useEffect(() => {
        const slideInterval = setInterval(() => {
            if (images.length > 0) {
                setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
            }
        }, 5000); // Change slide interval as needed (5000ms = 5 seconds)

        return () => clearInterval(slideInterval);
    }, [images]);

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const navigateBtn = (item) => {
        if (item.redirectTo != null && item.redirectTo != undefined && item.redirectTo != 0) {
            navigate(`/productsearch?productId=${item.redirectTo}`);
        }
        else if (item.redirectToURL != null && item.redirectToURL != undefined && item.redirectToURL != "") {
            // window.location.href = item.redirectToURL
            if (item.redirectToURL.startsWith('http://') || item.redirectToURL.startsWith('https://')) {
                window.location.href = item.redirectToURL;
            } else {
                navigate(item.redirectToURL, { replace: true });
            }
        }
    }

    return (
        <div>


            {/* <div className="product-slider-1"> */}
            {/* <Slider className="product-slider-1" {...settings}>
      <div className="productcard2"> 
      <img className="" src="" alt=""/>
      </div>
      <div className="productcard2"> </div>
      <div className="productcard2"> </div>
      <div className="productcard2"> </div>
      <div className="productcard2"> </div>
      <div className="productcard2"> </div>
      <div className="productcard2"> </div>
      <div className="productcard2"> </div>
    </Slider> */}
            {/* </div> */}

            <div style={{ position: "relative" }}>
                <div className="Left-Arrow-Lc" onClick={prevSlide}>
                    <span>❮</span>
                </div>
                <div className="Slider-Content">
                    <div className="Banner-Slider_Lc">
                        {images && images.map((image, index) => (
                            index === currentImageIndex && (
                                <div onClick={() => navigateBtn(image)} key={index}>
                                    <img key={index} className="Banner-Slider_Lc" src={`${imageBaseUrl}${image.bannerImageId}`} alt="" />
                                </div>
                            )
                        ))}
                    </div>
                </div>
                <div className="Right-Arrow-Lc" onClick={nextSlide}>
                    <span>❯</span>
                </div>
            </div>



        </div>
    );
};

export default BannerSlider1_Lc;
