import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductSeacrhImageScroller = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay : true ,
    autoplaySpeed: 3000,

  };

  return (
    <div>
      <Slider className="product-swap-1" {...settings}>
        <div className="productswap1"></div>
        <div className="productswap2"></div>
        <div className="productswap3"></div>
      </Slider>
    </div>
  );
};

export default ProductSeacrhImageScroller;
