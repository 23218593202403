import React, { useState } from "react";
import "./Phoennumber.css";
import Form from "../Form/Form";
import { useNavigate } from "react-router-dom";
import OTPinfo from "../../../../../API/API_SERVISES/OTPinfo";

const Phonenumber = () => {
  const [warning, setWarning] = useState("");
  // const [toggleForform, settoggle] = useState(false);
  const [otp, setotp] = useState('');
  const [isValidInput, setIsValidInput] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const [otpSent, setOtpSent] = useState(false);

  const [message, setMessage] = useState('');

  console.log(otp)


  const [otpData, setotpData] = useState({
    aMobileNo: '',
    aEmail: ''
  });
  const isValidPhoneNumber = /^\d{10}$/;
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


  // const handleSubmit = () => {
  //   let newotpData = { ...otpData };

  //   if (validateEmail(inputValue)) {
  //     newotpData = {
  //       ...newotpData,
  //       aEmail: inputValue,
  //       aMobileNo: ''
  //     };
  //   } else if (!isNaN(inputValue)) {
  //     newotpData = {
  //       ...newotpData,
  //       aMobileNo: inputValue.toString(),
  //       aEmail: ''
  //     };
  //   } else {
  //     console.error('Invalid input');
  //     return;
  //   }
  //   setotpData(newotpData);
  // };

  console.log(otpData)

  const validateEmail = (email) => {
    return isValidEmail.test(email);
  };

  const navigate = useNavigate();
  const handleInputBlur = () => {
    // Reset warning message on blur
    setWarning('');

    if (!isValidPhoneNumber.test(inputValue) && !isValidEmail.test(inputValue)) {
      setWarning('Invalid phone number or email');
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    let newotpData = { ...otpData };

    if (isValidPhoneNumber.test(value) || isValidEmail.test(value)) {
      setIsValidInput(true);
      setWarning('');
    } else {
      setIsValidInput(false);
      setWarning('Invalid phone number or email');
    }

    if (validateEmail(value)) {
      newotpData = {
        ...newotpData,
        aEmail: value,
        aMobileNo: ''
      };
    } else if (!isNaN(value) && value.trim() !== '') {
      newotpData = {
        ...newotpData,
        aMobileNo: value.toString(),
        aEmail: ''
      };
    } else {
      newotpData = {
        ...newotpData,
        aEmail: '',
        aMobileNo: ''
      };
    }

    setotpData(newotpData);
    setInputValue(value); // Update inputValue state if you have one
  };


  const handleButtonClick = () => {
    if (isValidInput) {

      if (!otpSent) {
        sendOTP(otpData);
      } else {
        verifyOTP();
      }
    } else {
      console.log('Navigate to form');
      // Example: navigate('/form');
      navigate('/form')
    }
  };

  const sendOTP = async (otpData) => {
    console.log('Sending OTP');
    setOtpSent(true);
    try {
      const response = await OTPinfo.SendOtp(otpData)
      console.log(response)
      if (response) {
        setMessage('OTP has been sent!');
        setTimeout(() => {
          setMessage('');
        }, 3000);
      }
    } catch (error) {
      throw new Error("Unable To Send OTP", error)
    }
  };

  const verifyOTP = async () => {
    try {
      const response = await OTPinfo.VerifyOtp(otpData, otp)
      if (response) {
        // alert("OTP Verified Successfully")
        setMessage("OTP Verified Successfully")
        navigate("/form")
      }
    } catch (error) {
      throw new Error("Please Enter Correct OTP")
    }
  };

  return (
    <>
      {/* <div className={`main-div-phone-appointment`}>
        <div className={`box-for-number-appointment`}>
          <div>
            <h4 className={`h4-enter-appointment`}>Enter Mobile Number/Email</h4>


            <div className={`input-section-appointment`}>
              <label className={`label-for-phone-appointment`}>
                Enter a valid Mob number/Email to get the OTP
              </label>
              <div className={`div-for-phone-number-appointment`}>

                <input
                  type="number"
                  onChange={(e) => {
                    dowarning(e)

                  }}
                  onBlur={() => setwarning("")}
                  value={inputValue}
                  className={`phone-number-input-appointment`}
                  placeholder="Enter Mobile Number/Email"
                />


              </div>

              <p
                className={`p-for-warning-appointment ${warning == "Valid number" ? "green" : ""
                  }`}
              >
                {warning}
              </p>

              <input
                type="number"
                placeholder="OTP"
                onChange={(e) => setotp(e.target.value)}
                className={`phone-number-input otp-border-appointment`}
              />

              <label className={`label-whats-up-appointment`}>
                <input type="checkbox" className={`chekbox`} />
                <p className={`whatsuptext`}>
                  Send me text updates on WhatsApp
                </p>
              </label>
            </div>

          </div>

          <div className={`button-div-appointment`}>
            {inputValue > 10 ? (
              <button
                className={`btn-proceed-appointment`}
                onClick={() => {

                  console.log("sds");
                }}
              >
                Send otp
              </button>
            ) : (
              <button
                className={`btn-proceed-appointment`}
                onClick={() => {
                  navigate("/form");
                }}
              >
                Proceed
              </button>
            )}
          </div>
        </div>


      </div> */}

      <div className="appointment-app-container">
        <div className="main-div-phone-appointment">
          <div className="box-for-number-appointment">
            <div>
              <h4 className="h4-enter-appointment">Enter Mobile Number/Email</h4>
              <div className="input-section-appointment">
                <label className="label-for-phone-appointment">
                  Enter a valid Mob number/Email to get the OTP
                </label>
                <div className="div-for-phone-number-appointment">
                  <input
                    type="text"
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    value={inputValue}
                    className="phone-number-input-appointment"
                    placeholder="Enter Mobile Number/Email"

                  />
                </div>
                <p className={`p-for-warning-appointment ${warning === "Valid number" ? "green" : ""}`}>
                  {warning && <div className="warning">{warning}</div>}
                </p>
                {message && <div className="message" style={{ color: "green" }}>{message}</div>}
                <input
                  type="text"
                  placeholder="OTP"
                  onChange={(e) => setotp(e.target.value)}
                  className="phone-number-input otp-border-appointment"
                  style={{ display: otpSent ? 'block' : 'none' }}
                />
                <label className="label-whats-up-appointment">
                  <input type="checkbox" className="chekbox" />
                  <p className="whatsuptext">Send me text updates on WhatsApp</p>
                </label>
              </div>
            </div>
            <div className="button-div-appointment">
              {isValidInput ? (
                <button className="btn-proceed-appointment" onClick={handleButtonClick}>
                  {otpSent ? 'Verify OTP' : 'Send OTP'}
                </button>
              ) : (
                <button
                  className="btn-proceed-appointment"
                  onClick={handleButtonClick}
                >
                  Proceed
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Phonenumber;
