import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import coupon from "../../Assets/Icons/discount.png"
import Modal from "../../Component/Modal/Modal";
import AlertBox from "../../Component/AlertBox/AlertBox";
import AddCouponModal from "../../Component/Modal/AddCouponModal";
import Order from "../../../API/API_SERVISES/Order";
import dlt from "../../Assets/Icons/trash.png"
import MyContext from "../../MyContext";
import axios from 'axios';
import swal from 'sweetalert';
import AlertSuccess from "../../Component/AlertBox/AlertSuccess";
import { Link } from "react-router-dom";
import StateInfoApi from "../../../API/API_SERVISES/StateInfo";
import CountryApi from "../../../API/API_SERVISES/Country";
import AddAddress from "../../../API/API_SERVISES/AddAddress";
import { useNavigate } from 'react-router-dom';

const ShippingAddress = (props) => {
    // const [modal, setModal] = useState(false);
    // const [alert, setAlert] = useState(false);
    const [addcoupon, setAddCoupon] = useState(false);
    // const [sweetAlert, setSweetAlert] = useState(false);
    // const alertbox = () => {
    //     setAlert(!alert)
    // }

    const [EditData, setEditData] = useState();
    const [addresses, setAddAddresses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedAddress, setSelectedAddress] = useState(null);

    const navigate = useNavigate();

    // const handleCheckboxChange = (addressIndex) => {
    //     setSelectedAddress(addressIndex);
    // };

    const { DefaultAddressChange,
        setDefaultAddressChange } = useContext(MyContext)

    const handleCheckboxChange = async (addressIndex, id) => {
        var getaddress = await AddAddress.getAddressById(id, myAccountID);
        if (getaddress.isDefault == false) {
            const updatedValues = {
                ...getaddress,
                "isDefault": true,
            };
            await AddAddress.updateAddress(updatedValues, myAccountID);
        }
        setDefaultAddressChange(!DefaultAddressChange)
        setSelectedAddress(id);
        alert("Updated Default Address");
        fetchAddress()
    };

    const OpenAddCoupon = () => {
        setAddCoupon(!addcoupon)
    }
    const { myaccountmodal, setTotalQuantity, modal, setLoginPanel, totalQuantity, InputValues, imageBaseUrl,
        fetchDataWish, currentUser, wishList, setWishList, totalPrice, setTotalPrice, myAccount, setMyAccount, } = useContext(MyContext)

    //****************************************************************** */
    const [isModalOpenAddAddress, setIsModalOpenAddAddress] = useState(false);
    const [showSelectAddress, setShowSelectAddress] = useState(true);

    const handleOpenModalAddAddress = () => {
        setIsModalOpenAddAddress(true);
        setShowSelectAddress(false);
        setEditData("")
    };

    const handleGoBack = () => {
        setIsModalOpenAddAddress(false);
        setShowSelectAddress(true); // Show Select Address section when going back
    };

    //************************************************************ */

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const signin = () => {
        setLoginPanel(!modal)
    }
    const {
        item
    } = props;


    const [activeLink, setActiveLink] = useState("/");
    const handleLinkClick = (link) => {
        setActiveLink(link);
    };



    // const [totalPrice, setTotalPrice] = useState(0);
    // const [taxtotalPrice, setTaxTotalPrice] = useState(0);
    // const [finalPrice, setFinalPrice] = useState(0);
    // const [productPrice, setProductPrice] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsPerPage = 3; // Number of items to show per page
    const itemsPerPage2 = 1; // Number of items to show per page



    //*****************************ADD Address************************************* */
    const [myAccountID, setMyAccountID] = useState();

    useEffect(() => {
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);
        // console.log(loginData.d1Id);
        setMyAccountID(loginData.d1Id);
    }, [myAccountID]);


    const [inputValues, setInputValues] = useState({
        accountId: "",
        name: "",
        mobile: "",
        email: "",
        pincode: "",
        state: "",
        country: "",
        address: "",
        locality: "",
        city: "",
        typeOfAddress: 0,
        isDefault: "",
        isActive: true,
    });

    // useEffect(() => {
    //     console.log(EditData);
    //     const fetchData = async () => {
    //         if (EditData) {
    //             try {
    //                 const data = await EditData; // Assuming props.editData is a Promise
    //                 setInputValues({
    //                     id: EditData.id,
    //                     accountId: 707,
    //                     name: EditData.name,
    //                     mobile: EditData.mobile,
    //                     pincode: EditData.pincode,
    //                     state: EditData.state,
    //                     address: EditData.address,
    //                     locality: EditData.locality,
    //                     city: EditData.city,
    //                     typeOfAddress: EditData.typeOfAddress,
    //                     isDefault: EditData.isDefault,
    //                     isActive: true,
    //                 });
    //             } catch (error) {
    //                 console.error("Error fetching data:", error);
    //             }
    //         } else if (EditData === "" || EditData === undefined) { // Use logical OR here
    //             setInputValues({
    //                 accountId: "",
    //                 name: "",
    //                 mobile: "",
    //                 pincode: "",
    //                 state: "",
    //                 address: "",
    //                 locality: "",
    //                 city: "",
    //                 typeOfAddress: 0,
    //                 isDefault: "",
    //                 isActive: true,
    //             });
    //         }
    //     };


    //     fetchData();
    // }, [EditData]);

    useEffect(() => {
        console.log(EditData);
        const fetchData = () => {
            if (EditData) {
                setInputValues({
                    id: EditData.id,
                    accountId: 707,
                    name: EditData.name,
                    mobile: EditData.mobile,
                    pincode: EditData.pincode,
                    state: EditData.state,
                    address: EditData.address,
                    locality: EditData.locality,
                    city: EditData.city,
                    typeOfAddress: EditData.typeOfAddress,
                    isDefault: EditData.isDefault,
                    isActive: true,
                });
            } else {
                setInputValues({
                    accountId: "",
                    name: "",
                    mobile: "",
                    pincode: "",
                    state: "",
                    address: "",
                    locality: "",
                    city: "",
                    typeOfAddress: 0,
                    isDefault: "",
                    isActive: true,
                });
            }
        };

        fetchData();
    }, [EditData]);



    const [errorMessages, setErrorMessages] = useState({
        name: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };


    const handleInputChange1 = (value) => {
        // debugger
        setInputValues({
            ...inputValues,
            typeOfAddress: Number(value),
        });
    };

    const handleInputChange2 = (e) => {
        const checked = e.target.checked;
        setInputValues({
            ...inputValues,
            isDefault: checked,
        });
    };


    // const fetchAddress1 = () => {
    //     const userLogin = localStorage.getItem("accountLogin");
    //     const userArray = userLogin ? JSON.parse(userLogin) : null;

    //     if (userArray) {
    //         navigate('/PaymentMethods');
    //     }
    //     // else {
    //     //   setMyAccount(!myAccount);
    //     //   document.body.style.overflow = myAccount ? "auto" : "hidden";
    //     //   signupshow();
    //     // }
    // };


    const [stateInfo, setStateInfo] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await StateInfoApi.GetAllState();
                setStateInfo(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // const [country, setCountry] = useState();
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const data = await CountryApi.GetAllState();
    //             setCountry(data);
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };
    //     fetchData();
    // }, []);


    const validateInput = (fieldName) => {
        // console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (
            !fieldValue ||
            (typeof fieldValue === "string" && fieldValue.trim() === "")
        ) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }
    };
    const fieldNames = [];

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase());
    };


    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);

    const focusInput = (inputRef) => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    };

    const SaveBtn = async () => {
        // console.log(props.data);



        let allValid = true;

        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);

            if (!isValid) {
                allValid = false;
            }
        });

        if (showSelectAddress === true) {
            navigate('/PaymentMethods');
            return;
        }

        if (allValid) {
            if (EditData) {
                if (inputValues.name === "") {
                    alert("Name field is Required")
                    focusInput(inputRef1)
                }
                else if (inputValues.address === "") {
                    alert("Address field is Required ")
                    focusInput(inputRef2)
                }
                else if (inputValues.state === "" && inputValues.state === 0) {
                    alert("Please select the state ")
                    focusInput(inputRef3)
                }
                else if (inputValues.city === "") {
                    alert("City/District feild is required ")
                    focusInput(inputRef4)
                }
                else {
                    const address = await AddAddress.updateAddress(
                        inputValues,
                        myAccountID
                    );
                    if (address) {
                        alert("Address Updated successfully");
                        // setEditData(address);
                        navigate('/PaymentMethods');

                    } else {
                        alert("error while updating");
                    }
                }
            } else {
                try {
                    if (inputValues.name === "") {
                        alert("Name field is Required")
                        focusInput(inputRef1)
                    }
                    else if (inputValues.address === "") {
                        alert("Address field is Required ")
                        focusInput(inputRef2)
                    }
                    else if (inputValues.state === "" || inputValues.state === 0) {
                        alert("Please select the state ")
                        focusInput(inputRef3)
                    }
                    else if (inputValues.city === "") {
                        alert("City/District feild is required ")
                        focusInput(inputRef4)
                    }
                    else {
                        const address = await AddAddress.insertAddress(
                            inputValues,
                            myAccountID
                        );

                        if (address) {
                            alert("Address added successfully");
                            // props.modalclose();
                            // props.fetchAddress();
                            navigate('/PaymentMethods');
                        } else {
                            // Handle case when address is not added successfully
                        }
                    }
                } catch (error) {
                    console.error("Error inserting data:", error);
                    alert("Error inserting data");
                }
            }
        } else {
            alert("Validation failed");
        }
    };

    //******************************************************* */

    const fetchAddress = async () => {

        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);
        console.log(loginData.d1Id);
        setMyAccountID(loginData.d1Id)
        var addresses = await AddAddress.getAddress(loginData.d1Id);
        if (addresses) {
            setAddAddresses(addresses);
            //   setAddressModal(false);
            // setIsModalOpenAddAddress(true);
            setLoading(false);
        } else {
            alert("Error Occoured");
        }
    };

    useEffect(() => {
        const fetchAddress = async () => {
            var loginDataString = localStorage.getItem("accountLogin");
            var loginData = JSON.parse(loginDataString);
            console.log(loginData.d1Id);
            setMyAccountID(loginData.d1Id)
            var addresses = await AddAddress.getAddress(loginData.d1Id);
            if (addresses) {
                setAddAddresses(addresses);
                // setAddressModal(false);
                // setIsModalOpenAddAddress(true);
                setLoading(false);
            } else {
                alert("Error Occoured");
            }
        };

        fetchAddress();
    }, []);

    console.log(addresses);

    //************************Edit Address********************* */

    const editaddress = async (id) => {
        var getaddress = await AddAddress.getAddressById(id, myAccountID);
        // console.log(data);
        console.log(getaddress);
        setEditData(getaddress);
        setIsModalOpenAddAddress(true);
        setShowSelectAddress(false);
    };

    //***************************Delete Address******************************* */

    const deleteaddress = async (id) => {
        if (window.confirm("Are you sure you want to delete this address?")) {
            setLoading(true);
            try {
                var deletedata = await AddAddress.deleteAddress(id, myAccountID);
                if (deletedata) {
                    alert("Address Deleted");
                    fetchAddress();
                } else {
                    alert("Error Occurred");
                }
            } catch (error) {
                alert("Error Occurred: " + error.message);
            } finally {
                setLoading(false);
            }
        }
    };





    return (
        <>  {InputValues.length === 0 ? (
            <div className="black-codesdf">
                <div className="p-graph-head-title">Your shopping cart is empty! </div>
                <Link to="/" className="Blank-cart-page-btn"><button className="pricebox-type-btn-theme3">Continue Shopping</button></Link>
            </div>
        ) : (
            <div>

                <div className="cartpage-high-theme3">
                    <div className="cartpage-left-theme3">
                        <div className="flex-Shipping-address">
                            <div className="Top_font-Shipping-Add">Login/Signup</div>
                            <div><i class="fa-solid fa-chevron-right icon-margin-Rarrow"></i></div>
                            <div className="Top_font-Shipping-Add">Shipping Address</div>
                            <div><i class="fa-solid fa-chevron-right icon-margin-Rarrow"></i></div>
                            <div className="Top_font-Shipping-Add">Payment</div>
                            <div><i class="fa-solid fa-chevron-right icon-margin-Rarrow"></i></div>
                            <div className="Top_font-Shipping-Add">Summary</div>

                        </div>
                        {showSelectAddress && (
                            <div>
                                <div className="Save-Address-theme3 Select-Address-shipp">Select Address</div>
                                <div className="shipping-Address-flex">
                                    <div className="Flex-Checkout-powers">
                                        <p className="Paragraph-checkout-Font">Power will be taken after checkout. No additional charges for high powers.</p>
                                    </div>
                                </div>
                                <div className="Save-Address-theme3 Saved-Add-shipp">Saved Addresses</div>
                                {addresses && addresses.length > 0 ? (
                                    addresses.map((address, index) => (
                                        <div className="shipping-Address-flex">
                                            <div className="Border-Saved-Address-Lc">
                                                <div className="Flex-Shipping-Saved">
                                                    <div className="Shipping-Saved-Home">

                                                        {address.typeOfAddress === 0 && (
                                                            <i className="fa-solid fa-house Home-icon-Lc"></i>
                                                        )}
                                                        {address.typeOfAddress === 1 && (
                                                            <i className="fa-solid fa-briefcase iconHouse-right-Lc"></i>
                                                        )}
                                                        {address.typeOfAddress === 2 && (
                                                            <i className="fa-solid fa-location-dot iconHouse-right-Lc"></i>
                                                        )}
                                                        {/* {address.typeOfAddress} */}
                                                        {address.typeOfAddress === 0 && "Home"}
                                                        {address.typeOfAddress === 1 && "Work"}
                                                        {address.typeOfAddress === 2 && "Others"}
                                                    </div>
                                                    <div className="" key={index} onClick={() => handleCheckboxChange(index, address.id)}>
                                                        <input className="CheckBox-input-click"
                                                            type="checkbox"
                                                            name="addressCheckbox"
                                                            // checked={selectedAddress === index}
                                                            checked={address.isDefault}
                                                            // onChange={() => handleCheckboxChange(index, address.id)}
                                                            value={address.isDefault}

                                                        />
                                                        <span className="checkmark"></span>
                                                    </div>
                                                </div>
                                                <div className="Flex-Shipping-Saved">
                                                    <div className="Address-font-shipping">
                                                        {/* {address.address},{address.locality},{address.city},
                                                        {stateInfo.filter(item => item.id === address.state).map(data => (
                                                            data.stateName
                                                        ))}
                                                        ,{address.pincode},{address.mobile} */}
                                                        {address.address}, {address.locality}, {address.city},
                                                        {stateInfo && stateInfo.length > 0 ? (
                                                            stateInfo.filter(item => item.id === address.state).map(data => data.stateName).join('')
                                                        ) : 'State Not Found'},
                                                        {address.pincode}, {address.mobile}
                                                    </div>
                                                    <div className="changes-Btn-Lc">
                                                        <button className="Delete-Btn-lc" onClick={() => deleteaddress(address.id)}>Delete</button>
                                                        <button className="Delete-Btn-lc" onClick={() => editaddress(address.id)}>Edit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div></div>
                                )}
                                <div className="shipping-Address-flex" onClick={handleOpenModalAddAddress}>
                                    <div className="Flex-Add-New-Lc">
                                        <div className="New-Address-shipp">Add New Address</div>
                                        <div><i class="fa-solid fa-arrow-right right-arrow-font"></i></div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {isModalOpenAddAddress && (
                            <div>
                                <div className="Save-Address-theme3">
                                    <div className="Arrow-Margin-right" onClick={handleGoBack}><i class="fa-solid fa-arrow-left"></i></div>
                                    <div className="Saved-Add-shipp">Save Address</div>
                                </div>
                                <div className="shipping-Address-flex">
                                    <div className="Flex-input-shipping">
                                        <div className="Input-Box-shipping-Lc">
                                            <input className="Input-shipping-name"
                                                type="text"
                                                onChange={handleInputChange}
                                                value={inputValues.name}
                                                name="name"
                                                placeholder="Full Name"
                                                ref={inputRef1} />
                                            {/* <label className="modify_p_text7">First Name</label> */}
                                        </div>
                                        <div className="Input-Box-shipping-Lc">
                                            <input className="Input-shipping-name"
                                                type="number"
                                                onChange={handleInputChange}
                                                value={inputValues.mobile}
                                                name="mobile"
                                                placeholder="Mobile No" />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="Save-Address-theme3 Saved-Add-shipp">Select Gender</div> */}
                                {/* <div className="Flex-Gender-Shipping">
                                    <div className="gender-flex-add">
                                        <input className="input-radio-shipping" type="radio" name="gender" />
                                        <label className="Label-Gender-Lc">Male</label>
                                    </div>
                                    <div className="gender-flex-add">
                                        <input className="input-radio-shipping" type="radio" name="gender" />
                                        <label className="Label-Gender-Lc">Female</label>
                                    </div>
                                    <div className="gender-flex-add">
                                        <input className="input-radio-shipping" type="radio" name="gender" />
                                        <label className="Label-Gender-Lc">Prefer not to say</label>
                                    </div>
                                </div> */}
                                <div className="shipping-Address-flex">
                                    <div className="Flex-input-shipping">
                                        <div className="Input-Box-shipping-Lc">
                                            <input className="Input-shipping-name"
                                                type="number"
                                                onChange={handleInputChange}
                                                value={inputValues.pincode}
                                                name="pincode"
                                                placeholder="Pincode" />
                                        </div>
                                        <div className="Input-Box-shipping-Lc">
                                            <select className="Input-shipping-name"
                                                type="text"
                                                onChange={handleInputChange}
                                                value={inputValues.state}
                                                name="state"
                                                ref={inputRef3}
                                            >
                                                <option>--Select State--</option>
                                                {(() => {
                                                    if (stateInfo !== null && Array.isArray(stateInfo)) {
                                                        return stateInfo.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.stateName}
                                                            </option>
                                                        ));
                                                    }
                                                })()}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="shipping-Address-flex">
                                    <div className="Flex-input-shipping">
                                        <div className="Input-Box-shipping-Lc">
                                            <input className="Input-shipping-name"
                                                type="text"
                                                onChange={handleInputChange}
                                                value={inputValues.address}
                                                name="address"
                                                placeholder="Address Line 1"
                                                ref={inputRef2} />
                                        </div>
                                        <div className="Input-Box-shipping-Lc">
                                            <input className="Input-shipping-name"
                                                type="text"
                                                onChange={handleInputChange}
                                                value={inputValues.locality}
                                                name="locality"
                                                placeholder="Locality/Town" />
                                        </div>
                                    </div>
                                </div>
                                <div className="shipping-Address-flex">
                                    <div className="Flex-Input-Address-Lc">
                                        <div className="Input-Box-shipping-Lc">
                                            <input className="Input-shipping-name"
                                                type="text"
                                                onChange={handleInputChange}
                                                value={inputValues.city}
                                                name="city"
                                                placeholder="City/District"
                                                ref={inputRef4} />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="shipping-Address-flex">
                                    <div className="Flex-input-shipping">
                                     <div className="Input-Box-shipping-Lc">
                                            <select className="Input-shipping-name"
                                                type="text"
                                                onChange={handleInputChange}
                                                value={inputValues.country}
                                                name="country"
                                            >
                                                <option>--Select Country--</option>
                                                {(() => {
                                                    if (country !== null && Array.isArray(country)) {
                                                        return country.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ));
                                                    }
                                                })()}
                                            </select>
                                        </div> 
                                    </div>
                                </div> */}
                                <div className="shipping-Address-flex">
                                    <div className="Flex-Checkout-powers">
                                        <p className="Paragraph-checkout-Font">Power will be taken after checkout. No additional charges for high powers.</p>
                                    </div>
                                </div>
                                <div className="Save-Address-theme3 Saved-Add-shipp">Save as</div>
                                <div className="shipping-Address-flex">
                                    <div className="Flex-Home-Work-Other">
                                        <div className={`Home-Icon-Box-Lc ${inputValues.typeOfAddress === 0 ? 'active' : ''}`}
                                            onClick={() => handleInputChange1(0)}
                                            // checked={inputValues.typeOfAddress === 0 ? 'active' : ''}
                                            name="typeOfAddress"
                                            id="home">
                                            <i class="fa-solid fa-house iconHouse-right-Lc"></i>
                                            Home
                                        </div>
                                        <div className={`Home-Icon-Box-Lc ${inputValues.typeOfAddress === 1 ? 'active' : ''}`}
                                            onClick={() => handleInputChange1(1)}
                                            // checked={inputValues.typeOfAddress === 1 ? 'active' : ''}
                                            name="typeOfAddress"
                                            id="work">
                                            <i class="fa-solid fa-briefcase iconHouse-right-Lc"></i>
                                            Work
                                        </div>
                                        <div className={`Home-Icon-Box-Lc ${inputValues.typeOfAddress === 2 ? 'active' : ''}`}
                                            onClick={() => handleInputChange1(2)}
                                            // checked={inputValues.typeOfAddress === 2 ? 'active' : ''}
                                            name="typeOfAddress"
                                            id="others">
                                            <i class="fa-solid fa-location-dot iconHouse-right-Lc"></i>
                                            Other
                                        </div>
                                    </div>
                                </div>
                                <div className="shipping-Address-flex">
                                    <div className="addaddres-bottom_box width-Default-Address">
                                        <input
                                            style={{ marginRight: "8px" }}
                                            type="checkbox"
                                            onChange={handleInputChange2}
                                            checked={inputValues.isDefault === true}
                                            name="typeOfAddress"
                                            id=""
                                        />
                                        <label
                                            className="addaddress_lable"
                                            style={{ marginBottom: "0", fontSize: "13px" }}
                                            htmlFor=""
                                        >
                                            Make this as a Defult Address
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="cartpage-right-theme3">
                        <div style={{ marginLeft: "5px" }} className="cartpage-heading-theme3">Bill Details</div>

                        <div className="">
                            <div className="cartpage-pricebox">

                                <div className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Item Total
                                    </div>
                                    <div className="pricebox-heading-2">
                                        <i className="fa-solid fa-indian-rupee-sign"></i> {totalPrice}
                                    </div>
                                </div>
                                <div style={{ color: "rgb(17, 186, 216)" }} className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Item Coupon
                                    </div>
                                    <div className="pricebox-heading-2">
                                        <i className="fa-solid fa-indian-rupee-sign"></i>  0
                                    </div>
                                </div>
                                <div className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Net Amount
                                    </div>
                                    <div className="pricebox-heading-2">
                                        <i className="fa-solid fa-indian-rupee-sign"></i> {totalPrice}
                                    </div>
                                </div>
                                <div style={{ border: '0' }} className="pricebox-type-1">
                                    <div className="pricebox-heading">
                                        Total payable (incl. taxes)
                                    </div>
                                    <div className="pricebox-heading-2">
                                        <i className="fa-solid fa-indian-rupee-sign"></i> {totalPrice}
                                    </div>
                                </div>
                            </div>
                            <div style={{ cursor: "pointer" }} onClick={OpenAddCoupon} className="cartpage-pricebox">
                                <div className="pricebox-1">
                                    <div style={{ display: "flex" }}>
                                        <img src={coupon} alt="" />
                                        <div className="pricebox-coupon">Add Coupon Code</div>
                                    </div>
                                    <i className="fa-solid fa-caret-right"></i>
                                </div>
                            </div>
                            <div

                                className={`navbar-option ${activeLink === "/MyAccount" ? "active" : ""
                                    }`}
                            >
                                <button className="pricebox-type-btn-theme3" onClick={SaveBtn}>Proccesed To Payment</button>
                            </div>
                        </div>

                    </div>
                    {/* {modal && (
                        <Modal handleClick={signin} />
                    )} */}
                    {/* {alert && (<AlertBox alertname="Remove Item From Cart" close={() => DeleteDeleteEcomCartDetail()} />)} */}
                    {addcoupon && (<AddCouponModal handleClick={OpenAddCoupon} />)}
                    {/* <AlertSuccess /> */}
                </div>
            </div>
        )}

        </>
    );
};

export default ShippingAddress;
