import axios from '../axiosConfig'; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const StateInfo = {

  // ************************* Get All ***************************
  GetAllState: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/StateInfo/GetStateInfos`;
      const response = await axios.post(apiUrl, {}, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  // ************************* Get state by country ***************************
  getStateByCountry: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/StateInfo/GetStateInfos`;
      const response = await axios.post(apiUrl,
        {
          filter: [
            {
              fieldName: "countryId",
              operatorName: "Equal",
              compareValue: 1102
            }
          ],
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          pageNumber: 0,
          pageSize: 0
        },
        {
          withCredentials: true,
        });
      console.log('Response:', response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },
};
export default StateInfo;