import React from 'react'

const Loader1 = () => {
  return (
    <div className='modal-back'>
        <div className="loader_box">
        <div class="loader-1"></div>
        <div className='loading-txt'>LOADING .......</div>
        </div> 
    </div>
  )
}

export default Loader1