import React, { useContext } from "react";
import "./Rightside.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import MyContext from "../../../../MyContext";

const Rightside = () => {
  const navigate = useNavigate();
  const { homeEyeCheckUpData } = useContext(MyContext)
  return (
    <>
      <div className={`main-div-right-appointment`}>

        <div className={`content-div-appointment`}>

          <div className={`name-tag-appointment`}>
            <h2 className={`h2-for-name-appointment`}>Lenskart at Home</h2>
            <p className={`p-for-name-appointment`}>Eye Test & Frame Trial Service</p>
            <span className={`span-starr-appointment`}>
              <FontAwesomeIcon
                style={{ color: "rgb(15, 189, 149)" }}
                icon={faStar}
              />
              <p className={`p-for-rating-appointment`}>4.9</p>
            </span>
          </div>
          <div className={`div-for-visiontest-appointment`}>
            <h2 className={`h2-visiontest-appointment`}>
              What to Expect from Home Vision Test by Lenskart?
            </h2>
            <p className={`p-visiontest-appointment`}>

              <img src="https://static.lenskart.com/media/desktop/img/HTO/EyeWithTorch.svg" alt="" />
              <p className={`p-inside-vision-appointment`}>
                12 Step Eye Checkup by certified professionals
              </p>
            </p>

            <p className={`p-visiontest-appointment`}>

              <img src="https://static.lenskart.com/media/desktop/img/HTO/EyeWithBoxes.svg" alt="" />
              <p className={`p-inside-vision-appointment`}>Latest Eye Test Equipments</p>
            </p>
            <p className={`p-visiontest-appointment`}>

              <img src="https://static.lenskart.com/media/desktop/img/HTO/Glasses.svg" alt="" />
              <p className={`p-inside-vision-appointment`}>Try 150+ frames at home</p>
            </p>
          </div>

          {/* lenskart at home */}
          <div className={`div-for-price-appointment`}>
            <p>Lenskart at Home</p>
            <span className={`span-rupee-appointment`}>
              ₹<p className={`price-appointment`}>{homeEyeCheckUpData.eyeCheckUpFeeOnline ? homeEyeCheckUpData.lensEyeCheckUpFee : "Free"}</p>
            </span>
          </div>

          {/* Bookappointment button */}
          <button
            className={`book-button-appointment`}
            onClick={() => navigate("/phonenumber")}
          // onClick={() => handleClick()}
          >
            BOOK APPOINTMENT
          </button>
        </div>


      </div>
    </>
  );
};

export default Rightside;
