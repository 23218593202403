
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import ContentLense1 from "../../Assets/Image_lc/ContectLenses/aqualens-10h-dailies_csvfile-1706873774153-1_02_02_2024.webp";
import ContentLense2 from "../../Assets/Image_lc/ContectLenses/aqualens-10h-monthly_csvfile-1706697736381-1.webp";
import ContentLense3 from "../../Assets/Image_lc/ContectLenses/aqualens-24-h-contact-lens-30-lens-box-contact-lens_g_2766_1.webp";
import ContentLense4 from "../../Assets/Image_lc/ContectLenses/aqualens-24h-toric-dailies_aqualens-24-h-daily-disposable-toric-contact-lens--30-lens-box_csvfile-1681127655690-144128.webp";
import ContentLense5 from "../../Assets/Image_lc/ContectLenses/aqualens-24h-toric-monthly_aqualens-24h-toric-monthly---3lp_csvfile-1682208663780-135218.webp";
import ContentLense6 from "../../Assets/Image_lc/ContectLenses/aqualens-nxt-monthly_csvfile-1706625948211-artboard_12.webp";

const CarouselLensLc = (props) => {

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true
  };


  return (
    <div>
      <div>
        <h1 className="product-slider-heading" >{props.heading}</h1>
        <Slider className="product-slider-2" {...settings}>
         <Link to="/productsearch"> <img src={ContentLense1} alt="" /></Link>
         <Link to="/productsearch"> <img src={ContentLense1} alt="" /></Link>
         <Link to="/productsearch"> <img src={ContentLense1} alt="" /></Link>
         <Link to="/productsearch"> <img src={ContentLense1} alt="" /></Link>
         <Link to="/productsearch"> <img src={ContentLense1} alt="" /></Link>
        </Slider>
      </div>
    </div>
  );
};

export default CarouselLensLc;

