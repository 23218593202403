import React from 'react'
import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MyContext from '../../MyContext'

const Banner1_Lc = (props) => {

  const navigate = useNavigate();

  const { imageBaseUrl } = useContext(MyContext);
  const { item } = props;

  console.log("itemmm", item)

  const navigateBtn = () => {
    if(item.redirectTo != null && item.redirectTo != undefined && item.redirectTo != 0){
      navigate(`/productsearch?productId=${item.redirectTo}`);
    }
    else if(item.redirectToURL != null && item.redirectToURL != undefined && item.redirectToURL != ""){
      window.location.href(item.redirectToURL)
    }
  }

  return (
    <div onClick={navigateBtn}>
      <img className='banner-1-style' src={`${imageBaseUrl}${item.bannerImageId}`} alt="" />
    </div>
  )
}

export default Banner1_Lc