import React, { useContext, useEffect, useState } from "react";
import ProductSearchComponent from "../ProductSlider/ProductSearchComponent";
import { Link } from "react-router-dom";
import Order from "../../../API/API_SERVISES/Order";
import MyContext from "../../MyContext";

const ProductCart3 = (props) => {
  const {
    item
  } = props;
  // console.log(item)

  const [, setInputValues] = useState({
    d1ItemId: item.d1ItemId,
    d2ItemId: item.d2ItemId,
    d1AccountId: 0,
    d2AccountId: 0,
    userId: item.userId,

  });

  const { wishList, setWishList} = useContext(MyContext);

  // const fetchData = async () => {
  //   try {
  //     const data = await Order.getGetAllFavouriteItemList();
  //     // console.log(data);
  //     setWishList(data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }

  // useEffect(() => {
  //   fetchData();
  // }, [wishList]);

  const toggleWishlist = async (itemData) => {

    console.log(itemData);
    console.log(item);
    console.log(wishList);
    const existingIndex = wishList && wishList.find(product => product.d1ItemId === item.d1Id);
    console.log(existingIndex);
    if (existingIndex !== undefined) {
      // alert("Item is already in wishlist");
      try {
        const val = await Order.deleteDeleteFavouriteItemList(existingIndex.id);
        if (Boolean(val) === true) {
          // alert("Delete data successfully", "Ok");
          // fetchData()
        } else {
          alert("Error Deleting Data");
        }
        // fetchData();
      } catch (error) {
        console.error("Error deleting account:", error);
      }
      return;
    } else {

      const data = {
        d1ItemId: item.d1Id,
        d2ItemId: item.d2Id,
        d1AccountId: 0,
        d2AccountId: 0,
        userId: 1,
      };
      try {
        const isSuccess = await Order.insertCreateFavouriteItemList(data);
        if (isSuccess) {
          // alert("Added to wishlist successfully");
          // fetchData()
        } else {
          throw new Error("Error while adding to wishlist");
        }
      } catch (error) {
        console.error("Error while adding to wishlist:", error);
        alert("Error while adding to wishlist");
      }
      setInputValues({
        d1ItemId: "",
        d2ItemId: "",
        d1AccountId: "",
        d2AccountId: "",
        userId: "",
      });
    };
  }


  return (
    <div style={{ position: "relative" }}>
     <div style={{ cursor: "pointer", position: "absolute", right: "0px", zIndex: "1" }} onClick={() => toggleWishlist(item)}>
    {(wishList && wishList.find(product => product.d1ItemId === item.d1Id)) ? (
        <i className="fa-solid fa-heart HeartSaveBtn2"></i>
    ) : (
        <i className="fa-regular fa-heart HeartSaveBtn2"></i>
    )}
</div>
    <Link to={`/productdetail4/${item.d2Id}`} state={{ item }} className="Card-product-type-1">

      <div className="card-product-image-1">
      <ProductSearchComponent items={item} />
      </div>
      <div className="productcart-bottom-txt-box">
        <p className="product-cart-3-txt-1">{item.name}</p>
        <p className="product-cart-3-txt-2">{item.shortDescription ? item.shortDescription : "No short description found!"}</p>

        <div className="product-cart-price-box-main">
          <p className="product-cart-3-txt-3"><i className="fa-solid fa-indian-rupee-sign" ></i>{item.salePrice}</p>  
          <p className="product-cart-3-txt-4">{item.mrpPrice}</p>
          <p className="product-cart-3-txt-5"> <i className="fa-solid fa-star" style={{color:"#eccb0d"}}></i> 4.2 </p>
          <p className="product-cart-3-txt-6">( 7747 )</p>
        </div>
        <p className="product-cart-3-txt-7">(including Anti glare Lenses )</p>
      </div>
        <div className="Card-product-type-1-end1 colorchange-1 Card-integriti">
        Get 40% OFF in Coupon : LENSFREE
      </div>
    </Link>
      </div>
  );
};

export default ProductCart3;
