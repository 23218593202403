import React, { useEffect, useState } from "react";
import TableSingleVision from "../../Component/Table/TableSingleVision";
import TableBifocal from "../../Component/Table/TableBifocal";
import TablePrograssive from "../../Component/Table/TablePrograssive";
import TableZeropower from "../../Component/Table/TableZeropower";
import { Link } from "react-router-dom";
import imagetoshow from "../../Assets/Icons/gift.png";
import img from "../../Assets/Images/lens1.jpg";
import img1 from "../../Assets/Images/lens2.jpg";
import img2 from "../../Assets/Images/lens3.jpg";
const BuyWithLens = () => {
  useEffect(() => {
    // Scroll to the top of the page when the App component is mounted
    window.scrollTo(0, 0);
  }, []);

  const [activeOption, setActiveOption] = useState("prescriptionDetails");
  const [prescriptionDetailBox, setPrescriptionDetailBox] = useState(true);
  const [prescriptionOptionBox, setPrescriptionOptionBox] = useState(false);

  const[imageToShow , setimageToShow] = useState(false)
  const[imageToShow1 , setimageToShow1] = useState(false)
  const[imageToShow2 , setimageToShow2] = useState(false)
  const[imageToShow3 , setimageToShow3] = useState(false)


  const handleOptionClick = (option) => {
    setActiveOption(option);
    changepagebox();
  };

  const [selectedRadio, setSelectedRadio] = useState("table1");
  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  const changepagebox = () => {
    setPrescriptionDetailBox(false);
    setPrescriptionOptionBox(true);
  };

  const changepagebox1 = () => {
    setPrescriptionDetailBox(true);
    setPrescriptionOptionBox(false);
  };

  const showimage =()=>{
    setimageToShow(true);
  }
  const hideimage =()=>{
    setimageToShow(false);

  }

  const showimage1 =()=>{
    setimageToShow1(true);
  }

  const hideimage1 =()=>{
    setimageToShow1(false);
  }

  const showimage2 =()=>{
    setimageToShow2(true);
  }


  const hideimage2 =()=>{
    setimageToShow2(false);
  }


  const showimage3 =()=>{
    setimageToShow3(true);
  }


  const hideimage3 =()=>{
    setimageToShow3(false);
  }

  return (
    <div className="buywithlens-main">
      <div className="buywithlens">
        <div className="buywithlens-header">
          <div className="buywithlens-header-section">
            <div
              className={`buywithlens-header-txt ${
                activeOption === "prescriptionDetails" ? "active" : ""
              }`}
              onClick={() => changepagebox1()}
            >
              Enter Prescription Details
            </div>
            <div
              className={`buywithlens-header-txt ${
                activeOption === "prescriptionOption" ? "active" : ""
              }`}
            >
              Prescription Lens Option
            </div>
          </div>
        
        </div>
        {prescriptionDetailBox && (
          <div className="buywithlens-content">
            <div className="buywithlens-left">
              <div
                className="buy-with-lens-option"
                onClick={() => setSelectedRadio("table1")}
              >
                <div className="lens-option-top">
                  <input
                    type="radio"
                    value="table1"
                    checked={selectedRadio === "table1"}
                    onChange={handleRadioChange}
                  />
                  <p>Single Vision</p>
                  <i onMouseEnter={showimage} onMouseLeave={hideimage} className="fa-solid fa-circle-info ">
                  {imageToShow && (<div className="imagetoshowinhover"> <img  src={imagetoshow} alt="" /></div>)}
                  </i>                                                                            
                </div>
                                                                                             
                <div className="lens-option-pararaph">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
                  pariatur ipsum fugit dolor sit amet consectetur adipisicing
                  elit. Animi pariatur ipsum fugit
                </div>
              </div>
              <div
                className="buy-with-lens-option"
                onClick={() => setSelectedRadio("table2")}
              >
                <div className="lens-option-top">
                  <input
                    type="radio"
                    name=""
                    id=""
                    value="table2"
                    checked={selectedRadio === "table2"}
                    onChange={handleRadioChange}
                  />
                  <p>Bifocal</p>
                  <i onMouseEnter={showimage1} onMouseLeave={hideimage1} className="fa-solid fa-circle-info">
                  {imageToShow1 && (<div className="imagetoshowinhover"> <img  src={imagetoshow} alt="" /></div>)}
                  </i>
                </div>
                <div className="lens-option-pararaph">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
                  pariatur ipsum fugit sit amet consectetur adipisicing elit.
                  Animi pariatur ipsum fugit dolor sit amet consectetur
                  adipisicing elit. Animi pariatur ipsum fugit
                </div>
              </div>
              <div
                className="buy-with-lens-option"
                onClick={() => setSelectedRadio("table3")}
              >
                <div className="lens-option-top">
                  <input
                    type="radio"
                    name=""
                    id=""
                    value="table3"
                    checked={selectedRadio === "table3"}
                    onChange={handleRadioChange}
                  />
                  <p>Prograssive</p>
                 
                  <i onMouseEnter={showimage2} onMouseLeave={hideimage2} className="fa-solid fa-circle-info">
                  {imageToShow2 && (<div className="imagetoshowinhover"> <img  src={imagetoshow} alt="" /></div>)}
                  </i>
                </div>
                <div className="lens-option-pararaph">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
                  pariatur ipsum fugit fugit dolor sit amet consectetur
                  adipisicing elit. Animi pariatur ipsum fugit
                </div>
              </div>
              <div
                className="buy-with-lens-option"
                onClick={() => setSelectedRadio("table4")}
              >
                <div className="lens-option-top">
                  <input
                    type="radio"
                    name=""
                    id=""
                    value="table4"
                    checked={selectedRadio === "table4"}
                    onChange={handleRadioChange}
                  />
                  <p>Zero Power/Computer Glasses</p>
                  <i onMouseEnter={showimage3} onMouseLeave={hideimage3} className="fa-solid fa-circle-info">
                  {imageToShow3 && (<div className="imagetoshowinhover"> <img  src={imagetoshow} alt="" /></div>)}
                  </i>
                </div>
                <div className="lens-option-pararaph">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
                  pariatur ipsum fugit fugit dolor sit amet consectetur
                  adipisicing elit. Animi pariatur ipsum fugit
                </div>
              </div>
            </div>
            <div className="buywithlens-right">
              <div className="buy-with-lens-right-content">
                {selectedRadio === "table1" && <TableSingleVision onClick={() => handleOptionClick("prescriptionOption")} />}
                {selectedRadio === "table2" && <TableBifocal onClick={() => handleOptionClick("prescriptionOption")} />}
                {selectedRadio === "table3" && <TablePrograssive onClick={() => handleOptionClick("prescriptionOption")} />}
                {selectedRadio === "table4" && <TableZeropower onClick={() => handleOptionClick("prescriptionOption")} />}

              
            </div>
              </div>
            </div>
       
        )}

        {prescriptionOptionBox && (
          <div className="table-container">
            <table className="prescriptionOptiontable tablemain">
              <tr>
                <th className="fixed-column">LENS FEATURES</th>
                <th className="table-head">
                <div>
                    <img src={img}></img>
                  </div>
                  Anti Glare
                  <p>
                    1500 <i className="fa-solid fa-indian-rupee-sign"></i>
                  </p>
                  <h5>(include frame)</h5>
                  <Link to="/cart">Select This Lens</Link>
                </th>
                <th className="table-head">
                <div>
                    <img src={img1}></img>
                  </div>
                  GKB Thin Blue
                  <p>
                    2500 <i className="fa-solid fa-indian-rupee-sign"></i>
                  </p>
                  <h5>(include frame)</h5>
                  <Link to="/cart">Select This Lens</Link>
                </th>
                <th className="table-head">
                <div>
                    <img src={img2}></img>
                  </div>
                  Gkb Blue
                  <p>
                    4500 <i className="fa-solid fa-indian-rupee-sign"></i>
                  </p>
                  <h5>(include frame)</h5>
                  <Link to="/cart">Select This Lens</Link>
                </th>
                <th className="table-head">
                  Gkb Blue
                  <p>
                    4500 <i className="fa-solid fa-indian-rupee-sign"></i>
                  </p>
                  <h5>(include frame)</h5>
                  <Link to="/cart">Select This Lens</Link>
                </th> 
                <th className="table-head">
                  Gkb Blue
                  <p>
                    4500 <i className="fa-solid fa-indian-rupee-sign"></i>
                  </p>
                  <h5>(include frame)</h5>
                  <Link to="/cart">Select This Lens</Link>
                </th> 
                <th className="table-head">
                  Gkb Blue
                  <p>
                    4500 <i className="fa-solid fa-indian-rupee-sign"></i>
                  </p>
                  <h5>(include frame)</h5>
                  <Link to="/cart">Select This Lens</Link>
                </th> 
                <th className="table-head">
                  Gkb Blue
                  <p>
                    4500 <i className="fa-solid fa-indian-rupee-sign"></i>
                  </p>
                  <h5>(include frame)</h5>
                  <Link to="/cart">Select This Lens</Link>
                </th> 
              </tr>
              <tr>
                <td style={{ textAlign: "left" }} className="fixed-column">Warranty Period </td>
                <td >6 months</td>
                <td>12 months</td>
                <td>18 months</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }} className="fixed-column">Index/Thickness </td>
                <td>1.56</td>
                <td>1.60</td>
                <td>1.56</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }} className="fixed-column">Blue Light Blocker    <i onMouseEnter={showimage} onMouseLeave={hideimage} className="fa-solid fa-circle-info ">
                  {imageToShow && (<div className="imagetoshowinhover"> <img  src={imagetoshow} alt="" /></div>)}
                  </i>   </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td>
                  <i className="fa-solid fa-xmark"></i>
                </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }} className="fixed-column">Anti Scratch Coating   <i onMouseEnter={showimage1} onMouseLeave={hideimage1} className="fa-solid fa-circle-info ">
                  {imageToShow1 && (<div className="imagetoshowinhover"> <img  src={imagetoshow} alt="" /></div>)}
                  </i>   </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td>
                  <i className="fa-solid fa-xmark"></i>
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left" }} className="fixed-column">
                  Both Side Anti Glare Coating{" "}
                </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td>
                  <i className="fa-solid fa-xmark"></i>
                </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left" }} className="fixed-column">
                  Both Side Anti Reflective Coating{" "}
                </td>
                <td>
                  <i className="fa-solid fa-xmark"></i>
                </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left" }} className="fixed-column">UV Protection   <i onMouseEnter={showimage2} onMouseLeave={hideimage2} className="fa-solid fa-circle-info ">
                  {imageToShow2 && (<div className="imagetoshowinhover"> <img  src={imagetoshow} alt="" /></div>)}
                  </i>  </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td>
                  <i className="fa-solid fa-xmark"></i>
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left" }} className="fixed-column">Water and Dust Repellent </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td>
                  <i className="fa-solid fa-xmark"></i>
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left" }} className="fixed-column">
                  Breakage & Crack Resistant{" "}
                </td>
                <td>
                  <i className="fa-solid fa-xmark"></i>
                </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
              </tr>

              <tr>  
                <td style={{ textAlign: "left" }} className="fixed-column">Pupillary Distance   <i onMouseEnter={showimage3} onMouseLeave={hideimage3} className="fa-solid fa-circle-info ">
                  {imageToShow3 && (<div className="imagetoshowinhover"> <img  src={imagetoshow} alt="" /></div>)}
                  </i>  </td>
                <td>
                  <i className="fa-solid fa-xmark"></i>
                </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left" }} className="fixed-column">IPD / Single PD </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td>
                  <i className="fa-solid fa-check"></i>
                </td>
                <td>
                  <i className="fa-solid fa-xmark"></i>
                </td>
              </tr>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuyWithLens;
