import React from "react";

const PrescriptionGuide = () => {
  return (
    <div className="">
      <div>
        <div>
          <h1>Understanding your Prescription</h1>
          <p>
            Each time your visit an optometrist, you come back with a detailed
            prescription slip. Deciphering your prescriptions correctly
            especially while ordering your eyeglasses may look tricky with all
            the abbreviated terms and series of + & - numbers. This
            all-inclusive guide will help you read your prescriptions in an easy
            manner and understand all the terms in detail. Let’s start with the
            basics -
          </p>
        </div>
        <div><img src="" alt="" /></div>
      </div>

      <div>
        <div>
          <h1>Understanding your Prescription</h1>
          <p>
            Each time your visit an optometrist, you come back with a detailed
            prescription slip. Deciphering your prescriptions correctly
            especially while ordering your eyeglasses may look tricky with all
            the abbreviated terms and series of + & - numbers. This
            all-inclusive guide will help you read your prescriptions in an easy
            manner and understand all the terms in detail. Let’s start with the
            basics -
          </p>
        </div>
        <div><img src="" alt="" /></div>
      </div>  
    </div>
  );
};

export default PrescriptionGuide;
