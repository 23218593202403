import React from 'react'
import img from "../../Assets/Images/pic1.png";
import img1 from "../../Assets/Images/pic5.png";
import img2 from "../../Assets/Images/pic6.png";
import img3 from "../../Assets/Images/pic8.png"
import img4 from "../../Assets/Images/RetroSquare.png";
import img5 from "../../Assets/Images/Round.png";
import img6 from "../../Assets/Images/Rectangle.png";
import SquareBoxImage from '../../Component/Sections/SquareBoxImage';
import img7 from "../../Assets/Images/Butterfly.png";
import img8 from "../../Assets/Images/Wraparound.png";
import img9 from "../../Assets/Images/Oversized.png";

import img10 from "../../Assets/Images/Cateye.png";
import img11 from "../../Assets/Images/Pilot.png";
import img12 from "../../Assets/Images/Retro Square black.png";
import img13 from "../../Assets/Images/Pic18.png";
import img14 from "../../Assets/Images/pic19.png";
import img15 from "../../Assets/Images/pic20.png";
import img16 from "../../Assets/Images/pic21.png";
import img17 from "../../Assets/Images/pic22.png"
import img18 from "../../Assets/Images/pic23.png"
import img19 from "../../Assets/Images/pic24.png";
import img20 from "../../Assets/Images/Pic25.png";
import img21 from "../../Assets/Images/pic26.png";
import img22 from "../../Assets/Images/pic27.png";
import img23 from "../../Assets/Images/product1.png";
import img24 from "../../Assets/Images/product2.png";
import img25 from "../../Assets/Images/product3.png";
import img26 from "../../Assets/Images/product4.png";


const FaceShapeGuide = () => {
  return (
    // <div>FaceShapeGuide</div>
    <div className="">
      <div className="Faceo-Shape-Banner">
        <img
          className="img-Banner-guide"
          src={img}
          alt=""
        />
      </div>
      <div className="Face-Shape-Top">
        <div>
          <h1 className="Prescription-understand">Don't Know Your Face Shape?</h1>
          <p className="Pargraph-text-guide">
            Let's help you find that frame which will take your style up a few notches!
          </p>
        </div>
        <div className='Heading-line'></div>
        <div>
          <p className="Pargraph-text-guide">
            Often people find themselves in huge favor of a frame however when they put them on, it doesn't suit them at all. Don't let that happen to you. Your face shape plays a very important role in determining which frame will suit you the most. Here are three easy steps to know your face shape.
          </p>

        </div>

        <div>
          <p className="Pargraph-text-guide">
            Things You'll Need - Mirror & Dry-Erase Marker
          </p>
        </div>
        <div className="Trace-Margin-Top">
          <SquareBoxImage />
        </div>
        <div><img src="" alt="" /></div>
      </div>

      <div>
        <div className="Find-Match-bgcolor">
          <h1 className="Prescription-understand">Find Your Match</h1>
          <div className='Heading-line'></div>
        </div>
        <div className="Find-Match-img">
          <div className="Left-img-width">
            <img className="Cartoon-img-width" src={img1} alt="" />
          </div>
          <div className="Left-img-width">
            <img src={img2} alt="" />
          </div>
        </div>
        <div className="Find-Match-img">
          <div className="Left-img-width">
            <img className="Cartoon-img-width" src={img3} alt="" />
          </div>
          <div className="Left-img-width">
            <h1 className="Oval-Shape-Text">Oval Shape</h1>
            <p className="">
              Often people find themselves in huge favor of a frame however when they put them on, it doesn't suit them at all. Don't let that happen to you. Your face shape plays a very important role in determining which frame will suit you the most. Here are three easy steps to know your face shape.
            </p>
            <h1 className="Oval-Shape-Text">suggested Frames</h1>
            <div className="Flex-Frame-Oval">
              <div>
                EYEGLASSES
              </div>
              <div className="Flex-Eyeglasses">
                <div>
                  <img src={img4} alt="" />
                  <div>Retro Square</div>
                </div>
                <div>
                  <img src={img5} alt="" />
                  <div>Round</div>
                </div>
                <div>
                  <img src={img6} alt="" />
                  <div>Rectangle</div>
                </div>
              </div>
            </div>

            <div className="Flex-Frame-Oval">
              <div>
                SUNGLASSES
              </div>
              <div className="Flex-Eyeglasses">
                <div>
                  <img src={img7} alt="" />
                  <div>Butterfly</div>
                </div>
                <div>
                  <img src={img8} alt="" />
                  <div>Wraparound</div>
                </div>
                <div>
                  <img src={img9} alt="" />
                  <div>Oversized</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Find-Match-img">
          <div className="Left-img-width">
            <h1 className="Oval-Shape-Text">Round Shape</h1>
            <p className="">
              Round faces have fuller cheeks and rounded chin area. Its subtle angles are ever ready for a striking balance of geometric, angular frames. This makes rectangle and cateye shaped faces the best suitors for people with round faces. Also, hyped as the trendiest retro classic, there's no way you would want to miss this.
            </p>
            <h1 className="Oval-Shape-Text">suggested Frames</h1>
            <div className="Flex-Frame-Oval">
              <div>
                EYEGLASSES
              </div>
              <div className="Flex-Eyeglasses">
                <div>
                  <img src={img4} alt="" />
                  <div>Retro Square</div>
                </div>
                <div>
                  <img src={img10} alt="" />
                  <div>Cateye</div>
                </div>
                <div>
                  <img src={img6} alt="" />
                  <div>Rectangle</div>
                </div>
              </div>
            </div>
            <div className="Flex-Frame-Oval">
              <div>
                SUNGLASSES
              </div>
              <div className="Flex-Eyeglasses">
                <div>
                  <img src={img11} alt="" />
                  <div>Pilot</div>
                </div>
                <div>
                  <img src={img12} alt="" />
                  <div>Retro Square</div>
                </div>
                <div>
                  <img src={img7} alt="" />
                  <div>Butterfly</div>
                </div>
              </div>
            </div>
          </div>
          <div className="Left-img-width">
            <img className="Cartoon-img-width" src={img19} alt="" />
          </div>
        </div>

        <div className="Find-Match-img">
          <div className="Left-img-width">
            <img className="Cartoon-img-width" src={img20} alt="" />
          </div>
          <div className="Left-img-width">
            <h1 className="Oval-Shape-Text">Heart Shape</h1>
            <p className="">
              With prominent forehead and narrow jaw-line, heart shaped faces exude a striking look with bottom-heavy frames. You can go for round, retrosquare and cateye for best looks. Try choosing frames which are wider to bring a sense of balance to the face proportions. Try picking full-frame eyewear on your next purchase.
            </p>
            <h1 className="Oval-Shape-Text">suggested Frames</h1>
            <div className="Flex-Frame-Oval">
              <div>
                EYEGLASSES
              </div>
              <div className="Flex-Eyeglasses">
                <div>
                  <img src={img5} alt="" />
                  <div>Round</div>
                </div>
                <div>
                  <img src={img4} alt="" />
                  <div>Retro Square</div>
                </div>
                <div>
                  <img src={img10} alt="" />
                  <div>Cateye</div>
                </div>
              </div>
            </div>
            <div className="Flex-Frame-Oval">
              <div>
                SUNGLASSES
              </div>
              <div className="Flex-Eyeglasses">
                <div>
                  <img src={img13} alt="" />
                  <div>Cateye</div>
                </div>
                <div>
                  <img src={img12} alt="" />
                  <div>Retro Square</div>
                </div>
                <div>
                  <img src={img11} alt="" />
                  <div>Pilot</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Find-Match-img">
          <div className="Left-img-width">
            <h1 className="Oval-Shape-Text">Square Shape</h1>
            <p className="">
              When you see someone with a square face, the first thing you notice is their strong jawline and cheek bones. Frames like rectangle or square are a big no-no to wear. Rather choose shapes like round, oval and pilot to add some curves to the face contours. These also help to make the face look a little less boxy.
            </p>
            <h1 className="Oval-Shape-Text">suggested Frames</h1>
            <div className="Flex-Frame-Oval">
              <div>
                EYEGLASSES
              </div>
              <div className="Flex-Eyeglasses">
                <div>
                  <img src={img5} alt="" />
                  <div>Round</div>
                </div>
                <div>
                  <img src={img14} alt="" />
                  <div>Pilot</div>
                </div>
                <div>
                  <img src={img15} alt="" />
                  <div>Oval</div>
                </div>
              </div>
            </div>
            <div className="Flex-Frame-Oval">
              <div>
                SUNGLASSES
              </div>
              <div className="Flex-Eyeglasses">
                <div>
                  <img src={img8} alt="" />
                  <div>Round</div>
                </div>
                <div>
                  <img src={img16} alt="" />
                  <div>Retro Square</div>
                </div>
                <div>
                  <img src={img11} alt="" />
                  <div>Cateye</div>
                </div>
              </div>
            </div>
          </div>
          <div className="Left-img-width">
            <img className="Cartoon-img-width" src={img21} alt="" />
          </div>
        </div>

        <div className="Find-Match-img">
          <div className="Left-img-width">
            <img className="Cartoon-img-width" src={img22} alt="" />
          </div>
          <div className="Left-img-width">
            <h1 className="Oval-Shape-Text">Triangle Shape</h1>
            <p className="">
              Your face shape is considered the rarest of all. With narrow chin and forehead & wider cheekbones, you wouldn't just be satisfied with anything too plain. Which is why, clubmasters are your soul-frames. Try going for round & oval frames to keep the face well-balanced. Invest in prolonged temples for a flattering touch.
            </p>
            <h1 className="Oval-Shape-Text">suggested Frames</h1>
            <div className="Flex-Frame-Oval">
              <div>
                EYEGLASSES
              </div>
              <div className="Flex-Eyeglasses">
                <div>
                  <img src={img17} alt="" />
                  <div>Clubmaster</div>
                </div>
                <div>
                  <img src={img15} alt="" />
                  <div>Oval</div>
                </div>
                <div>
                  <img src={img5} alt="" />
                  <div>Round</div>
                </div>
              </div>
            </div>
            <div className="Flex-Frame-Oval">
              <div>
                SUNGLASSES
              </div>
              <div className="Flex-Eyeglasses">
                <div>
                  <img src={img18} alt="" />
                  <div>Clubmaster</div>
                </div>
                <div>
                  <img src={img16} alt="" />
                  <div>Round</div>
                </div>
                <div>
                  <img src={img13} alt="" />
                  <div>Cateye</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bg-color">
        <div className="Browser-under-text">
          <p>Need more options? Browse our entire collection!</p>
        </div>
        <div className="flex-eye-buttons">
          <div className="shop-eyeglasses-width">
            <button className="btn-eyeglasses">Shop Eyeglasses</button>
          </div>
          <div className="shop-eyeglasses-width">
            <button className="btn-eyeglasses">Shop Sunglasses</button>
          </div>
        </div>
        <div className="Products-flex-gen">
          <div className="img-option-shape">
            <div className="flex-shop-shape">
              <img className="Product-gen-img" src={img23} alt="" />
            </div>
            <div>100% GENUION PRODUCT</div>
          </div>
          <div className="img-option-shape">
            <div className="flex-shop-shape">
              <img className="Product-gen-img" src={img24} alt="" />
            </div>
            <div>EASY DELIVERY & RETURNS</div>
          </div>
          <div className="img-option-shape">
            <div className="flex-shop-shape">
              <img className="Product-gen-img" src={img25} alt="" />
            </div>
            <div>CASH ON DELIVERY</div>
          </div>
          <div className="img-option-shape">
            <div className="flex-shop-shape">
              <img className="Product-gen-img" src={img26} alt="" />
            </div>
            <div>100% SECURE PAYMENTS</div>
          </div>
        </div>
        </div>
      </div>
    </div>

  );
}

export default FaceShapeGuide