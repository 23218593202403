import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MyContext from '../../MyContext';
import ShopBanner from '../../../API/API_SERVISES/Shopbanner';

const ContactLenseMore_Lc = (props) => {

    const { imageBaseUrl, currentUser } = useContext(MyContext);
    const { filteredData } = props;
    const navigate = useNavigate();

    console.log(filteredData)
    // const { item, index } = props;
    // console.log("itemm", item.bannerCaption)
    
    // const [block2Banner, setBlock2Banner] = useState([]);

    // const fetchData = async () => {
    //     debugger
    //     if (currentUser) {
    //         try {
    //             const data = await ShopBanner.GetShopBanners();
    //             // const data = shopBanner;
    //             console.log(data)
    //             if (data) {
    //                 const filteredData = data.filter(item => item.imageBannerName.includes("Block2")).slice().sort((a, b) => a.bannerPriority - b.bannerPriority);
    //                 console.log("filteredData", filteredData);
    //                 setBlock2Banner(filteredData);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching shop banners:', error);
    //         }
    //     }
    // }
    // useEffect(() => {
    //     fetchData();
    // }, [])

    const navigateBtn = (item) => {
        if (item.redirectTo != null && item.redirectTo != undefined && item.redirectTo != 0) {
            navigate(`/productsearch?productId=${item.redirectTo}`);
        }
        else if (item.redirectToURL != null && item.redirectToURL != undefined && item.redirectToURL != "") {
            window.location.href(item.redirectToURL)
        }
    }

    return (
        <div className=''>
            {/* {block2Banner && block2Banner.map((item, index) => ( */}
            <div>
                <div style={{ textAlign: "-webkit-center" }}>
                    <h4 className="Title-Name_Lc">{filteredData != null && filteredData != undefined && filteredData.length > 0 && filteredData[0].bannerCaption}</h4>
                </div>
                <div className="Four-Banner-Lc">
                    <div className="Find-Under-width-Lc" >
                        <div className="" onClick={() => navigateBtn(filteredData[0])}>
                            <img className='banner-1-style Contact-Img-Lc'
                                src={`${imageBaseUrl}${filteredData != null && filteredData != undefined && filteredData.length > 0 && filteredData[0].bannerImageId}`} alt="" />
                        </div>
                        <div className="" onClick={() => navigateBtn(filteredData[1])}>
                            <img className='banner-1-style Contact-Img-Lc'
                                src={`${imageBaseUrl}${filteredData != null && filteredData != undefined && filteredData.length > 0 && filteredData[1].bannerImageId}`} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* ))} */}
        </div >
    )
}

export default ContactLenseMore_Lc;