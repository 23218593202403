import React, { useState } from "react";

const ModalTechnicalInforamation = () => {

    return (

        // <div className="form-container form-loginWidth-Lc">
        <div className="productswap-deteil-2" style={{zIndex:"3"}}>
            <div className="Flex-modal-width">
                <div className="Features-Technical-Box ">
                    <table>
                        <tr className="">
                            <td className="Font-ModalInfo_Lc">Brand Name</td>
                            <td className="Font-ModalInfo_Lc">Bausch & Lomb PureVision</td>
                        </tr>
                        <tr className="">
                            <td className="Font-ModalInfo_Lc">Product Type</td>
                            <td className="Font-ModalInfo_Lc">Contact Lens-Spherical</td>
                        </tr>
                        <tr className="">
                            <td className="Font-ModalInfo_Lc">Type</td>
                            <td className="Font-ModalInfo_Lc">Monthly Disposable</td>
                        </tr>
                        <tr className="">
                            <td className="Font-ModalInfo_Lc">Manufacturer Details</td>
                            <td className="Font-ModalInfo_Lc">Bausch & Lomb</td>
                        </tr>
                        <tr className="">
                            <td className="Font-ModalInfo_Lc">Country of Origin</td>
                            <td className="Font-ModalInfo_Lc">USA</td>
                        </tr>
                        <tr className="">
                            <td className="Font-ModalInfo_Lc">Model No.</td>
                            <td className="Font-ModalInfo_Lc">Bausch & Lomb Purevision2 HD Monthly Disposable</td>
                        </tr>
                    </table>
                </div>
                <div className="Features-Technical-Box">
                    <table>
                        <tr className="">
                            <td className="Font-ModalInfo_Lc">Base Curve</td>
                            <td className="Font-ModalInfo_Lc">8.6 mm</td>
                        </tr>
                        <tr className="">
                            <td className="Font-ModalInfo_Lc">Diameter</td>
                            <td className="Font-ModalInfo_Lc">14.0 mm</td>
                        </tr>
                        <tr className="">
                            <td className="Font-ModalInfo_Lc">Lens Material</td>
                            <td className="Font-ModalInfo_Lc">Balafilcon A ( Silicone Hydrogel )</td>
                        </tr>
                        <tr className="">
                            <td className="Font-ModalInfo_Lc">Packaging</td>
                            <td className="Font-ModalInfo_Lc">6 Lens per Box</td>
                        </tr>
                        <tr className="">
                            <td className="Font-ModalInfo_Lc">Usage Duration</td>
                            <td className="Font-ModalInfo_Lc">24H</td>
                        </tr>
                        <tr className="">
                            <td className="Font-ModalInfo_Lc">Water Content</td>
                            <td className="Font-ModalInfo_Lc">36%</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ModalTechnicalInforamation;
