import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactImageMagnify from "react-image-magnify";

const ContectLensZoom = () => {
  const [enlargedImagePosition, setEnlargedImagePosition] = useState("beside");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setEnlargedImagePosition("over");
      } else {
        setEnlargedImagePosition("beside");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const imageurl =
    "https://happychashma.com/shop/images/product/Soflens_One_Day_Astigmatism_Toric_30_Lens_par_Box_Bausch_Lomb_1810_2.jpg";

  return (
    <>
      <div className="productswap-deteil-2" style={{ zIndex: "3" }}>
        <ReactImageMagnify
          {...{
            smallImage: {
              isFluidWidth: true,
              src: imageurl,
            },
            largeImage: {
              src: imageurl,
              width: 2300,
              height: 2200,
            },
            style: {
              backgroundColor: "white",
            },
            enlargedImageContainerDimensions: {
              width: "93%",
              height: "120%",
              backgroundColor: "black",
            },
            enlargedImagePosition: enlargedImagePosition,
          }}
        />
      </div>


    </>
  );
};

export default ContectLensZoom;
