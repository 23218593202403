import React from "react";
import "./BookAppoinment.css";
import Leftside from "../LeftsideComponent/Leftside";
import Rightside from "../RightsideComponent/Rightside";


const Bookappoinment = () => {

  return (
    <>
      <div className={`main-div-book-appointment`}>
        <Leftside />
        <Rightside/>
      </div>
    </>
  );
};

export default Bookappoinment;
