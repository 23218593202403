import React from 'react'
import OrderBox from './OrderBox'
import TrackBox_Lc from './TrackBox_Lc'

const TrackOrder = () => {
  return (
    <div className='admin-myorder'>
          <label className="title" style={{marginBottom:"0"}}>Track Order</label>
          <div className='favourite-box-container'>
            {/* <div className='myorder-box'></div>
            <div className='myorder-box'></div>
            <div className='myorder-box'></div>
            <div className='myorder-box'></div> */}
            <TrackBox_Lc />

          </div>

    </div>
  )
}

export default TrackOrder