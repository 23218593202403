// SideBar1.js
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import logo from "../../Assets/Icons/logo.png";

import icon from "../../Assets/Icons/home.png";
import icon1 from "../../Assets/Icons/glasses.png";
import icon2 from "../../Assets/Icons/sunglasses.png";
import icon3 from "../../Assets/Icons/contact-lens.png";
import icon4 from "../../Assets/Icons/glasses (1).png";
import icon5 from "../../Assets/Icons/light-eyeglasses-.png";
import icon6 from "../../Assets/Icons/clipboard.png";
import icon7 from "../../Assets/Icons/support.png";
import icon8 from "../../Assets/Icons/shopping-cart (1).png";
import icon9 from "../../Assets/Icons/facebook-app-symbol.png";
import icon10 from "../../Assets/Icons/instagram.png";
import icon11 from "../../Assets/Icons/youtube.png";

const SideBar1 = ({ showSidebar, handleClick }) => {
  const initialClass = "sidebar-1";
  const additionalClass = showSidebar ? "show" : "";
  const initialClass1 = "";
  const additionalClass1 = showSidebar ? "sidebarback" : "";

  // Concatenate the classes with a space
  const combinedClasses = `${initialClass} ${additionalClass}`.trim();
  const combinedClasses1 = `${initialClass1} ${additionalClass1}`.trim();

  useEffect(() => {
    // Set the overflow property on the body based on the isClassAdded state
    document.body.style.overflow = showSidebar ? "hidden" : "auto";

    // Clean up the effect by resetting the overflow property when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showSidebar]);

  return (
    <div onClick={handleClick} className={combinedClasses1}>
      <div className={combinedClasses}>
        <Link onClick={handleClick} to="/" className="sidebar-1-logo">
          <img className="logo-sidebar-1" src={logo} alt="" />
        </Link>

        <div onClick={handleClick} className="sidebar-1-button-box">
          <Link onClick={handleClick} to="/" className="sidebar-1-btn">
            {" "}
            <img className="sidebar-1-icon" src={icon} alt="" />
            Home
          </Link>
          <Link
            onClick={handleClick}
            to="/productsearch"
            className="sidebar-1-btn"
          >
            {" "}
            <img
              className="sidebar-1-icon"
              style={{ width: "30px" }}
              src={icon1}
              alt=""
            />{" "}
            Eyeglasses
          </Link>
          <Link
            onClick={handleClick}
            to="/productsearch"
            className="sidebar-1-btn"
          >
            {" "}
            <img
              className="sidebar-1-icon"
              style={{ width: "30px" }}
              src={icon2}
              alt=""
            />{" "}
            Sunglasses
          </Link>
          <Link
            onClick={handleClick}
            to="/productsearch"
            className="sidebar-1-btn"
          >
            {" "}
            <img className="sidebar-1-icon" src={icon3} alt="" /> Contact Lenses
          </Link>
          <Link
            onClick={handleClick}
            to="/productsearch"
            className="sidebar-1-btn"
          >
            {" "}
            <img
              className="sidebar-1-icon"
              style={{ width: "30px" }}
              src={icon4}
              alt=""
            />{" "}
            Kids Glasses
          </Link>
          <Link
            onClick={handleClick}
            to="/productsearch"
            className="sidebar-1-btn"
          >
            {" "}
            <img className="sidebar-1-icon" src={icon5} alt="" /> Computer
            Glasses
          </Link>
          <Link onClick={handleClick} to="/admin" className="sidebar-1-btn">
            {" "}
            <img className="sidebar-1-icon" src={icon6} alt="" /> My Order
          </Link>
          <Link onClick={handleClick} to="/" className="sidebar-1-btn">
            {" "}
            <img className="sidebar-1-icon" src={icon7} alt="" /> Contact Us
          </Link>

          <Link onClick={handleClick} to="/cart3" className="sidebar-1-btn">
            {" "}
            <img className="sidebar-1-icon" src={icon8} alt="" />
            CART
          </Link>
          <Link onClick={handleClick} to="/" className="sidebar-1-btn">
            {" "}
            <img className="sidebar-1-icon" src={icon9} alt="" />
            FACEBOOK
          </Link>
          <Link onClick={handleClick} to="/" className="sidebar-1-btn">
            {" "}
            <img className="sidebar-1-icon" src={icon10} alt="" />
            INSTAGRAM
          </Link>
          <Link onClick={handleClick} to="/" className="sidebar-1-btn">
            {" "}
            <img className="sidebar-1-icon" src={icon11} alt="" />
            YOUTUBE
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SideBar1;
