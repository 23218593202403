import React, { useContext, useEffect, useState, } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactImageMagnify from 'react-image-magnify';
import MyContext from "../../../MyApp/MyContext";
// import './ProductSwap2.css'; // Import your custom styles if needed

const ProductSwap3 = (props) => {

  const { items } = props
  
  const { imageBaseUrl } = useContext(MyContext);

  const [enlargedImagePosition, setEnlargedImagePosition] = useState('beside');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setEnlargedImagePosition('over');
      } else {
        setEnlargedImagePosition('beside');
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


//   const imageurl = 'https://www.specsplus.in/shop/images/product/SNOOPY_160229_699_1.jpg';
  const imageurl2 = `${imageBaseUrl}${items.images[2]}`;

  return (
    <>

        <div className="productswap-deteil-3" style={{zIndex:"2"}}>
        <ReactImageMagnify
            {...{
              smallImage: {
                isFluidWidth: true,
                src: imageurl2,
              },
              largeImage: {
                src: imageurl2,
                width: 1200,
                height: 600,
              },
              style: {
                backgroundColor: 'white',
              },
              enlargedImageContainerDimensions:{
                width:  "93%",
                height : '120%',
                backgroundColor:"black"
              }
              ,
              enlargedImagePosition: enlargedImagePosition, 
              
            }}
          />
        </div>
    </>
  );
};

export default ProductSwap3;
