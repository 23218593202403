import React, { useEffect, useRef } from 'react'

const SearchPageMobile = (props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []); 
  return (
    <div className="mobile-filter-page">
        <i onClick={props.close}  className="search-back-button fa-solid fa-arrow-left-long"></i>
      <div className='searchpanel-input-box' > 
      <input className='search-input-searchpanel' type="text" placeholder='What are you looking for ?' ref={inputRef} />
      </div>
      <div className='search-options-panal'>
        <p className='search-options-panal-txt'>Recent Search</p>
        <div className='search-options-panal-recent-box'>
          <div className='search-options-panal-recent-1'> <i className="fa-regular fa-clock"></i> glass</div>
          <div className='search-options-panal-recent-1'> <i className="fa-regular fa-clock"></i>black glass</div>
          <div className='search-options-panal-recent-1'> <i className="fa-regular fa-clock"></i> lens</div>
          <div className='search-options-panal-recent-1'> <i className="fa-regular fa-clock"></i>black glass</div>
          <div className='search-options-panal-recent-1'> <i className="fa-regular fa-clock"></i> lens</div>
        </div>
        <p className='search-options-panal-txt'>Tranding on our shop</p>
        <div className='search-options-panal-recent-box'>
          <div className='search-options-panal-recent-1'>  Eyeglasses</div>
          <div className='search-options-panal-recent-1'> Sunglasses</div>
          <div className='search-options-panal-recent-1'>  lens</div>
        </div>
      </div>
      
    </div> 
  )
}

export default SearchPageMobile
