import React, { useContext, useEffect, useState } from 'react'
import MyContext from '../../MyContext';

const Banner1 = () => {

  const { imageBaseUrl, shopBanner } = useContext(MyContext);
  const [productPageBanner, setproductPageBanner] = useState([])
  const [imageId, setImageId] = useState('')



  useEffect(() => {
    debugger
    if (shopBanner.length > 0) {
      const mainfilterData = shopBanner.filter(item => item.imageBannerName.includes("ProductDetail1")).slice().sort((a, b) => a.bannerPriority - b.bannerPriority);
      setproductPageBanner(mainfilterData)
      if (mainfilterData.length > 0) {
        setImageId(mainfilterData[0].bannerImageId)
      }
      setImageId(false)

      console.log(shopBanner)
    }

  }, [shopBanner])



  // console.log(imageId)


  // return (

  //   <img className='banner-1-style' src={`${imageBaseUrl}${imageId}`} alt="" />
  // )
  return (
    <>
      {shopBanner && imageId && (
        <img className='banner-1-style' src={`${imageBaseUrl}${imageId}`} alt="" />
      )}
    </>
  );

}

export default Banner1