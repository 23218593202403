import axios from "../axiosConfig"; // Adjust the import path accordingly

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const EyeCheckUpAppointment = {
  // ****************************** Create Eye Check Up Appointment ******************************************************** */
  CreateEyeCheckUpAppointment: async (data) => {
    // debugger;
    console.log(data);

    try {
      const apiUrl = `${WebApiUrl}/api/EyeCheckUpAppointment/CreateEyeCheckUpAppointment`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          data,
        },
        {
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ****************************** Get Eye Check Up Appointment ******************************************************** */
  GetEyeCheckUpAppointment: async (data) => {
    // debugger;
    console.log(data);

    try {
      const apiUrl = `${WebApiUrl}/api/EyeCheckUpAppointment/GetEyeCheckUpAppointment`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
};

export default EyeCheckUpAppointment;
