import React from 'react';
import { Link } from 'react-router-dom';

const FindThePerfectFit = () => {
    return (
        <div>
            <Link to="/productsearch">
                <div style={{ textAlign: "-webkit-center" }}>
                    <h4 className="Title-Name_Lc">FIND THE PERFECT FIT</h4>
                </div>
                <div className="Four-Banner-Lc">
                    <div className="Flex-Find-Perfect-Lc">
                        <div className="Find-Image-width-Lc">
                            <img className='banner-1-style' src="https://static1.lenskart.com/media/desktop/img/Aug21/Desktop/eye-square10.jpg" alt="" />
                        </div>
                        <div className="Find-Image-width-Lc">
                            <img className='banner-1-style' src="https://static1.lenskart.com/media/desktop/img/Aug21/Desktop/ce-square.jpg" alt="" />
                            <img className='banner-1-style' src="https://static1.lenskart.com/media/desktop/img/Aug21/Desktop/sun-square.jpg" alt="" />
                        </div>
                    </div>
                    <div className="Find-Under-width-Lc">
                        <div className="">
                            <img className='banner-1-style Under-img' src="https://static1.lenskart.com/media/desktop/img/Aug21/Desktop/power-sun-square.jpg" alt="" />
                        </div>
                        <div className="">
                            <img className='banner-1-style Under-img' src="https://static1.lenskart.com/media/desktop/img/Nov20/25-Nov/Banner03_TileDesktop.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default FindThePerfectFit;