import React from 'react'

const TableSingleVision = (props) => {
  return (
    <>
    <table className='tablemain'> 
      
    <tr>
      <th>
        <button className="prehistory-btn">Pre. History</button>
      </th>
      <th>Right</th>
      <th>Left</th>
    </tr>
    <tr>
      <td>Spherical </td>
      <td>
        {" "}
        <select name="" id="">
          <option value="">1</option>
          <option value="">2</option>
          <option value="">3</option>
        </select>{" "}
      </td>
      <td>
        {" "}
        <select name="" id="">
          <option value="">1</option>
          <option value="">2</option>
          <option value="">3</option>
        </select>
      </td>
    </tr>
    <tr>
      <td>Cylindrical </td>
      <td>
        {" "}
        <select name="" id="">
          <option value="">1</option>
          <option value="">2</option>
          <option value="">3</option>
        </select>{" "}
      </td>
      <td>
        {" "}
        <select name="" id="">
          <option value="">1</option>
          <option value="">2</option>
          <option value="">3</option>
        </select>
      </td>
    </tr>
    <tr>
      <td>Axis </td>
      <td>
        {" "}
        <select name="" id="">
          <option value="">1</option>
          <option value="">2</option>
          <option value="">3</option>
        </select>
      </td>
      <td>
        {" "}
        <select name="" id="">
          <option value="">1</option>
          <option value="">2</option>
          <option value="">3</option>
        </select>
      </td>
    </tr>
  </table>
    <button
    onClick={props.onClick}
    className="continue-button-bwl"
  >
    Continue
  </button>


  <div className="buy-with-lens-button-box">
<button to="/cart" className="product-detail-button1">
Pre. History
</button>
<button to="/buywithlens" className="product-detail-button1">
  Upload my prescription
</button>
</div>
</>

  )
}

export default TableSingleVision