import React from "react";

const HeadWith3Box = (props) => {
  return (
    <div className="pd-right-section-main-frame-sec">
      <p className="pd-right-section-main-frame-head">{props.heading}</p>
      <div className="pd-right-section-main-frame-section-2">
        <div className="pd-right-section-main-frame-sec-1">
          <img
            className="pd-right-section-main-frame-sec-image"
            src={props.image1}
            alt=""
          />
          <p className="pd-right-section-main-frame-sec-1-head">
            {props.imagetitle1}
          </p>
        </div>
        <div className="pd-right-section-main-frame-sec-1">
          <img
            className="pd-right-section-main-frame-sec-image"
            src={props.image2}
            alt=""
          />
          <p className="pd-right-section-main-frame-sec-1-head">
            {props.imagetitle2}
          </p>
        </div>
        <div
          style={{ border: "0" }}
          className="pd-right-section-main-frame-sec-1"
        >
          <img
            className="pd-right-section-main-frame-sec-image"
            src={props.image3}
            alt=""
          />
          <p className="pd-right-section-main-frame-sec-1-head">
            {props.imagetitle3}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeadWith3Box;
