import React from "react";
import "./Input.css";

const Input = ({ placeholder, width, onChange, value, readOnly }) => {


  const handleInputChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <input
      placeholder={placeholder}
      style={{ width: `${width}` }}
      className={`input-field-appointment`}
      onChange={handleInputChange}
      value={value}
      readOnly={readOnly}
    />
  );
};

export default Input;
