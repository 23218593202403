import axios from "../axiosConfig";
//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const ItemGroup = {
  GetItemGroup: async () => {
    try {
      //   const apiUrl = `${express_server_url}/api/ItemGroup/GetItemGroups`;
      const apiUrl = `${WebApiUrl}/api/ItemGroup/GetItemGroups`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [],
          order: [
            {
              propertyName: "d1Id",
              ascending: false,
            },
          ],
          pageNumber: 0,
          pageSize: 500000,
        },

        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetItemGroupWithFilter: async (filterData) => {
    const filteredData = filterData.map((data) => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue,
    }));
    try {
      //   const apiUrl = `${express_server_url}/api/ItemGroup/GetItemGroups`;
      const apiUrl = `${WebApiUrl}/api/ItemGroup/GetItemGroups`;
      const response = await axios.post(
        apiUrl,
        {
          filter: filteredData,
          order: [
            {
              propertyName: "string",
              ascending: true,
            },
          ],
          pageNumber: 0,
          pageSize: 0,
        },
        {
          withCredentials: true,
        }
      );
      // // debugger;
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
};

export default ItemGroup;
