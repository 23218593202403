import React, { useState } from "react";
import dlt from "../../Assets/Icons/trash.png";
// import MyContext from "./MyContext";

const OrderBox = () => {
  const [isClicked, setIsClicked] = useState(false);

  const toggleClass = () => {
    setIsClicked(!isClicked);
  };

//   const constextProduct = useContext(MyContext);

//   console.log(constextProduct);

//   const filteredProducts = constextProduct.filter(
//     (item) => item.favourite === true
//   );

// 

  return (
    <div>
      <div
        onClick={toggleClass}
        className={` ${isClicked ? "custom-div" : ""} my-order-table `}
      >
        <div className="order-box-top-top">
          <div className="order-box-top">
            <div className="order-detail-list">
              <p className="ordertxt-1">Order No : 934030</p>
              <p className="ordertxt-1">Order Date : 02/11/2023 11:44</p>
              <p className="ordertxt-2">
                Service Type : Table | Table No : PERGOLA 2
              </p>
              <p className="ordertxt-2">Delivery Date : 02/11/2023</p>
              <p className="ordertxt-2">Delivery Time Slot : 17:00 To 17:30</p>
            </div>

            <div
              style={{ justifyContent: "end" }}
              className="order-detail-list"
            >
              <p className="ordertxt-1">Sub Amount : 455</p>
              <p className="ordertxt-1">Delivery : 0</p>
              <p style={{ marginBottom: "23px" }} className="ordertxt-1">
                Total Amount : 455
              </p>
              <p
                style={{ marginBottom: "5px", color: "#11bad8" }}
                className="ordertxt-3"
              >
                See Details
              </p>
            </div>
          </div>
          <div>
           
              <div
                style={{
                  borderRadius: "15px",
                  height: "350px",
                  boxShadow: "none",
                  width:"100%",
                  padding:"0",
                  marginBottom:"0"
                }}
                className="card-cart cart "
              >
                <label className="title">My Order </label>

                <div className="favourite-box-container">
        <div className="favourite-box">
          <div className="review-box-image">
            <img className="favourite-img "
              src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg"
              alt=""
            />
          </div>
          <div className="review-box-detail">
            <div className="review-box-detail-line-1">
              <div className="review-box-name">
                Peyush Bansal Shark Tank Monza Red Full Rim Hustler
                Eyeglasses....
              </div>
              <div className="review-box-section">
                {/* <i className="fa-solid fa-trash"></i> */}
                <img className="fa-trash" src={dlt} alt="" />
              </div>
            </div>
            <div className="review-box-detail-line-2">
              <div className="lens-txt">
                Lens :{" "}
                <div className="review-product-name">
                  Black Full Frame Eyeglasses
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "60px",
                }}
              >
                {" "}
                <p className="review-price">
                  <i className="fa-solid fa-indian-rupee-sign"></i> 1500
                </p>
                {/* <p className="review-price-2">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 2500
                  </p> */}
              </div>
            </div>
           
          </div>
        </div>

     

        <div className="favourite-box">
          <div className="review-box-image">
            <img className="favourite-img"
              src="https://happychashma.com/shop/images/product/Green_Year_9017_Metal_Half_Rim_52_Rectangle_Black_502_1.jpg"
              alt=""
            />
          </div>
          <div className="review-box-detail">
            <div className="review-box-detail-line-1">
              <div className="review-box-name">
                Peyush Bansal Shark Tank Monza Red Full Rim Hustler
                Eyeglasses....
              </div>
              <div className="review-box-section">
                <img className="fa-trash" src={dlt} alt="" />
              </div>
            </div>
            <div className="review-box-detail-line-2">
              <div className="lens-txt">
                Lens :{" "}
                <div className="review-product-name">
                  Black Full Frame Eyeglasses
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "60px",
                }}
              >
                {" "}
                <p className="review-price">
                  <i className="fa-solid fa-indian-rupee-sign"></i> 1500
                </p>
                {/* <p className="review-price-2">
                    <i className="fa-solid fa-indian-rupee-sign"></i> 2500
                  </p> */}
              </div>
            </div>
          </div>
        </div>

      </div>

              
   
                
              </div>
            </div>
          </div>
        </div>
      </div>
  
  );
};

export default OrderBox;
