import React, { useEffect, useState } from "react";
import ProductSwap2 from "../../Component/ProductSlider/ProductSwap2";
import ProductSwap3 from "../../Component/ProductSlider/ProductSwap3";
import { Link } from "react-router-dom";
import ProductSwap from "../../Component/ProductSlider/Productswap";
import ProductSwap4 from "../../Component/ProductSlider/Productswap4";
import Banner1 from "../../Component/Sections/Banner1";
import ReviewAndRating from "../../Component/Rating/ReviewAndRating";
import ReviewTest from "../../Component/Rating/ReviewTest";
import ProductCart4 from "../../Component/Card/ProductCart4";
import SimilerProductSection1 from "../../Component/Sections/SimilerProductSection1";
import ProductCart1 from "../../Component/Card/ProductCart1";
// import ProductSwap from "../Component/ProductSlider/Productswap";
import { useLocation } from 'react-router-dom';
import Order from "../../../API/API_SERVISES/Order.js";

const ProductDetailPage3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const { item } = location.state;

  const [activeComponent, setActiveComponent] = useState(<ProductSwap />);
  const [description, setDescription] = useState(true);
  const [features, setFeatures] = useState(true);
  const [specification, setSpecification] = useState(true);
  const [review, setReview] = useState(true);

  const opendesc1 = () => {
    setDescription(!description);
  };
  const opendesc2 = () => {
    setFeatures(!features);
  };
  const opendesc3 = () => {
    setSpecification(!specification);
  };

  const opendesc4 = () => {
    setReview(!review);
  };

  const showComponent = (component) => {
    setActiveComponent(component);
  };

  const [inputValues, setInputValues] = useState({
    d1ItemId: item.d1ItemId,
    d2ItemId: item.d2ItemId,
    d1AccountId: 214,
    d2AccountId: 214,
    userId: item.userId,
    quantity: 1,
    unit: "Pcs",
    itemPrice: item.itemPrice,
  });

  const AddToCart = () => {
    console.log(item)
    const data = {
      d1ItemId: item.d1Id,
      d2ItemId: item.d2Id,
      d1AccountId: 214,
      d2AccountId: 214,
      userId: 0,
      quantity: 1,
      unit: "Pcs",
      itemPrice: item.salePrice,
    }
    const isSuccess = Order.insertCreateEcomCartDetail(data);
    setInputValues(isSuccess)

    setInputValues({
      d1ItemId: " ",
      d2ItemId: " ",
      d1AccountId: " ",
      d2AccountId: " ",
      userId: " ",
      quantity: " ",
      unit: " ",
      itemPrice: " ",
    });

  }


  return (
    <div>
      <Banner1 />

      <div className="ProductDetailPage" style={{ border: "none" }}>
        <div className="ProductDetailPage-left">
          <div className="ProductDetailPage-imagebox1">
            <div
              onClick={() => showComponent(<ProductSwap />)}
              className="ProductDetailPage-smallimg"
            >
              <img
                className="ProductDetailPage-image"
                src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg"
                alt=""
              />
            </div>
            <div
              onClick={() => showComponent(<ProductSwap2 />)}
              className="ProductDetailPage-smallimg"
            >
              <img
                className="ProductDetailPage-image"
                src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1832_BLACKRED_914_2.jpg"
                alt=""
              />
            </div>
            <div
              onClick={() => showComponent(<ProductSwap3 />)}
              className="ProductDetailPage-smallimg"
            >
              <img
                className="ProductDetailPage-image"
                src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg"
                alt=""
              />
            </div>
            <div
              onClick={() => showComponent(<ProductSwap4 />)}
              className="ProductDetailPage-smallimg"
            >
              <img
                className="ProductDetailPage-image"
                src="https://www.ashishoptics.com/shop/images/product/TITANIUM_KIDS_FRAME_2_93503_534_3.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="ProductDetailPage-imagebox2">{activeComponent}</div>
        </div>
        <div className="ProductDetailPage-right">
          <div className="ProductDetailPage3-section">
            <h1 className="product-name-3">
              {item.name} 
            </h1>
            <h2>
              <div>
                <i className="fa-solid fa-indian-rupee-sign"></i> {item.saleprice}
                <span>
                  {" "}
                  <i className="fa-solid fa-indian-rupee-sign"></i> {item.saleprice * 2}
                </span>
              </div>
              <div className="instock-box-pdp">3 Stock left</div>
            </h2>
          </div>
          <div className="color-section-p1">
            <div className="color-option-pd">
              <div className="outline-coloroption">
                <div className="color-option-box1"></div>
              </div>
              <div className="outline-coloroption">
                <div className="color-option-box2"></div>
              </div>
              <div className="outline-coloroption">
                <div className="color-option-box3"></div>
              </div>
              <div className="outline-coloroption">
                <div className="color-option-box4"></div>
              </div>
              <div className="outline-coloroption">
                <div className="color-option-box5"></div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <input
              required=""
              type="text"
              name="text"
              autocomplete="off"
              className="input3"
            />
            <span className="user-label">Enter Pincode For Delivery Estimate</span>
            <div>check</div>
          </div>

          <div className="productdetail-bottom-box">
            <div className="product-detail-button-box">
              <div onClick={AddToCart} to="/cart" className="product-detail-button">
                Buy Frame Only{" "}
              </div>
              <Link to="/buywithlens" className="product-detail-button">
                Buy With Lens
              </Link>
            </div>
            <div className="productdetail-name">
              <p
                style={{ marginLeft: "20px " }}
                className="product-detail-return"
              >
                Return Policy : 7 day easy return policy
              </p>
              <p
                style={{ marginLeft: "20px", color: "#fd5959" }}
                className="product-detail-enquiry"
              >
                Product Enquiry
              </p>
            </div>
          </div>
          <p className="productdetail-name rating-box-pdp">
            <div className="product-detail-name">
              4.7 <i className="fa-solid fa-star product-detail-star"></i> 1507
            </div>
          </p>
        </div>
      </div>


      <div className="ProductDetailPage-bottom">
        <div className="ProductDetailPage-bottom-1">
          <div onClick={opendesc1} className="accordion-box">
            Description
            {description ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {description && (
            <div className="Description-accordian-box">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
                laborum, nemo pariatur minima minus totam sit accusantium
                impedit sunt mollitia repellat adipisci dolorum iure
                exercitationem nihil, libero neque recusandae consequatur
                numquam repellendus. Quibusdam alias placeat animi labore dolor
                corporis delectus nemo nam quas provident sint sequi, eos,
                deleniti, perferendis sed.
              </p>
            </div>
          )}
          <div onClick={opendesc2} className="accordion-box">
            Features
            {features ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {features && (
            <div className="Features-accordian-box">
              <table>
                <tr className="dark-tablerow">
                  <td> Frame Material</td>
                  <td>Metal</td>
                </tr>
                <tr className="light-tablerow">
                  <td> Lens Material</td>
                  <td>Polycarbonate</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Frame Color</td>
                  <td>Black</td>
                </tr>
                <tr className="light-tablerow">
                  <td>Shape</td>
                  <td>Square</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Brand</td>
                  <td>KILLER</td>
                </tr>
                <tr className="light-tablerow">
                  <td>Gender</td>
                  <td>Men, Women</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Dimensions</td>
                  <td>48-20-140</td>
                </tr>
                <tr className="light-tablerow">
                  <td>SKUID</td>
                  <td>E12B10286</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Product type</td>
                  <td>eyeglasses</td>
                </tr>
                <tr className="light-tablerow">
                  <td>Country of Origin</td>
                  <td>India</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Manufactured by</td>
                  <td>Scavin International Exim Pvt. Ltd.</td>
                </tr>
                <tr className="light-tablerow">
                  <td>Sold by</td>
                  <td>EyeMyEye Pvt Ltd</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Raw Material Sourced from</td>
                  <td> India </td>
                </tr>
              </table>
            </div>
          )}
          <div onClick={opendesc3} className="accordion-box">
            Specification
            {specification ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {specification && (
            <div className="Specification-accordian-box">
              <p>✔ Full Frame</p>
              <p>✔ Light Weight and Comfortable</p>
              <p>✔ Strong and Durable</p>
              <p>✔ Free Eyeglasses Case with Cleaning Cloth</p>
            </div>
          )}

          <div
            onClick={opendesc4}
            className="accordion-box"
          >
            Review & Rating
            {review ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {review && (
            <ReviewAndRating  />
            
            )}
        </div>

      </div>
      <Banner1 />

      {/* <SimilerProductSection1 heading="Similer Products" productComponent={<ProductCart1 />} /> */}
      

    </div>
  );
};

export default ProductDetailPage3;
