import React, { useContext, useEffect, useState } from "react";
// import ProductSwap from '../ProductSlider/Productswap'
import { Link } from "react-router-dom";
import ProductSearchComponent from "../ProductSlider/ProductSearchComponent";
import Order from "../../../API/API_SERVISES/Order";
import MyContext from "../../MyContext";


const ProductCart2 = (props) => {
  const {
    item
  } = props;

  const [, setInputValues] = useState({
    d1ItemId: item.d1ItemId,
    d2ItemId: item.d2ItemId,
    d1AccountId: 0,
    d2AccountId: 0,
    userId: item.userId,

  });

  const { wishList, setWishList} = useContext(MyContext);

  // const fetchData = async () => {
  //   try {
  //     const data = await Order.getGetAllFavouriteItemList();
  //     // console.log(data);
  //     setWishList(data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const toggleWishlist = async (itemData) => {

    console.log(itemData);
    console.log(item);
    console.log(wishList);
    const existingIndex = wishList.find(product => product.d1ItemId === item.d1Id);
    console.log(existingIndex);
    if (existingIndex !== undefined) {
      // alert("Item is already in wishlist");
      try {
        const val = await Order.deleteDeleteFavouriteItemList(existingIndex.id);
        if (Boolean(val) === true) {
          // alert("Delete data successfully", "Ok");
          // fetchData()
        } else {
          alert("Error Deleting Data");
        }
        // fetchData();
      } catch (error) {
        console.error("Error deleting account:", error);
      }
      return;
    } else {

      const data = {
        d1ItemId: item.d1Id,
        d2ItemId: item.d2Id,
        d1AccountId: 0,
        d2AccountId: 0,
        userId: 1,
      };
      try {
        const isSuccess = await Order.insertCreateFavouriteItemList(data);
        if (isSuccess) {
          // alert("Added to wishlist successfully");
          // fetchData()
        } else {
          throw new Error("Error while adding to wishlist");
        }
      } catch (error) {
        console.error("Error while adding to wishlist:", error);
        alert("Error while adding to wishlist");
      }
      setInputValues({
        d1ItemId: "",
        d2ItemId: "",
        d1AccountId: "",
        d2AccountId: "",
        userId: "",
      });
    };
  }


  return (
    <div style={{ position: "relative" }}>
      <div style={{ cursor: "pointer", position: "absolute", right: "0px", zIndex: "1" }} onClick={() => toggleWishlist(item)}>
        {(wishList && wishList.find(product => product.d1ItemId === item.d1Id)) ? (
          <i className="fa-solid fa-heart HeartSaveBtn2"></i>
        ) : (
          <i className="fa-regular fa-heart HeartSaveBtn2"></i>
        )}
      </div>

      <Link to={`/productdetail/${item.d2Id}`} state={{ item }} className="Card-product-type-1">
        {/* <i
          style={{ color: "red", cursor: "pointer" }}
          className="fa-solid fa-heart card-menu-favorite"
        ></i> */}

        <div className="card-product-image-1">
          <ProductSearchComponent items={item} />
        </div>

        <div className="card-product-details-1">
          <div className="product-rating-box">
            <div className="card-product-name">
              4.7 <i className="fa-solid fa-star cart-star"></i>{item.mrpPrice}
            </div>
            <div></div>
          </div>

          <div>
            {" "}
            <p className="card-product-name">{item.name}</p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <p className="card-product-price">
              <i className="fa-solid fa-indian-rupee-sign"></i>{item.salePrice}
            </p>
            <p className="card-product-price-2">
              <i className="fa-solid fa-indian-rupee-sign"></i>{item.salePrice * 2}
            </p>
          </div>
          <div>
            {" "}
            <p className="card-product-desc">{item.shortDescription ? item.shortDescription : "No short description found!"}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCart2;
